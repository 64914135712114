import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';
import { uniqBy, defaults, get } from 'lodash';

const persist = persistReducer('voicemails');

const initialState = {
  // vm_box_index: 0, // NOT used anymore
  // messages: [], // NOT used anymore
  vm_boxes: [],
  loadingVmboxes: false,
  loadedVmboxes: false,
  vmboxById: {
    // vmbox_id: { messages: [messages_listing_array] }
  },
  messageTranscription: {
    // message.media_id: {...result}
  },
  newvoicemails: [],
  makeCheckedItem: [],
  makeCheckedItemObject: [],
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 10
};

const defaultVmBoxesStruct = {
  loading: false,
  loaded: false,
  messages: []
};

const defaultState = persist(initialState, 'get', initialState);

export default (state = defaultState, action) => {
  let vmboxById;
  var vmbox_id;
  var bool;
  var messages;
  switch (action.type) {
    case CONSTS.SET_VOICEMAIL_TRANSCRIPTION:
      const messageTranscription = { ...state.messageTranscription };
      messageTranscription[action.payload.messageId] =
        action.payload.messageTranscription;
      return persist({ ...state, messageTranscription });
    case CONSTS.SET_VOICEMAIL_DATA:
      return persist({ ...state, newvoicemails: action.payload.newvoicemails });
    case CONSTS.GET_ALL_MESSAGES:
      return persist({
        ...state,
        ...action.payload
      });

    case CONSTS.UPDATE_VOICEMAIL_STATE:
      return persist({ ...state, ...action.payload });

    case CONSTS.CHANGE_VOICEMAIL_STATE:
      const messages2 = get(
        state.vmboxById,
        `${action.payload.vmboxId}.messages`,
        []
      ).map(message =>
        message.media_id === action.payload.messageId
          ? {
            ...message,
            ...action.payload.data
          }
          : message
      );
      const vmboxById2 = state.vmboxById[action.payload.vmboxId];
      vmboxById2.messages = messages2;

      return persist({
        ...state,
        vmboxById: {
          ...state.vmboxById,
          ...vmboxById2
        }
      });

    case CONSTS.SET_VMBOXES:
      // updates info for ALL vmboxes (counts)
      // - TODO: use vm_boxes[id].data? (like vm_boxes[id].messages)
      const { vm_boxes } = action.payload;
      vmboxById = state.vmboxById;
      for (let vmbox of vm_boxes) {
        const vmbox_id = vmbox.id;
        if (!vmboxById[vmbox_id]) {
          vmboxById[vmbox_id] = {};
        }
        vmboxById[vmbox_id] = defaults(
          vmboxById[vmbox_id],
          defaultVmBoxesStruct
        );
      }
      // TODO: remove unused vmboxes, messages

      return persist({
        ...state,
        vm_boxes: vm_boxes,
        vmboxById
      });

    case CONSTS.SET_VMBOXES_LOADING:
      // loading ALL faxboxes
      let loadingVmboxes = get(
        action.payload,
        'loadingVmboxes',
        state.loadingVmboxes
      );
      let loadedVmboxes = get(
        action.payload,
        'loadedVmboxes',
        state.loadedVmboxes
      );

      return persist({
        ...state,
        loadingVmboxes,
        loadedVmboxes
      });

    case CONSTS.SET_VMBOX_LOADING:
      // removes message dupes by media_id
      vmbox_id = action.payload.vmbox_id;
      bool = action.payload.bool;

      vmboxById = state.vmboxById || {};
      if (!vmboxById[vmbox_id]) {
        vmboxById[vmbox_id] = {};
      }
      vmboxById[vmbox_id] = defaults(vmboxById[vmbox_id], defaultVmBoxesStruct);

      vmboxById[vmbox_id].loading = bool;

      return persist({
        ...state,
        vmboxById
      });

    case CONSTS.SET_VMBOX_MESSAGES:
      // removes message dupes by media_id
      vmbox_id = action.payload.vmbox_id;
      messages = action.payload.messages;

      vmboxById = state.vmboxById || {};
      if (!vmboxById[vmbox_id]) {
        vmboxById[vmbox_id] = {};
      }
      vmboxById[vmbox_id] = defaults(vmboxById[vmbox_id], defaultVmBoxesStruct);
      vmboxById[vmbox_id].messages = messages.filter(m => m && m.media_id);
      vmboxById[vmbox_id].messages = uniqBy(
        vmboxById[vmbox_id].messages,
        'media_id'
      );

      return persist({
        ...state,
        vmboxById
      });

    case CONSTS.RESET_VOICEMAIL:
    case CONSTS.LOGOUT:
      return persist(initialState);

    default:
      return persist(state);
  }
};
