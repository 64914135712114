export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_AUTH_FORM = 'UPDATE_AUTH_FORM';
export const UPDATE_AUTH_QUERYSTRING = 'UPDATE_AUTH_QUERYSTRING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const START_LOGIN = 'START_LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

export const SET_USER_NUMBERS = 'SET_USER_NUMBERS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_LAST_SEEN = 'SET_USER_DATA_LAST_SEEN';
