import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    background: theme.palette.white["500"],
    borderRadius: 2,
    boxShadow: props =>
      props.highlight
        ? "inset 0px 3px 0px 0px rgb(46, 208, 34), 0px 1px 4px 0px rgba(32, 32, 41, 0.24)"
        : "0px 1px 4px 0px rgba(32, 32, 41, 0.24)",
    height: 102,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "16px"
  },
  iconContainer: {
    background: theme.palette.voicemailIconGreen["500"],
    height: 40,
    width: 40,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: theme.spacing(2)
  },
  icon: {
    color: theme.palette.white["500"],
    borderRadius: 0,
    height: 16,
    width: 16
  },
  leftContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  count: {
    fontSize: theme.spacing(4), // 32px;
    fontWeight: 600,
    // height: theme.spacing(3),
    // marginBottom: theme.spacing(1),
    color: props => props.highlight ? theme.palette.voicemailIconGreen['500'] : theme.palette.black['500'],
  },
  label: {
    fontSize: 14,
    fontWeight: "normal",
    height: 14,
    letterSpacing: 0
  }
}));

export default useStyles;
