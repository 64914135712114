import {
  AuthState,
  SettingsState,
  CallsState,
  DevicesState,
  FaxesState,
  VoicemailsState,
  UserPortalState,
  UserSettingsState,
  ApplicationState
} from 'Reducers/types';

export const getAuthState = (state: ApplicationState): AuthState => state.auth;
export const getSettingsState = (state: ApplicationState): SettingsState =>
  state.settings;
export const getCallState = (state: ApplicationState): CallsState =>
  state.calls;
export const getDeviceState = (state: ApplicationState): DevicesState =>
  state.devices;
export const getFaxesState = (state: ApplicationState): FaxesState =>
  state.faxes;
export const getVoicemailState = (state: ApplicationState): VoicemailsState =>
  state.voicemails;
export const getApplicationUserPortalState = (
  state: ApplicationState
): UserPortalState => state.applications_user_portal;
export const getUserSettings = (state: ApplicationState): UserSettingsState =>
  state.userSettings;
