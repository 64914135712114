import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
import { KazooSDK } from '@KazooSDK';
import { Telicon } from '../../Icons';
import { getPhoneNumber, getDateTime } from '@KazooSDK/utils';
import { EmptyResultBox } from '../EmptyResultBox';
import _ from 'lodash';
// import TableHead from '@material-ui/core/TableHead';
import { useTranslation } from 'react-i18next';

const FaxesWidget = (props) => {
  const { faxbox, history } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  let faxesData = _.orderBy(
    [...props.faxes_inbox_data, ...props.faxes_outbox_data],
    'created',
    'desc'
  );

  return (
    <Paper className={classes.root}>
      <Typography
        onClick={() => history.push('/user_portal/faxes')}
        className={classes.title}
      >
        {t('faxes.todaysFaxes.label')}
      </Typography>
      <FaxesTable rows={faxesData} faxbox={faxbox} />
    </Paper>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    padding: theme.spacing(1)
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function FaxesTable (props) {
  const classes = useStyles();
  const { rows } = props;

  const { t } = useTranslation();

  if (rows.length < 1) {
    return (
      <EmptyResultBox
        icon='device-fax'
        title={t('faxes.noFaxesToday.title.label')}
        text={t('faxes.noFaxesToday.text.label')}
      />
    );
  }

  return (
    <Table className={classes.table} aria-label='customized table'>
      {/* <TableHead>
        <StyledTableRow>
          <th className={classes.th}>{t('from.label')}</th>
          <th className={classes.th}>{t('to.label')}</th>
          <th className={classes.th}>{t('dateTime.label')}</th>
          <th className={classes.th} />
        </StyledTableRow>
      </TableHead> */}
      <TableBody>
        {rows.map((fax, index) => {
          const URL = KazooSDK.getFaxUrl(fax);
          if (fax.folder === 'inbox') {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <div className={classes.displayRow}>
                    <Telicon
                      name='arrow-target-down'
                      className={classes.icon}
                    />
                    <div className={classes.displayColumn}>
                      <span className={classes.from}>{fax.from}</span>
                      <span className={classes.faxbox}>
                        {/* {getPhoneNumber(fax.from_number)} */}
                      </span>
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes.displayColumn}>
                    <span className={classes.from}>
                      {/* {props.faxbox.caller_name} */}
                      {fax.from_name}
                    </span>
                    <span className={classes.faxbox}>
                      {/* {props.faxbox.faxbox_name} */}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes.displayColumn}>
                    <span className={classes.from}>
                      {getDateTime(fax.created).date}
                    </span>
                    <span className={classes.faxbox}>
                      {getDateTime(fax.created).time}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <a href={URL}>
                    <Telicon name='download' className={classes.downloadIcon} />
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            );
          }
          if (fax.folder === 'outbox') {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <div className={classes.displayRow}>
                    <Telicon name='arrow-target-up' className={classes.icon} />
                    <div className={classes.displayColumn}>
                      <span className={classes.from_name}>
                        {/* {props.faxbox.caller_name} */}
                        {fax.from}
                      </span>
                      <span className={classes.faxbox}>
                        {/* {props.faxbox.faxbox_name} */}
                      </span>
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes.displayColumn}>
                    <div className={classes.from}>{fax.to_name}</div>
                    <div className={classes.faxbox}>
                      {getPhoneNumber(fax.to_number)}
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes.displayColumn}>
                    <span className={classes.from}>
                      {getDateTime(fax.created).date}
                    </span>
                    <span className={classes.faxbox}>
                      {getDateTime(fax.created).time}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <a href={URL}>
                    <Telicon name='download' className={classes.downloadIcon} />
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            );
          }
          return null;
        })}
        {rows.length < 1 && (
          <StyledTableRow>
            <StyledTableCell colSpan='7'>
              <p className='p-5'>
                {t('faxes.noFaxesToday.title.label')}
              </p>
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default FaxesWidget;
