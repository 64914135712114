import URLSearchParams from '@ungap/url-search-params';
import { KazooSDK } from 'utils/KazooSDK';
import { LaunchParams } from 'types';
import moment from 'moment';
const CONFIG = require('Config');

window.KazooSDK = KazooSDK;

const cleanUpSessionStorage = (): void => {
  // remove broken parameters (probably remove this at some point)
  Object.keys(window.sessionStorage).forEach(function (key) {
    if (
      ['undefined', 'null', undefined, null].includes(
        window.sessionStorage.getItem(key)
      )
    ) {
      window.sessionStorage.removeItem(key);
    }
  });
};

const getLaunchParams = (): void => {
  const doubleDecode: Array<string> = ['api_url'];
  const searchParams = new URLSearchParams(window.location.search);
  const launchParams: LaunchParams = {};

  for (const key of searchParams.keys()) {
    launchParams[key] = doubleDecode.includes(key)
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        decodeURIComponent(searchParams.get(key)!).replace(/\+/g, '%20')
      : searchParams.get(key);
  }

  if (launchParams.mobile) {
    window.sessionStorage.setItem('mobile', 'true');
  }

  if (launchParams.mobile === '0' || launchParams.mobile === 'false') {
    window.sessionStorage.removeItem('mobile');
  }

  if (window.sessionStorage.getItem('mobile')) {
    launchParams.mobile = 'true'; // for redux
  }

  window.launchParams = launchParams;

  if (launchParams.api_url && launchParams.api_url.length) {
    window.localStorage.setItem('api_url', launchParams.api_url);
  } else {
    const currentApiUrl = window.localStorage.getItem('api_url');
    // set if not already set
    if (!currentApiUrl) {
      window.localStorage.setItem('api_url', CONFIG.API_URL);
    }
  }
};

const setupMoment = (): void => {
  moment.updateLocale('en', {
    relativeTime: {
      past: (input) => {
        return input === 'Yesterday' ? input : `${input} ago`;
      },
      d: 'Yesterday'
    }
  });
};

export { cleanUpSessionStorage, getLaunchParams, setupMoment };
