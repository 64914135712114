import React from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
// import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { useStylesCallListTablePagination } from './styles';

export const CallListTablePagination = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStylesCallListTablePagination();
  const theme = useTheme();
  const countPages = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleChangePage = (event, page) => {
    onChangePage(event, page);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, countPages - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? 'Last' : 'First'}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {countPages === 1 && <IconButton disabled>{page + 1}</IconButton>}
      {countPages > 1 ? (
        page === countPages - 1 ? (
          <>
            <IconButton
              disabled={page !== countPages - 1}
              className={classNames({
                [classes.activeButton]: page !== countPages - 1
              })}
              onClick={e => handleChangePage(e, page - 1)}
            >
              {page}
            </IconButton>
            <IconButton
              disabled={page === countPages - 1}
              className={classNames({
                [classes.activeButton]: page === countPages - 1
              })}
              onClick={e => handleChangePage(e, page)}
            >
              {page + 1}
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              className={classes.activeButton}
              disabled
              onClick={e => handleChangePage(e, page)}
            >
              {page + 1}
            </IconButton>
            <IconButton onClick={e => handleChangePage(e, page + 1)}>
              {page + 2}
            </IconButton>
          </>
        )
      ) : (
        <></>
      )}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= countPages - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= countPages - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? 'First' : 'Last'}
      </IconButton>
    </div>
  );
};
