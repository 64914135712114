import React, { useReducer, useEffect } from 'react';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { Hidden } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import useStyles from './styles';
import { Telicon } from '../../Icons/index';
import { useTranslation } from 'react-i18next';
import SearchWidget from '../SearchWidget';

export const FilterWidget = props => {
  const { handleFilter, type, history, missingCount } = props;
  const classes = useStyles();
  const initState = {
    typeActive: type,
    from: moment()
      .subtract(7, 'day')
      .format('MM/DD/YYYY'),
    to: moment().format('MM/DD/YYYY'),
    openFromDate: false,
    openToDate: false
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { typeActive, from, to, openFromDate, openToDate } = state;

  const { t } = useTranslation();
  // const handleApplyFilter = () => {
  //   if (from !== 'Invalid date' && to !== 'Invalid date') {
  //     handleFilter({ from, to });
  //   }
  // };

  const handleOnChangeFromDate = value => {
    let tempToDate = to;
    if (
      moment(to, 'MM/DD/YYYY').diff(moment(value), 'days') >= 30 ||
      moment(to, 'MM/DD/YYYY').diff(moment(value), 'days') < 0
    ) {
      tempToDate = moment(value)
        .add(30, 'day')
        .format('MM/DD/YYYY');
    }
    setState({
      from: moment(value).format('MM/DD/YYYY'),
      to: tempToDate,
      openFromDate: false
    });
  };

  const handleOnChangeToDate = value => {
    let tempFromDate = from;
    if (
      moment(value).diff(moment(from, 'MM/DD/YYYY'), 'days') >= 30 ||
      moment(value).diff(moment(from, 'MM/DD/YYYY'), 'days') < 0
    ) {
      tempFromDate = moment(value)
        .subtract(30, 'day')
        .format('MM/DD/YYYY');
    }
    setState({
      from: tempFromDate,
      to: moment(value).format('MM/DD/YYYY'),
      openToDate: false
    });
  };

  const handleOnChangeStatus = value => {
    setState({ typeActive: value });
    history.push(`/user_portal/call_history?filter=${value}`);
  };

  useEffect(() => {
    if (from !== 'Invalid date' && to !== 'Invalid date') {
      handleFilter({ from, to });
    }
  }, [from, to, handleFilter]);

  return (
    <Grid container className={classes.containerFilter} spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <ButtonGroup
          aria-label="full width outlined button group"
          className={classes.filterType}
        >
          <Button
            className={classNames(classes.button, {
              [classes.active]: typeActive === 'all'
            })}
            onClick={() => handleOnChangeStatus('all')}
          >
            {t('all.label')}
          </Button>
          <Button
            className={classNames(classes.button, {
              [classes.active]: typeActive === 'missed'
            })}
            onClick={() => handleOnChangeStatus('missed')}
          >
            {t('missed.label')}
            {missingCount ? ` (${missingCount})` : ''}
          </Button>
        </ButtonGroup>
      </Grid>
      {
        <>
          <Hidden xsDown>
            <Grid item xs={12} sm={8} md={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      open={openFromDate}
                      onOpen={() => setState({ openFromDate: true })}
                      onClose={() => setState({ openFromDate: false })}
                      classes={{ root: classes.datePicker }}
                      keyboardIcon={<Telicon name="calendar" />}
                      InputProps={{
                        classes: {
                          root: classes.datePickerInnerInput
                        }
                      }}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      value={from}
                      onChange={value => handleOnChangeFromDate(value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      open={openToDate}
                      onOpen={() => setState({ openToDate: true })}
                      onClose={() => setState({ openToDate: false })}
                      classes={{ root: classes.datePicker }}
                      keyboardIcon={<Telicon name="calendar" />}
                      variant="inline"
                      InputProps={{
                        classes: {
                          root: classes.datePickerInnerInput
                        }
                      }}
                      value={to}
                      format="MM/dd/yyyy"
                      margin="normal"
                      onChange={value => handleOnChangeToDate(value)}
                    />
                  </Grid>
                </Grid>
                {/* <Button
                variant="outlined"
                className={classNames(classes.buttonFilter)}
                onClick={handleApplyFilter}
              >
                <Typography>{t('filters.label')}</Typography>
              </Button> */}
              </MuiPickersUtilsProvider>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={4} md={3}>
            <SearchWidget handleChangeFilter={props.changeFilter} />
          </Grid>
        </>
      }
    </Grid>
  );
};

export default FilterWidget;
