import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth, drawerMiniWidth } from '../../App';

export default makeStyles(theme => ({
  drawer: {
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: props => (props.miniMenu ? drawerMiniWidth : drawerWidth),
      flexShrink: 0
    }
  },
  drawerLogo: {
    marginTop: 48,
    [theme.breakpoints.up('sm')]: {
      marginTop: 56
    }
  },
  root: {
    backgroundColor: theme.palette.grey['500'],
    height: window.innerHeight,
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    width: props => (props.miniMenu ? drawerMiniWidth : drawerWidth),
    marginLeft: '-115%',
    zIndex: 10000
  },
  drawerPaper: {
    height: '100vh',
    overflow: 'hidden',
    // width: 280,
    backgroundColor: theme.palette.grey['500'],
    color: theme.palette.sidebarText['500']
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    width: drawerMiniWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1
    // }
  },
  logo: {
    width: 130,
    height: 32,
    padding: '12px 24px',
    boxSizing: 'content-box',
    [theme.breakpoints.up('sm')]: {
      width: 130,
      height: 32,
      padding: '12px 0',
      alignSelf: 'center'
    }
  },
  logoMini: {
    width: 32,
    height: 32,
    padding: 12,
    boxSizing: 'content-box'
  },
  sideBarIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.sidebarIcon['500'],
    lineHeight: 'inherit',
    marginRight: theme.spacing(1)
  },
  sideBarIconActive: {
    color: `${theme.palette.iconBlue['500']} !important`,
    lineHeight: 'inherit',
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  menuItem: {
    padding: props => (props.miniMenu ? '16px 16px' : '16px 24px'),
    width: drawerWidth
  },
  menuTitle: {
    opacity: props => (props.miniMenu ? 0 : 1),
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 0.3
    })
    // display: props => (props.miniMenu ? 'none' : 'block'),
    // marginTop: 0,
    // marginBottom: 0
  },
  active: {
    backgroundColor: `${theme.palette.darkGrey['500']} !important`,
    color: theme.palette.iconBlue['500']
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '0'
  },
  gotoToSettings: {
    marginTop: 'auto',
    boxShadow: 'inset 0px 1px 0px 0px rgb(21, 21, 23)',
    padding: props => (props.miniMenu ? '16px 12px' : '16px 24px')
  },
  gotoToSettingsLabel: {
    paddingLeft: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  typography: {
    color: theme.palette.darkGrey['500'],
    fontSize: theme.spacing(2), // 16
    marginLeft: theme.spacing(1)
  },
  avatar: {
    width: 32,
    height: 32
  }
}));
