import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  checkbox: {
    position: 'absolute',
    top: 15,
    left: 5,
    padding: 0,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.iconBlue['500']
    }
  },
  select: {
    padding: 0,
    height: 38,
    minWidth: 70,
    margin: '10px 0 20px',
    '& .MuiInputBase-input': {
      height: 'auto !important',
      padding: '0 30px 0 10px'
    }
  },
  statusItem: {
    padding: '2px 30px',
    color: theme.palette.darkGrey['500'],
    '&:before': {
      content: "''",
      width: 8,
      height: 8,
      position: 'absolute',
      top: '50%',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
      left: 15
    }
  },
  grayColor: {
    '&:before': {
      backgroundColor: theme.palette.textGrey['500'],
    }
  },
  redColor: {
    '&:before': {
      backgroundColor: theme.palette.red['500']
    }
  },
  greyColor: {
    '&:before': {
      backgroundColor: theme.palette.activeGrey['500']
    }
  }
}));

export default useStyles;
