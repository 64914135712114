import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { useWebsocketStatus } from '@Utils/websocketBinding';
import { ReadyState } from 'react-use-websocket';
import { Telicon } from '../../Icons';

const SocketStateProps = (readyState) => {
  switch (readyState) {
    case ReadyState.OPEN:
      return {icon: <Telicon name="lightning-bolt" />, label: "Connected", color: "#4caf50"};
    case ReadyState.CONNECTING:
      return {icon: <Telicon name="ellipses" />, label: "Connecting", color: "#2196f3"};
    case ReadyState.CLOSING:
      return {icon: <Telicon name="chevron-double-down" />, label: "Closing", color: "#ff9800"};
     case ReadyState.CLOSED:
      return {icon: <Telicon name="x" />, label: "Disconnected", color: "#f44336"};
    default:
      return {icon: <Telicon name="ellipses" />, label: "Connecting", color: "#ff9800"};
  }
}

const WebsocketStatus = () => {
  const [socketState, setSocketState] = useState(ReadyState.CONNECTING);
  const socketProps = SocketStateProps(socketState);

  const readyState  = useWebsocketStatus();
  useEffect(
    () => {
      if (socketState !== readyState.status) {
        setSocketState(readyState.status);
      }
    }, [socketState, readyState]);



    return (<Chip label={socketProps.label} style={{backgroundColor: socketProps.color, color:"white" }} icon={socketProps.icon}  size="small" />)

}

export default WebsocketStatus;
