import { createMuiTheme } from '@material-ui/core/styles';
export const theme = createMuiTheme({
  palette: {
    primary: { 500: '#2297ff' },
    white: { 500: '#ffffff' },
    grey: { 500: 'rgb(46, 46, 50)' },
    darkGrey: { 500: 'rgb(21, 21, 23)' } /* #151517 */,
    sidebarIcon: { 500: 'rgb(144, 144, 153)' },
    sidebarText: { 500: 'rgb(244, 244, 245)' } /* #f4f4f5 */,
    iconBlue: { 500: 'rgb(34, 151, 255)' } /* #2297ff */,
    iconRed: { 500: 'rgb(255, 61, 36)' } /* #2297ff */,
    iconGreen: { 500: 'rgb(46, 208, 34)' },
    iconGrey: { 500: 'rgb(216, 216, 222)' } /* #2297ff */,
    iconDefault: { 500: 'rgb(244, 244, 245)' } /* #f4f4f5 */,
    voicemailIconGreen: { 500: 'rgb(46, 208, 34)' },
    red: { 500: 'rgb(255, 61, 36)' },
    textGrey: { 500: 'rgb(100, 100, 108)' },
    inboundGrey: { 500: 'rgb(188, 188, 194)' },
    selectBlack: { 500: 'rgb(48, 48, 57)' },
    activeGrey: { 500: 'rgb(219, 219, 222)' },
    activeGreyTransparent: { 500: 'rgba(219, 219, 222, 0.48)' },
    svgGrey: { 500: '#909099' },
    hoverBlue: { 500: 'rgb(23,105,178)' },
    green: { 500: '#2ed022' },
    darkGreen: { 500: '#209218' },
    opacityGrey: { 500: 'rgba(0, 0, 0, 0.23)' },
    bgRowGrey: { 500: 'rgba(0, 0, 0, 0.02)' },
    black: { 500: 'rgba(0, 0, 0, 0.87)' },
    darkBlue: { 500: 'rgb(0, 107, 202)' } /* #006bca */,
    backgroundGreyLinear: {
      500: 'linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(244, 244, 245) 100%)'
    },
    borderGreen: { 500: 'rgb(32, 146, 24)' },
    successLightGreen: { 500: 'rgb(186, 243, 181)' },
    failLightRed: { 500: 'rgb(250, 177, 160)' },
    tabBarGrey: { 500: 'rgb(237, 237, 241)' }
  },
  typography: {
    fontFamily: ['Source Sans Pro'].join(','),
    color: 'rgb(21, 21, 23)'
  }
});
