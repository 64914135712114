import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
// import { Voicemail as VoicemailIcon } from "../../Icons";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { get } from 'lodash';

import useStyles from "./styles";

const VoicemailBoxCountWidget = props => {
  const { vmBox } = props;

  let history = useHistory();

  const route = useCallback(() => {
    history.push(`/user_portal/voicemails/vmbox/${vmBox.id}`);
    // history.push("/user_portal/voicemails?filter=new");
  }, [history, vmBox.id]);

  const newMessageCount = get(vmBox, 'folders.new') || 0;
  const savedMessageCount = get(vmBox, 'folders.saved') || 0;

  const classes = useStyles({
    highlight: newMessageCount ? true : false
  });
  const { t } = useTranslation();
  
  return (
    <div className={classes.root} onClick={route}>
      <div className={classes.leftContainer}>
        <Typography className={classes.count}>{newMessageCount || savedMessageCount}</Typography>
        <Typography className={classes.label}>
          {
            newMessageCount ? 
            t('voicemails.new.label')
            :
            t('voicemails.label')
          }
        </Typography>
      </div>
    </div>
  );
};

export default VoicemailBoxCountWidget;
