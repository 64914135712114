import { get } from 'lodash';
import {
  AuthThunk,
  AuthAction,
  AuthFormPayload,
  UserNumbersPayload,
  LoginSuccessPayload
} from './types';
import { KazooSDK } from 'utils/KazooSDK';
import * as CONSTS from '../Constants';
import { LaunchParams } from 'types';

const getPrettyMessage = ({ message }: { message: string }): string => {
  if (message.includes('401')) {
    return 'Invalid credentials. Please try again or contact your admin.';
  }
  if (message.includes('403')) {
    return `Please contact your Admin. You currently don't have access to comm.io.`;
  }
  if (message.includes('500')) {
    return 'Server Unavailable, please contact your admin.';
  }
  return message;
};

// ACTIONS

export const updateAuthForm = (payload: AuthFormPayload): AuthAction => ({
  type: CONSTS.UPDATE_AUTH_FORM,
  payload: { ...payload }
});

export const startLogin = (): AuthAction => ({ type: CONSTS.START_LOGIN });

export const setLoginSuccess = (payload: LoginSuccessPayload): AuthAction => ({
  type: CONSTS.LOGIN_SUCCESS,
  payload
});

export const setLoginError = (message: string): AuthAction => ({
  type: CONSTS.LOGIN_ERROR,
  payload: message
});

export const logout = (): AuthAction => ({ type: CONSTS.LOGOUT });

export const setUserData = (payload: Record<string, unknown>): AuthAction => ({
  type: CONSTS.SET_USER_DATA,
  payload
});

export const setUserDataLastSeen = (
  key: string,
  value: string
): AuthAction => ({
  type: CONSTS.SET_USER_DATA_LAST_SEEN,
  payload: {
    key,
    value
  }
});

export const setUserNumbers = (payload: UserNumbersPayload): AuthAction => ({
  type: CONSTS.SET_USER_NUMBERS,
  payload
});

export const updateAuthQueryString = (payload: LaunchParams): AuthAction => ({
  type: CONSTS.UPDATE_AUTH_QUERYSTRING,
  payload
});

// THUNKS

export const login = (
  username: string,
  password: string,
  accountName: string
): AuthThunk => {
  return async (dispatch) => {
    try {
      dispatch(startLogin());
      const response = await KazooSDK.authenticate(
        username.trim(),
        password.trim(),
        accountName.trim()
      );
      const { app_state } = await KazooSDK.checkUserLogin();
      if (app_state) {
        dispatch(
          updateAuthForm({ username, password, account_name: accountName })
        );
        dispatch(setLoginSuccess(response));
      } else {
        const message = `Please contact your Admin. You currently don't have access to comm.io.`;
        dispatch(setLoginError(message));
      }
    } catch (e) {
      const message = getPrettyMessage(e);
      dispatch(setLoginError(message));
    }
  };
};

export const loginWithAuthToken = (auth_token: string): AuthThunk => {
  return async (dispatch) => {
    try {
      dispatch(startLogin());
      let response;
      try {
        response = await KazooSDK.authenticateWithToken(auth_token.trim());
      } catch (err) {
        const message = 'Authentication Token Invalid';
        dispatch(setLoginError(message));
      }
      const { app_state } = await KazooSDK.checkUserLogin();
      if (app_state) {
        if (response) {
          dispatch(setLoginSuccess(response));
        }
      } else {
        const message = `Please contact your Admin. You currently don't have access to comm.io.`;
        dispatch(setLoginError(message));
      }
    } catch (e) {
      const message = getPrettyMessage(e);
      dispatch(setLoginError(message));
    }
  };
};

export const fetchUserData = (): AuthThunk => {
  return async (dispatch) => {
    try {
      const response = await KazooSDK.getUser();
      const user_data = response.data.data;
      dispatch(setUserData(user_data));

      const numbersResponse = await KazooSDK.getDeviceNumber();
      let numbers = [];

      numbers = numbersResponse.data?.data[0]?.numbers;
      dispatch(setUserNumbers({ isLoading: false, isLoaded: true, numbers }));
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch(logout());
      }
    }
  };
};

interface LastSeenPayload {
  last_seen: {
    [key: string]: string | null | undefined;
  };
}

export const updateLastSeen = (key: string, value: string): AuthThunk => {
  return async (dispatch, getState) => {
    try {
      const previousLastSeen = get(
        getState(),
        ['auth', 'user_data', 'last_seen', key],
        ''
      );
      const payload: LastSeenPayload = {
        last_seen: {}
      };
      payload.last_seen[key] = value;

      if (value <= previousLastSeen) {
        return;
      }

      dispatch(setUserDataLastSeen(key, value));

      // TODO: verify reponse
      await KazooSDK.patchUser(payload);
    } catch (e) {
      //if (get(e, 'response.status') === 401) {
      //  dispatch(logout());
      //}
      console.error('Update user last_seen failed', e);
    }
  };
};
