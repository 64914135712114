import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
import classNames from 'classnames';
import { Telicon } from '../../Icons';
import {
  getPhoneNumber,
  formatDuration,
  // getDateTime,
  formatDateTime
} from '@KazooSDK/utils';
import { KazooSDK } from '@KazooSDK';
import { EmptyResultBox } from '../EmptyResultBox';
import { useTranslation } from 'react-i18next';

// import { TELICON } from 'PhoneVoipIcon';

const CallDataWidget = props => {
  const { callData, history } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Typography
        onClick={() => history.push('/user_portal/call_history')}
        className={classes.title}
      >
        {t('callHistory.todaysCalls.label')}
      </Typography>
      <CallTable rows={callData} />
    </Paper>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    padding: theme.spacing(1)
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function CallTable(props) {
  const classes = useStyles();
  const { rows } = props;
  const { t } = useTranslation();

  if (!rows.length) {
    return (
      <EmptyResultBox
        // Icon={<Icon name="thumbs-up" />}
        icon='thumbs-up'
        title={t('callHistory.emptyToday.title.label')}
        text={t('callHistory.emptyToday.text.label')}
      />
    );
  }
  // console.log('rows:', rows);
  return (
    <Table className={classes.table} aria-label='customized table'>
      <TableBody>
        {rows.map((call, i) => {
          return (
            <StyledTableRow key={i}>
              <StyledTableCell component='td' scope='row'>
                {KazooSDK.isIncomingCall(call) ? (
                  KazooSDK.isMissedCall(call) ? (
                    <Telicon name='phone-missed' className={classes.missed} />
                  ) : (
                    <Telicon name='phone-inbound' className={classes.inbound} />
                  )
                ) : (
                  <Telicon name='phone-outbound' className={classes.outbound} />
                )}
              </StyledTableCell>
              <StyledTableCell>
                {KazooSDK.isIncomingCall(call) ? (
                  <>
                    <div
                      className={classNames([classes.callerId], {
                        [classes.red]: KazooSDK.isMissedCall(call)
                      })}
                    >
                      {call.caller_id_name}
                    </div>
                    <div className={classes.callerIdNumber}>
                      {getPhoneNumber(call.caller_id_number)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.callerId}>
                      {call.callee_id_name}
                    </div>
                    <div className={classes.callerIdNumber}>
                      {call.callee_id_number
                        ? getPhoneNumber(call.callee_id_number)
                        : getPhoneNumber(call.dialed_number)}
                    </div>
                  </>
                )}
              </StyledTableCell>
              {/* <StyledTableCell align="center">
              <div className={classes.callerId}>{call.callee_id_name}</div>
              <div className={classes.callerIdNumber}>
                {call.callee_id_number
                  ? getPhoneNumber(call.callee_id_number)
                  : getPhoneNumber(call.dialed_number)}
              </div>
            </StyledTableCell> */}
              <StyledTableCell align='center'>
                {/* <div className={classes.callerId}>
                {getDateTime(call.timestamp).date}
              </div> */}
                <div className={classes.callerIdNumber}>
                  {/* {getDateTime(call.timestamp).time} */}
                  {formatDateTime(call.timestamp, 'h:mm a')}
                </div>
              </StyledTableCell>
              <StyledTableCell align='center'>
                {formatDuration(call.duration_seconds)}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default CallDataWidget;
