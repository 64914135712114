import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      fontFamily: '"Source Sans Pro-Regular", sans-serif'
    },
    // width: 230,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // margin: '10px 0 0',
    margin: 0,
    height: '36px',
    boxShadow: 'none',
    float: 'right',
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0'
    }
  },
  input: {
    fontFamily: '"Source Sans Pro-Regular", sans-serif',
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

export default useStyles;
