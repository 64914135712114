import { get } from 'lodash';
import * as CONSTS from '../Constants';
import { KazooSDK } from '@KazooSDK';

export const setVmboxesLoading = (loadingVmboxes, loadedVmboxes) => {
  return async dispatch => {
    dispatch({
      type: CONSTS.SET_VMBOXES_LOADING,
      payload: {
        loadingVmboxes,
        loadedVmboxes
      }
    });
  };
};
export const setVmBoxLoading = (vmbox_id, bool) => {
  return async dispatch => {
    dispatch({
      type: CONSTS.SET_VMBOX_LOADING,
      payload: {
        vmbox_id,
        bool
      }
    });
  };
};

export const fetchVmBoxes = () => {
  return async dispatch => {
    try {
      dispatch(setVmboxesLoading(true));
      const response = await KazooSDK.getVmBoxes();
      const { data: vm_boxes } = response.data;
      dispatch({
        type: CONSTS.SET_VMBOXES,
        payload: { vm_boxes }
      });
      dispatch(setVmboxesLoading(false, true));
    } catch (e) {
      dispatch(setVmboxesLoading(false, true));
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchVmMessages = vmbox_id => {
  return async dispatch => {
    try {
      dispatch(setVmBoxLoading(vmbox_id, true));
      const response = await KazooSDK.getVmMessages(
        vmbox_id,
        '?filter_not_folder=deleted'
      );
      const { data: messages } = response.data;
      // console.log('MESSAGES:', messages);
      dispatch({
        type: CONSTS.SET_VMBOX_MESSAGES,
        payload: { vmbox_id, messages }
      });
      dispatch(setVmBoxLoading(vmbox_id, false));
    } catch (e) {
      dispatch(setVmBoxLoading(vmbox_id, false));
      console.error('failed:', e);
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchVoicemails = () => {
  return async dispatch => {
    try {
      const { messages, vm_boxes } = await KazooSDK.getAllVmMessages();
      if (messages.length) {
        dispatch({
          type: CONSTS.GET_ALL_MESSAGES,
          payload: { messages, vm_boxes }
        });
      }
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchVoicemailTranscription = (vmbox_id, message_id) => {
  return async dispatch => {
    try {
      const response = await KazooSDK.getVmMessageTranscription(
        vmbox_id,
        message_id
      );
      const { transcription } = response.data.data;
      if (!transcription) {
        dispatch({
          type: CONSTS.SET_VOICEMAIL_TRANSCRIPTION,
          payload: {
            messageId: message_id,
            messageTranscription: { result: 'none' }
          }
        });
      } else {
        dispatch({
          type: CONSTS.SET_VOICEMAIL_TRANSCRIPTION,
          payload: {
            messageId: message_id,
            messageTranscription: transcription
          }
        });
      }
    } catch (e) {
      console.error('failed;', e);
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const resetVoicemails = () => dispatch => {
  dispatch({
    type: CONSTS.RESET_VOICEMAIL
  });
};

export const updateVoicemailsState = state => {
  return dispatch => {
    dispatch({ type: CONSTS.UPDATE_VOICEMAIL_STATE, payload: state });
  };
};

export const changeVoicemailState = payload => {
  return dispatch => {
    dispatch({ type: CONSTS.CHANGE_VOICEMAIL_STATE, payload });
  };
};

export const moveVoicemailToFolder = (vmbox_id, message_id, folder) => {
  return async dispatch => {
    try {
      // TODO: handle temporary counts (made change to folders.new total, update from remote and sync local)

      // // TODO: save immediately, fallback on failure?
      // // - recommended ux for mobile, w/ flaky connection?
      dispatch(
        changeVoicemailState({
          vmboxId: vmbox_id,
          messageId: message_id,
          data: {
            folder
          }
        })
      );

      await KazooSDK.makeItem(vmbox_id, message_id, folder);

      // TODO: have saga/thunk (mobx runInAction)
      // TODO: fetch individual (not ALL) vmboxes
      dispatch(fetchVmBoxes());
    } catch (e) {
      console.error('failed;', e);
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};
