import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { PhoneNumberFlag } from '../PhoneNumberFlag';

// const calculateTotalCalls = (selected, callData) => {
//   if (selected.todayTotal > 1) {
//     return selected.todayTotal;
//   }
//   const reducedTotalCallsCount = callData
//     .map(data => data.todayCount)
//     .reduce((acc, cur) => acc + cur, 0);
//   return reducedTotalCallsCount > 0 ? reducedTotalCallsCount : 0;
// };

export const NumbersWidget = props => {
  const { phoneNumbers, history } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = newValue => {
    setValue(newValue);
  };

  //   const selected = callData[value] || { todayCount: 0, todayTotal: 1 };
  //   const selectedCallTotalPercent =
  //     Math.floor((selected.todayCount / selected.todayTotal) * 100) || 0;
  //   // const totalCalls = calculateTotalCalls(selected, callData);

  return (
    <Paper className={classes.root}>
      <div className={classes.topRow}>
        <Typography
          className={classes.title}
          onClick={() => history.push('/user_portal/devices_numbers')}
        >
          {t('devicesNumbers.numbers.label')}
        </Typography>
      </div>
      <div className={classes.secondRow}>
        <Typography className={classes.totalText}>
          {/* <span className={classes.total}>{totalCalls}</span> */}
          <span className={classes.total}>{phoneNumbers.length}</span>
          {t('devicesNumbers.numbers.total.label')}
        </Typography>
        {/* <Typography className={classes.usageText}>
          {t('devicesNumbers.numbers.usageToday.label')}
        </Typography> */}
      </div>
      <Divider />
      <div className={classes.bottomRow}>
        <div className={classes.listContainer}>
          <List className={classes.list}>
            {phoneNumbers.map((number, i) => (
              <ListItem
                className={i === value ? classes.selected : ''}
                button
                key={number}
                onClick={() => handleChange(i)}
              >
                <ListItemText
                  primary={number.length > 4 ? number : `x ${number}`}
                />
                {/* <img src="/usa.png" className="ml-1" alt="flag" /> */}
                <PhoneNumberFlag ptn={number} size={12} />
              </ListItem>
            ))}
          </List>
        </div>
        {/* <div className={classes.circleContainer}>
          <CircularProgressbar
            value={selectedCallTotalPercent}
            className={classes.progressBar}
          />
          <div className={classes.percentContainer}>
            <Typography className={classes.selectedPercent}>
              {selectedCallTotalPercent}%
            </Typography>
            <Typography className={classes.selectedCount}>
              {selected.todayCount} {t('devicesNumbers.numbers.calls.label')}
            </Typography>
          </div>
        </div> */}
      </div>
    </Paper>
  );
};

export default NumbersWidget;
