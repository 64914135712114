import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 321,
    overflow: 'auto'
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    marginBottom: theme.spacing(3),
    cursor: 'pointer'
  },
  th: {
    color: theme.palette.textGrey['500'],
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  icon: {
    color: theme.palette.inboundGrey['500'],
    height: 16,
    width: 16,
    margin: theme.spacing(2)
  },
  downloadIcon: {
    color: theme.palette.textGrey['500'],
    height: 16,
    width: 16,
    margin: theme.spacing(2)
  },
  from: {
    color: theme.palette.darkGrey['500'],
    fontSize: 14,
    fontWeight: 'normal'
  },
  faxbox: {
    color: theme.palette.textGrey['500'],
    fontSize: 12,
    fontWeight: 'normal'
  },
  displayRow: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  displayColumn: { display: 'flex', flexDirection: 'column' }
}));

export default useStyles;
