import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  settingsList: {
    width: '100vw',
    margin: '0 -4px',
    '& svg': {
      fontSize: 16,
      color: theme.palette.textGrey['500']
    }
  },
  settingsItem: {
    boxShadow: 'inset 0px 1px 0px 0px rgb(188, 188, 194)',
    '&:last-of-type': {
      boxShadow:
        'inset 0px -1px 0px 0px rgb(188, 188, 194), inset 0px 1px 0px 0px rgb(188, 188, 194)'
    }
  }
}));
