import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  rootLoading: {
    height: "80vh",
    // width: "100vw",
    width: '100%',
    textAlign: 'center',
    margin: 0,
    fontSize: '1rem'
  },
}));

export default useStyles;
