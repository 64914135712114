import React, { useState } from "react";
import classNames from "classnames";
import List from "@material-ui/core/List";
import { useStylesTableMobile } from "./styles";

import MobileVoicemailListItem from "./Message";

const MobileVoicemailList = props => {
  const { messagesData } = props;

  const classes = useStylesTableMobile();

  const [selected, setSelected] = useState(null);

  const handleClick = id => setSelected(id);

  return (
    <List
      className={classNames([classes.root], {
        [classes.rootExplain]: !!selected
      })}
    >
      {messagesData.map(message => {
        const isSelected = selected === message.media_id;
        return (
          <MobileVoicemailListItem
            key={message.media_id}
            handleClick={handleClick}
            setSelected={setSelected}
            message={message}
            isSelected={isSelected}
            {...props}
          />
        );
      })}
    </List>
  );
};

export default MobileVoicemailList;
