import { combineReducers } from 'redux';
import authReducer from './auth';
import settingsReducer from './settings';
import faxesReducer from '../Applications/UserPortal/Reducers/faxes';
import callsReducer from '../Applications/UserPortal/Reducers/calls';
import conferencesReducer from '../Applications/UserPortal/Reducers/conferences';
import devicesReducer from '../Applications/UserPortal/Reducers/devices';
import voicemailsReducer from '../Applications/UserPortal/Reducers/voicemails';
import applicationUserPortalReducer from '../Applications/UserPortal/Reducers/application';
import userSettingsReducer from '../Applications/UserPortal/Reducers/settings';

export default combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  calls: callsReducer,
  conferences: conferencesReducer,
  devices: devicesReducer,
  faxes: faxesReducer,
  voicemails: voicemailsReducer,
  userSettings: userSettingsReducer,
  application_user_portal: applicationUserPortalReducer
});
