import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    borderRadius: 2,
    padding: theme.spacing(2)
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  topRow: {
    marginBottom: theme.spacing(2)
  },
  secondRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  thirdRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftContainer: {
    display: 'flex'
  },
  rightContainer: {
    display: 'flex'
  },
  totalText: {
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal',
    letterSpacing: 0,
    width: 90,
    float: 'left'
  },
  restoreText: {
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal',
    letterSpacing: 0,
    color: theme.palette.textGrey['500'],
    cursor: 'pointer'
  },
  total: {
    fontSize: theme.spacing(4),
    fontWeight: 'normal',
    marginRight: theme.spacing(1)
  },
  usageText: {
    marginLeft: theme.spacing(6)
  },
  buttonFilter: {
    color: theme.palette.textGrey['500'],
    backgroundColor: theme.palette.white['500'],
    margin: '0px 16px 0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.white['500'],
      color: theme.palette.darkGrey['500'],
      boxShadow: '0px 0px 4px 0px rgb(21, 21, 23, 0.48)'
    }
  },
  buttonOpen: {
    boxShadow: '0px 0px 4px 0px rgba(34, 151, 255, 0.48)',
    border: `1px solid ${theme.palette.iconBlue['500']}`
  },
  buttonFiltered: {
    '& svg': {
      color: theme.palette.iconBlue['500']
    }
  },
  buttonClearAll: {
    marginRight: theme.spacing(1)
  },
  buttonApply: {
    color: theme.palette.darkGrey['500']
  },
  buttonAll: {
    borderWidth: 1,
    borderColor: theme.palette.black['500']
  },
  dateSeparator: {
    width: 12,
    borderBottom: `1.5px solid ${theme.palette.darkGrey['500']}`,
    margin: '32px 12px 0 12px',
    float: 'left'
  },
  wrapPicker: {
    display: 'inline-block'
  },
  datePicker: {
    float: 'left',
    border: `1.4px solid ${theme.palette.darkGrey['500']}`,
    padding: 4,
    borderRadius: 5,
    transition: 'border-color 0.2s',
    width: 150,
    '&:hover': {
      borderColor: theme.palette.hoverBlue['500']
    },
    '& label': {
      marginLeft: 4
    }
  },
  datePickerInnerInput: {
    paddingBottom: 4,
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '& button svg': {
      height: 16,
      width: 16
    }
  },
  buttonGroupActive: {
    backgroundColor: theme.palette.activeGrey['500']
  },
  secondCol: {
    marginLeft: 90,
    width: 'calc(100% - 90px)'
  },
  filterButtons: {
    width: '100%',
    '& button:first-child': {
      maxWidth: 60
    },
    '& button': {
      maxWidth: 140,
      color: theme.palette.textGrey['500']
    }
  },
  checkBox: {
    minWidth: 100,
    marginRight: 25,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary['500']
    },
    '& .MuiFormControlLabel-label': {
      borderRadius: 20,
      fontSize: 12,
      padding: '2px 10px',
    },
    '&.grayLabel .MuiFormControlLabel-label': {
      backgroundColor: theme.palette.textGrey['500'],
      color: theme.palette.white['500']
    },
    '&.redLabel .MuiFormControlLabel-label': {
      backgroundColor: theme.palette.red['500']
    },
    '&.greyLabel .MuiFormControlLabel-label': {
      backgroundColor: theme.palette.activeGrey['500']
    }
  },
  button: {
    color: theme.palette.textGrey['500'],
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 172,
    maxHeight: 36,
    margin: '0px 16px 0px 0px',
    backgroundColor: theme.palette.white['500'],
    borderWidth: 1,
    borderColor: theme.palette.inboundGrey['500'],
    borderRadius: 2,
    '&:hover': {
      color: theme.palette.darkGrey['500'],
      backgroundColor: theme.palette.white['500']
    },
    '&:active': {
      boxShadow: '0px 0px 4px 0px rgba(34, 151, 255, 0.48)',
      borderStyle: 'outset'
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      minWidth: 100,
    }
  },
  active: {
    background: theme.palette.activeGrey['500'],
    borderRadius: '2px 0 0 2px',
    color: theme.palette.selectBlack['500']
  },
}));

export const useStylesMobile = makeStyles((theme) => ({
  containerFilter: {
    display: 'flex',
    paddingRight: 20,
    marginBottom: theme.spacing(1),
    width: 'calc(100% - 230px)',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: 0,
    }
  },
  filterType: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0
    }
  },
  button: {
    color: theme.palette.textGrey['500'],
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 172,
    maxHeight: 36,
    [theme.breakpoints.down('md')]: {
      width: '50%',
      minWidth: 100,
    }
  },
  active: {
    background: `${theme.palette.activeGrey['500']} !important`,
    borderRadius: '2px 0 0 2px',
    color: theme.palette.selectBlack['500']
  }

}));

export default { useStyles, useStylesMobile };
