import { get } from 'lodash';
import * as CONSTS from '../Constants';
import { KazooSDK } from '@KazooSDK';

export const setConferencesLoading = (
  loadingConferences,
  loadedConferences
) => {
  return async dispatch => {
    dispatch({
      type: CONSTS.SET_CONFERENCES_LOADING,
      payload: {
        loadingConferences,
        loadedConferences
      }
    });
  };
};
export const setConferenceVertoConfigLoading = (conference_id, bool) => {
  return async dispatch => {
    dispatch({
      type: CONSTS.SET_CONFERENCE_VERTO_CONFIG_LOADING,
      payload: {
        conference_id,
        bool
      }
    });
  };
};

export const fetchConferences = () => {
  return async dispatch => {
    try {
      dispatch(setConferencesLoading(true));
      const response = await KazooSDK.getConferences();
      const { data: conferences } = response.data;
      dispatch({
        type: CONSTS.SET_CONFERENCES,
        payload: { conferences }
      });
      dispatch(setConferencesLoading(false, true));
    } catch (e) {
      dispatch(setConferencesLoading(false, true));
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchConferencesCallInNumbers = () => {
  return async dispatch => {
    try {
      // dispatch(setConferencesLoading(true));
      const numbers = await KazooSDK.getConferencesCallInNumbers();
      dispatch({
        type: CONSTS.SET_CONFERENCES_CALL_IN_NUMBERS,
        payload: { numbers }
      });
      // dispatch(setConferencesLoading(false, true));
    } catch (e) {
      // dispatch(setConferencesLoading(false, true));
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchConferenceVertoConfig = conference_id => {
  return async dispatch => {
    try {
      dispatch(setConferenceVertoConfigLoading(conference_id, true));
      const response = await KazooSDK.getConferenceVertoConfig(conference_id);
      const { data: verto_config } = response.data;
      dispatch({
        type: CONSTS.SET_CONFERENCE_VERTO_CONFIG,
        payload: { conference_id, verto_config }
      });
      dispatch(setConferenceVertoConfigLoading(conference_id, false));
    } catch (e) {
      dispatch(setConferenceVertoConfigLoading(conference_id, false));
      console.error('failed:', e);
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};
