import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import prepareHomeState from '../../Selectors/Home';
import { fetchInitialData } from '../../Actions/home';
import { fetchVmBoxes } from '../../Actions/voicemails';
import { fetchInitialData as fetchInitialDataCallHistory } from '../../Actions/callHistory';
import { Grid, Hidden, List, ListItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import useStyles from './styles';
// import { useTranslation } from 'react-i18next';

import { useWebsocketBinding } from '@Util/websocketBinding';

import { CallCount } from './Components/CallCount';
import { VoicemailCount } from './Components/VoicemailCount';
import { FaxCount } from './Components/FaxCount';
// import { CountWidget } from '../Common/CountWidget';
// import MissedCallsWidget from '../Common/MissedCallsWidget';
import { fetchConferences } from './../../Actions/conferences';
import { NumbersWidget } from '../Common/NumbersWidget';
import CallDataWidget from '../Common/CallDataWidget';
import DevicesWidget from '../Common/DevicesWidget';
import FaxesWidget from '../Common/FaxesWidget';
// import NewVoicemailsWidget from '../Common/NewVoicemailsWidget';
import { Telicon } from '../Icons';

const calledChannels = {};

const Home = (props) => {
  const {
    fetchInitialData,
    fetchVmBoxes,
    fetchInitialDataCallHistory,
    fetchConferences,
    history
  } = props;

  const classes = useStyles();

  // const { t } = useTranslation();

  useEffect(() => {
    fetchInitialData();
    fetchConferences();
  }, [history.location, fetchInitialData, fetchConferences]);

  const routeSettings = useCallback(() => {
    history.push('/user_portal/settings');
  }, [history]);

  // TODO: move bindings to individual widgets (get out of this component)
  // - wedget for counting missed calls, etc.
  useWebsocketBinding(
    'object.doc_edited.mailbox_message', // this also gets NEW voicemails!
    (event) => {
      // fetchInitialData(); // TODO: abort previous attempts to fetch
      fetchVmBoxes();
    },
   true,  // set shouldBind true
   false
  );
  useWebsocketBinding(
    'call.CHANNEL_DESTROY.*', // get call history
    (event) => {
      // only need to listen for a single CHANNEL_DESTROY call for the call_id
      const interactionId =
        event?.data?.custom_channel_vars?.call_interaction_id;
      if (!calledChannels[interactionId]) {
        calledChannels[interactionId] = true;
        setTimeout(fetchInitialDataCallHistory, 1000); // TODO: abort previous attempts to fetch
      }
    },
    true,
    false
  );

  const lng = 'en-US';

  return (
    <>
      {/* <Hidden xsDown> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} className={classes.gridItem}>
          <CallCount />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.gridItem}>
          <VoicemailCount />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.gridItem}>
          <FaxCount />
        </Grid>

        <Hidden xsDown>
          <Grid item sm={6} md={6} className={classes.gridItem}>
            <CallDataWidget
              lng={lng}
              history={history}
              callData={props.recentCallData}
            />
          </Grid>
          <Grid item sm={6} md={6} className={classes.gridItem}>
            <NumbersWidget
              lng={lng}
              history={history}
              callData={props.callData}
              phoneNumbers={props.phoneNumbers}
            />
          </Grid>

          <Grid item sm={6} md={6} className={classes.gridItem}>
            <DevicesWidget
              register_device={props.register_device}
              unregister_device={props.unregister_device}
              lng={lng}
              history={history}
            />
          </Grid>
          <Grid item sm={6} md={6} className={classes.gridItem}>
            <FaxesWidget
              lng={lng}
              faxbox={props.faxes.faxboxById}
              faxes_outbox_data={props.outboxFaxes}
              faxes_inbox_data={props.inboxFaxes}
              history={history}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <List className={classes.settingsList}>
            <ListItem className={classes.settingsItem} onClick={routeSettings}>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item xs={2}>
                  Settings
                </Grid>
                <Grid item xs={1}>
                  <Telicon name='chevron-right' />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Hidden>
      </Grid>

      {/* </Hidden> */}
      {/* <Hidden smUp>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridItem}>
            <CallCount />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <VoicemailCount />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <FaxCount />
          </Grid>

          <List className={classes.settingsList}>
            <ListItem className={classes.settingsItem} onClick={routeSettings}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={2}>
                  Settings
                </Grid>
                <Grid item xs={1}>
                  <Telicon name="chevron-right" />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
      </Hidden> */}
    </>
  );
};

const mapStateToProps = prepareHomeState;

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchInitialData,
    fetchVmBoxes,
    fetchInitialDataCallHistory,
    fetchConferences
  })
)(Home);
