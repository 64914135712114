import React, { useReducer } from 'react';

import { Grid, Menu, MenuItem, Button } from '@material-ui/core';
import useStyles from './styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Telicon } from '../../Icons';
import SearchWidget from '../../Common/SearchWidget';
import { ConferenceItem } from '../ConferenceItem';
import { flatten, map } from 'lodash';
import { getPhoneNumber } from '@KazooSDK/utils';

const ConferenceList = props => {
  const { conferences, numbers } = props;

  const initState = {
    anchorEl: null,
    filter: ''
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({
      ...preState,
      ...newState
    }),
    initState
  );

  const { anchorEl, filter } = state;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = event => {
    setState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ anchorEl: null });
  };

  const handleChangeFilter = value => {
    setState({ filter: value.toLowerCase() });
  };

  const allNumbers = flatten(map(numbers, 'numbers')).map(getPhoneNumber);

  let conferencesFilteredByText = conferences
    .filter(conference =>
      filter === ''
      || conference.name.toLowerCase().includes(filter)
      || conference.numbers.toLowerCase().includes(filter));
  
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <h2 className={classes.singleTitle}>
            {t('conferences.meetingRooms.label')}
          </h2>
        </Grid>
      </Grid>

      <Grid container className={classes.containerFilter}>
        <Grid item xs={12}>
          <Grid
            justify="space-between" // Add it here :)
            container
          >
            <Grid item>
              <Button
                variant="contained"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<Telicon name="caret-down" />}
                disableRipple={true}
              >
                Call-In Numbers
              </Button>
              <Menu
                id="simple-menu"
                className={classes.callInMenu}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {allNumbers.map(number => (
                  <MenuItem key={number} onClick={handleClose}>
                    {number}
                  </MenuItem>
                ))}
                {!allNumbers.length && (
                  <MenuItem onClick={handleClose}>
                    No Call-In Numbers Assigned
                  </MenuItem>
                )}
              </Menu>
            </Grid>
            
            <Grid item>
              <SearchWidget handleChangeFilter={handleChangeFilter} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Grid container>
            {
              conferencesFilteredByText.map(conference => (
                  <ConferenceItem
                    key={conference.id}
                    conference={conference}
                    allNumbers={allNumbers}
                  />
                ))
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { conferences, numbers } = state.conferences;
  return {
    conferences: conferences
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      .map(conference => ({
        ...conference,
        numbers: conference.conference_numbers?.length ? conference.conference_numbers?.join(', '):'None Assigned'
      })),
    numbers
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {})
)(ConferenceList);
