import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import {
  fetchFullData,
  toggleUserCallForwarding,
  toggleDeviceDoNotDisturb
} from 'Applications/UserPortal/Actions/settings';
import prepareTray from 'Applications/UserPortal/Selectors/Tray';
import { openWebPhone } from 'Components/WebPhone';
import { logout } from 'Actions/auth';

const CONFIG = require('Config');

export const DesktopTrayMenu = (): null => {
  const history = useHistory();
  const {
    authenticated,
    fullName,
    newVoicemailCount,
    newFaxCount,
    defaultFaxboxId,
    callForwarding,
    doNotDisturb
  } = useSelector(prepareTray);
  const dispatch = useDispatch();
  const [deviceId] = useLocalStorage('webphone_device_id', 'undefined', {
    raw: true
  });

  useEffect(() => {
    dispatch(fetchFullData());
  }, [dispatch]);

  useEffect(() => {
    const trayMenuTemplate = (authenticated
      ? [
          {
            label: 'Comm.io',
            enabled: false
          },
          {
            label: 'Show Comm.io',
            click: () => {
              window.commio.app.window.focusMainWindow();
            }
          },
          { type: 'separator' },
          newVoicemailCount
            ? {
                label: `${newVoicemailCount} New Voicemails`,
                click: () => {
                  history.push('/user_portal/voicemails');
                  window.commio.app.window.focusMainWindow();
                }
              }
            : undefined,
          newFaxCount
            ? {
                label: `${newFaxCount} New Faxes`,
                click: () => {
                  history.push('/user_portal/faxes');
                  window.commio.app.window.focusMainWindow();
                }
              }
            : undefined,

          defaultFaxboxId
            ? {
                label: 'Send Fax',
                click: () => {
                  window.openSendForm = true;
                  history.push(`/user_portal/faxes/faxbox/${defaultFaxboxId}`);
                  window.commio.app.window.focusMainWindow();
                }
              }
            : undefined,
          { type: 'separator' },
          CONFIG.ENABLE_WEBPHONE &&
          CONFIG.ENABLE_WEBPHONE !== 'false' &&
          deviceId &&
          deviceId !== 'undefined'
            ? {
                label: 'Webphone',
                enabled: false
              }
            : undefined,
          CONFIG.ENABLE_WEBPHONE &&
          CONFIG.ENABLE_WEBPHONE !== 'false' &&
          deviceId &&
          deviceId !== 'undefined'
            ? {
                label: 'Open Webphone',
                click: openWebPhone
              }
            : undefined,
          { type: 'separator' },
          callForwarding.number
            ? {
                label: 'Call Forwarding',
                enabled: false
              }
            : undefined,
          callForwarding.number
            ? {
                label: `${
                  callForwarding.isEnabled ? 'Disable' : 'Enable'
                } for ${callForwarding.number}`,
                click: () => {
                  dispatch(toggleUserCallForwarding());
                }
              }
            : undefined,
          { type: 'separator' },
          doNotDisturb.devices.length > 0
            ? {
                label: 'Do Not Disturb',
                submenu: [
                  ...doNotDisturb.devices.map(
                    ({
                      name,
                      id,
                      isDoNotDisturbEnabled
                    }: {
                      name: string;
                      id: string;
                      isDoNotDisturbEnabled: boolean;
                    }) => ({
                      label: `${
                        isDoNotDisturbEnabled ? 'Disable' : 'Enable'
                      } for ${name}`,
                      click: () => {
                        dispatch(toggleDeviceDoNotDisturb(id));
                      }
                    })
                  )
                ]
              }
            : undefined,
          { type: 'separator' },
          {
            label: 'Settings',
            click: () => {
              history.push('/user_portal/settings');
              window.commio.app.window.focusMainWindow();
            }
          },
          CONFIG.DEVELOPER_TOOLS && CONFIG.DEVELOPER_TOOLS !== 'false'
            ? {
                label: 'Logout',
                click: () => {
                  dispatch(logout());
                  window.commio.app.window.focusMainWindow();
                }
              }
            : undefined,
          {
            label: 'Quit Comm.io',
            click: () => {
              window.commio.app.window.quitApplication();
            }
          }
        ]
      : [
          {
            label: 'Comm.io',
            enabled: false
          },
          {
            label: 'Show Comm.io',
            click: () => {
              window.commio.app.window.focusMainWindow();
            }
          },
          {
            label: 'Quit Comm.io',
            click: () => {
              window.commio.app.window.quitApplication();
            }
          }
        ]
    ).filter((c) => !!c);
    try {
      window.commio.app.tray.setTrayIcon(trayMenuTemplate);
    } catch (err) {
      console.error('Tray error:', err);
    }
  }, [
    dispatch,
    deviceId,
    authenticated,
    fullName,
    newVoicemailCount,
    newFaxCount,
    defaultFaxboxId,
    history,
    callForwarding,
    doNotDisturb
  ]);

  return null;
};

export default DesktopTrayMenu;
