import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Telicon } from '../../Icons';
import $ from 'jquery';
import { Image } from '../Image';

const scroll = direction => {
  const far = ($('.device-container').width() / 2) * direction;
  const pos = $('.device-container').scrollLeft() + far;
  $('.device-container').animate({ scrollLeft: pos }, 1000);
};

const DevicesWidget = props => {
  const { register_device, unregister_device, history } = props;
  const unregister = unregister_device ? unregister_device.length : 0;
  const register = register_device ? register_device.length : 0;
  const total = unregister + register;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div>
        <Typography
          className={classes.title}
          onClick={() => history.push('/user_portal/devices_numbers')}
        >
         {t('devicesNumbers.devices.label')}
        </Typography>
      </div>
      <div className={classes.totalRow}>
        <Typography className={classes.totalNumber}>
          {total}
          <span className={classes.totalText}>Total</span>
        </Typography>
        <Typography className={classes.unregisteredNumber}>
          {unregister}
          <span className={classes.unregisteredText}>Unregistered</span>
        </Typography>
      </div>
      <Divider />
      <div className={`${classes.carousel} device-container`}>
        {unregister_device &&
          unregister_device.map((device, index) => {
            return (
              <Paper key={index} className={classes.box}>
                <div className={classes.squareContainer}>
                  {device.device_type === 'sip_device' && (
                    <div>
                      <Telicon
                        name="device-voip-phone"
                        className={classes.unregisteredIcon}
                      />
                      <Image src={'/desk.png'} alt="device" />
                    </div>
                  )}
                  {device.device_type === 'cellphone' && (
                    <div>
                      <Telicon
                        name="device-mobile"
                        className={classes.unregisteredIcon}
                      />
                      <Image src={'/cell.png'} alt="device" />
                    </div>
                  )}
                  {device.device_type === 'softphone' && (
                    <div>
                      <Telicon
                        name="device-soft-phone"
                        className={classes.unregisteredIcon}
                      />
                      <Image src={'/device-soft.png'} alt="device" />
                    </div>
                  )}
                  {device.device_type === 'application' && (
                    <div>
                      <Telicon
                        name="device-sprint-phone"
                        className={classes.unregisteredIcon}
                      />
                      <Image src={'/application.png'} alt="device" />
                    </div>
                  )}
                  <div className="mt-2 name">
                    {device.name.length > 50
                      ? device.name.substr(0, 50)
                      : device.name}
                  </div>
                  <div className="number">{device.mac_address}</div>
                </div>
              </Paper>
            );
          })}
        {register_device &&
          register_device.map((device, index) => (
            <Paper key={index} className={classes.box}>
              <div className={classes.squareContainer}>
                {device.device_type === 'sip_device' && (
                  <div>
                    <Telicon
                      name="device-voip-phone"
                      className={classes.registeredIcon}
                    />
                    <Image src={'/desk.png'} alt="device" />
                  </div>
                )}
                {device.device_type === 'cellphone' && (
                  <div>
                    <Telicon
                      name="device-mobile"
                      className={classes.registeredIcon}
                    />
                    <Image src="/cell.png" alt="device" />
                  </div>
                )}
                {device.device_type === 'softphone' && (
                  <div>
                    <Telicon
                      name="device-soft-phone"
                      className={classes.registeredIcon}
                    />
                    <Image src={'/device-soft.png'} alt="device" />
                  </div>
                )}
                {device.device_type === 'application' && (
                  <div>
                    <Telicon
                      name="device-sprint-phone"
                      className={classes.registeredIcon}
                    />
                    <Image src="/application.png" alt="device" />
                  </div>
                )}
                <div className="mt-2 name">
                  {device.name.length > 50
                    ? device.name.substr(0, 50)
                    : device.name}
                </div>
                <div className="number">{device.mac_address}</div>
              </div>
            </Paper>
          ))}
        {total > 1 && (
          <>
            <span className="prev" onClick={() => scroll(-1)}>
              &#10094;
            </span>
            <span className="next" onClick={() => scroll(1)}>
              &#10095;
            </span>
          </>
        )}
      </div>
    </Paper>
  );
};

export default DevicesWidget;
