import makeStyles from '@material-ui/core/styles/makeStyles';

export const rootStyles = makeStyles(theme => ({
  root: {
    '& p': {
      // color: theme.palette.darkGrey['500'],
      // fontSize: 14,
      margin: '0'
    },
    '& h6': {
      fontSize: 16,
      fontWeight: 600,
      margin: '0 0 10px'
    },
    '& h4': {
      fontSize: 32,
      fontWeight: 600,
      // margin: '0 0 10px',
      margin: 0,
      color: theme.palette.darkGrey['500']
    },
    '& a': {
      textDecoration: 'unset'
    }
  },
  rootLoading: {
    textAlign: 'center',
    height: '80vh'
  },
  pd16: {
    paddingBottom: 16
  },
  singleTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    marginBottom: theme.spacing(2)
  },
  wrapperNone: {
    background: theme.palette.activeGreyTransparent['500'],
    borderRadius: 2,
    marginTop: 25,
    padding: 60,
    textAlign: 'center',
    '& svg': {
      color: theme.palette.inboundGrey['500'],
      height: 32,
      width: 32
    },
    '& h4': {
      color: theme.palette.grey['500'],
      fontSize: 16
    },
    '& p': {
      color: theme.palette.grey['500'],
      fontSize: 14
    }
  }
}));

export const faxBoxStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.white['500'],
    backgroundColor: theme.palette.white['500'],
    padding: '16px 24px',
    borderRadius: 2,
    boxShadow: `0px 2px 3px 1px  ${theme.palette.inboundGrey['500']}`
  },
  wrapTitle: {
    '& h6': {
      marginBottom: 0
    }
  },

  faxIdentity: {
    marginBottom: 4,
    padding: 0,
    '& p': {
      marginRight: 8
    }
  }
}));

export default { rootStyles, faxBoxStyles };
