import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    "& .newLabel": {
      float: "left",
      background: theme.palette.green["500"],
      padding: "0px 6px",
      fontSize: 12,
      borderRadius: 25
    },
    "& .listenedLabel": {
      float: "left",
      background: theme.palette.activeGrey["500"],
      padding: "0px 6px",
      fontSize: 12,
      borderRadius: 25
    }
  },
  rootLoading: {
    height: "80vh",
    width: "100vw",
    textAlign: 'center',
    margin: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  singleTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 354,
    minHeight: 48,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
      margin: 0
    }
  },
  selectVoicemail: {
    "& .MuiSelect-selectMenu": {
      height: "18px !important",
      padding: "15px 14px",
      fontSize: "24px",
      display: "flex",
      alignItems: "center"
    },
    "& .newLabel": {
      padding: "2px 6px",
      marginLeft: 15,
      float: "unset"
    },
    "& .noneNew": {
      display: "none"
    }
  },
  selectVoicemailItem: {
    display: "flex",
    justifyContent: "space-between",
    "& .newLabel": {
      float: "left",
      background: theme.palette.green["500"],
      padding: "3px 6px",
      fontSize: 12,
      borderRadius: 25
    },
    "& .noneNew": {
      color: theme.palette.textGrey["500"]
    }
  },
  gridFlex: {
    flexBasis: 0
  },
  mainMargin: {
    marginLeft: -20,
    marginTop: -10
  },
  containerMargin: {
    display: "flex",
    flexWrap: "wrap"
  },
  newCountText: {
    backgroundColor: theme.palette.voicemailIconGreen["500"],
    borderRadius: 10,
    fontSize: 12,
    fontWeight: 600,
    padding: "2px 6px"
  },
  vmText: {
    fontSize: 24,
    fontWeight: "normal"
  },
  vmParentBox: {
    display: "flex",
    alignItems: "flex-start"
  },
  vmTotalCount: {
    fontSize: "24px",
    fontWeight: 600,
    color: theme.palette.darkGrey["500"]
  },
  vmCountText: {
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.darkGrey["500"]
  },
  buttonMultiDelete: {
    color: theme.palette.textGrey["500"],
    marginLeft: 10,
    width: 40,
    height: 40,
    background: `linear-gradient(-180deg, ${theme.palette.white["500"]} 0%, ${theme.palette.sidebarText["500"]} 100%)`,
    borderRadius: 2,
    border: `1px solid ${theme.palette.inboundGrey["500"]}`,
    minWidth: 40,
    "& svg": {
      height: 20,
      width: 20
    }
  },
  dialog: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .MuiLinearProgress-root": {
      minWidth: 300,
      height: 10
    }
  },
  wrapperNone: {
    background: theme.palette.activeGreyTransparent["500"],
    borderRadius: 2,
    marginTop: 25,
    padding: 60,
    textAlign: "center",
    "& svg": {
      color: theme.palette.inboundGrey["500"],
      height: 32,
      width: 32
    },
    "& h4": {
      color: theme.palette.grey["500"],
      fontSize: 16
    },
    "& p": {
      color: theme.palette.grey["500"],
      fontSize: 14
    }
  }
}));
