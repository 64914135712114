import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  name: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  row: {
    '&:hover': {
      background: theme.palette.bgRowGrey['500']
    }
  },
  button: {
    marginTop: 4,
    borderRadius: 2,
    height: 36,
    minWidth: 100,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& .MuiButton-endIcon': {
        margin: '0 8px 0 -4px'
      }
    }
  },
  buttonJoin: {
    background: theme.palette.iconBlue['500'],
    border: `1px solid ${theme.palette.darkBlue['500']}`,
    color: theme.palette.darkGrey['500']
  },
  buttonLocked: {
    background: theme.palette.activeGreyTransparent['500'],
    border: `1px solid ${theme.palette.svgGrey['500']}`,
    color: theme.palette.darkGrey['500']
  },
}));

export default useStyles;
