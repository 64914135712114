import React, { useRef } from 'react';
import { Bar } from './Bar'
import { useTranslation } from 'react-i18next';
import useAudioPlayer from './useAudioPlayer'
import './style.css';

export const AudioPlayer = (props) => {
  const { URLMedia, handleEnded, handlePlay, mediaId, autoPlay } = props;

  const audioEl = useRef(null);

  const { currentTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer(audioEl);

  const { t } = useTranslation();

  return (
    <div className='container-audio'>
      <audio
        autoPlay={autoPlay}
        ref={audioEl}
        onPlay={() => {
          handlePlay(mediaId);
        }}
        onEnded={() => {
          setPlaying(false);
          handleEnded(mediaId);
        }}
        // controlsList='nodownload'
      >
        <source src={URLMedia} />
        <p> {t('voicemails.audioPlayer.notSupported.label')} </p>
      </audio>
      <Bar
        playing={playing}
        setPlaying={setPlaying}
        duration={duration}
        currentTime={currentTime}
        onTimeUpdate={setClickedTime}
      />
    </div>
  );
};

export default AudioPlayer;
