import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  containerFilter: {
    display: 'flex',
    // paddingRight: 20,
    // width: 'calc(100% - 230px)',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      // width: '100%',
      // paddingRight: 0,
      // display: 'block',
      // marginTop: 15
    }
  },
  filterType: {
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 20
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  button: {
    fontFamily: '"Source Sans Pro-Regular", sans-serif',
    color: theme.palette.textGrey['500'],
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 172,
    maxHeight: 36,
    [theme.breakpoints.down('md')]: {
      width: '50%',
      minWidth: 100
    }
  },
  active: {
    background: `${theme.palette.activeGrey['500']} !important`,
    borderRadius: '2px 0 0 2px',
    color: theme.palette.selectBlack['500']
  },
  wrapperFilter: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  datePicker: {
    // width: 140,
    width: '100%',
    margin: 0,
    // margin: '0 10px 10px 0',
    padding: '3px 4px',
    borderRadius: 5,
    transition: 'border-color 0.2s',
    background: theme.palette.white['500'],
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    '&:hover': {
      borderColor: theme.palette.hoverBlue['500']
    },
    '& svg': {
      color: theme.palette.svgGrey['500']
    }
  },
  datePickerInnerInput: {
    padding: '2.5px 0',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '& button svg': {
      height: 16,
      width: 16
    }
  },
  buttonFilter: {
    background: `linear - gradient(-180deg, 
      ${theme.palette.white['500']} 0 %,
      ${theme.palette.sidebarText['500']} 100 %)`,
    color: theme.palette.grey['500'],
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    borderRadius: 2
  }
}));

export default useStyles;
