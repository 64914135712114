import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultPageLoader } from '../Common/DefaultPageLoader';
import { EmptyResultBox } from '../Common/EmptyResultBox';

import { ConferenceList } from './ConferenceList';

import {
  fetchConferences,
  fetchConferencesCallInNumbers
} from '../../Actions/conferences';

const Conferences = (props) => {
  const {
    conferences,
    loadingConferences,
    fetchConferences,
    fetchConferencesCallInNumbers,
    history
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    fetchConferences();
    fetchConferencesCallInNumbers();
  }, [history.location, fetchConferences, fetchConferencesCallInNumbers]);

  // useWebsocketBinding(
  //   ['conference.event.*.*'], // TODO: get correct websockets to update automatically on New conferences
  //   data => {
  //     console.log('conference event:', data);
  //     fetchConferences(); // TODO: abort previous attempts to fetch
  //   }
  // );

  if (conferences.length > 0) {
    return <ConferenceList />;
  }

  if (loadingConferences) {
    return <DefaultPageLoader text="Loading Conferences..." />;
  }

  return (
    <EmptyResultBox
      icon={'user-group'}
      title={t('conferences.emptyTitle.label')}
      text={t('conferences.emptyText.label')}
    />
  );
};

const mapStateToProps = (state, props) => {
  const {
    conferences,
    loadingConferences,
    loadedConferences
  } = state.conferences;
  return {
    conferences,
    loadingConferences,
    loadedConferences
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchConferences,
    fetchConferencesCallInNumbers
  })
)(Conferences);
