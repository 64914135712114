import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  singleTitle: {},
  callInMenu: {
    marginTop: 50
  },
  containerFilter: {
    display: 'flex',
    // paddingRight: 20,
    // width: 'calc(100% - 230px)',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      // width: '100%',
      // paddingRight: 0,
      // display: 'block',
      // marginTop: 15
    }
  }
}));

export default useStyles;
