import React, { useReducer, useEffect } from 'react';
import {
  Grid
  // Box,
  // Hidden,
  // FormControl,
  // Select,
  // MenuItem,
  // Button,
  // Container
} from '@material-ui/core';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Dialog from '@material-ui/core/Dialog';
import useStyles from './styles';
// import VoicemailsActionbar from "./ActionBar";
import { connect } from 'react-redux';
import {
  resetVoicemails,
  updateVoicemailsState,
  changeVoicemailState,
  fetchVmBoxes,
  fetchVoicemails
} from '../../Actions/voicemails';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
// import { getVoicemailMethods } from "@KazooSDK/helpers/VoicemailsListHelpers";
// import SearchWidget from "../Common/SearchWidget";
// import { filterVoicemail } from "@KazooSDK/utils";
import { useTranslation } from 'react-i18next';
import { DefaultPageLoader } from '../Common/DefaultPageLoader';
import { EmptyResultBox } from '../Common/EmptyResultBox';

import VoicemailsBoxList from './BoxesList';
import { VoicemailsBoxView } from './ViewBox';

import { useParams } from 'react-router-dom';
import { useWebsocketBinding } from '@Utils/websocketBinding';

export const Voicemails = props => {
  const {
    vmBoxes,
    // vmboxById,
    history,
    // messages,
    // vmBoxIndex,
    fetchVmBoxes
    // fetchVoicemails,
    // resetVoicemails
  } = props;

  useWebsocketBinding(
    ['object.doc_edited.mailbox_message'], // this also gets NEW voicemails!
    'userportal.voicemails',
    data => {
      fetchVmBoxes(); // TODO: abort previous attempts to fetch
    },
    true,  // set shouldBind true
    false
  );

  const classes = useStyles();

  const { t } = useTranslation();

  const initState = {
    loading: false
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { loading } = state;

  const { vmbox_id } = useParams();

  useEffect(() => {
    setState({ loading: true });
    (async () => {
      // await fetchVoicemails();
      await fetchVmBoxes();
      setState({ loading: false });
    })();
    // return () => {
    //   resetVoicemails(); // reset voicemails on exit?
    // };
  }, [history.location, fetchVmBoxes]);

  // route to default vmBox if only 1
  useEffect(() => {
    if (vmBoxes.length === 1 && !vmbox_id) {
      history.replace(`/user_portal/voicemails/vmbox/${vmBoxes[0].id}`);
    }
  }, [loading, vmBoxes, vmbox_id, history]);

  if (vmbox_id) {
    return (
      <div className={classes.root}>
        {/* <Dialog
        className={classes.dialog}
        open={loading}
      >
        <LinearProgress />
      </Dialog> */}
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <VoicemailsBoxView vmbox_id={vmbox_id} />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (vmBoxes.length > 1) {
    return (
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <h2 className={classes.singleTitle}>{t('voicemails.vBoxText.label')}</h2>
            <VoicemailsBoxList vmBoxes={vmBoxes} />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (vmBoxes.length === 0 && !loading) {
    return (
      <EmptyResultBox
        icon={'voicemail'}
        title={t('voicemails.vmBoxesEmptyTitle.label')}
        text={t('voicemails.vmBoxesEmptyText.label')}
      />
    );
  }

  return <DefaultPageLoader text={t('voicemails.loadingVoicemailBoxes.label')} />;
};

const mapStateToProps = (state, props) => {
  const vmBoxes = (state.voicemails.vm_boxes || []).sort((vm1, vm2) => {
    return (vm2?.folders?.new ?? 0) - (vm1?.folders?.new ?? 0);
  });
  let selected = props.match.params.vmbox_id;
  let tempVmBoxIndex = vmBoxes.findIndex(element => element.id === selected);
  tempVmBoxIndex = tempVmBoxIndex < 0 ? 0 : tempVmBoxIndex;

  return {
    messages: [], //state.voicemails.messages,
    vmBoxes,
    vmboxById: state.voicemails.vmboxById,
    vmBoxIndex: tempVmBoxIndex,
    order: state.voicemails.order,
    orderBy: state.voicemails.orderBy,
    page: state.voicemails.page,
    rowsPerPage: state.voicemails.rowsPerPage,
    makeCheckedItem: state.voicemails.makeCheckedItem || [],
    makeCheckedItemObject: state.voicemails.makeCheckedItemObject || []
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchVmBoxes,
    fetchVoicemails,
    resetVoicemails,
    updateVoicemailsState,
    changeVoicemailState
  })
)(Voicemails);
