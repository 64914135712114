import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import isElectron from 'is-electron';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { KazooSDK } from '@KazooSDK';
import useStyles, { Toolbar } from './styles';
import { Telicon } from '../../Components/Icons';
import CONFIG from './../../../../Config';
import { useTranslation } from 'react-i18next';
import { openWebPhone } from './../../../../Components/WebPhone';

const Topbar = (props) => {
  const { t } = useTranslation();
  const title = getTitle(props.history.location.pathname, t);
  const { handleDrawerToggle, miniMenu } = props;
  const classes = useStyles({ miniMenu });
  const [isMobile] = useSessionStorage('mobile', false);
  const [webphoneDeviceId, setWebphoneDeviceId] = useState(null);

  const setUpWebPhone = async () => {
    const realm = await KazooSDK.getAccountRealm();
    window.localStorage.setItem('auth_realm', realm);
    window.localStorage.setItem('websocket_url', CONFIG.WEBSOCKET_URL);
  };

  const setUpUserWebphoneDevice = async () => {
    const { id, enabled } =
      (await KazooSDK.getUserDefaultWebphoneDevice()) || {};
    if (id && enabled) {
      window.localStorage.setItem('webphone_device_id', id);
      setWebphoneDeviceId(id);
      window.commio.webphone.setUpWebphone();
    } else {
      window.localStorage.removeItem('webphone_device_id');
    }
  };

  useEffect(() => {
    if (
      isElectron() &&
      CONFIG.ENABLE_WEBPHONE &&
      CONFIG.ENABLE_WEBPHONE !== 'false'
    ) {
      setUpWebPhone();
      setUpUserWebphoneDevice();
    }
    return () => {
      if (isElectron()) {
        window.localStorage.setItem('webphone_device_id', ' ');
        window.localStorage.removeItem('webphone_device_id');
        window.commio.webphone.closeWebphone();
      }
    };
  }, []);

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.typography} noWrap>
            {title}
          </Typography>
        </div>
        {!isMobile && (
          <div className={classes.webPhone}>
            {isElectron() &&
              CONFIG.ENABLE_WEBPHONE &&
              CONFIG.ENABLE_WEBPHONE !== 'false' &&
              webphoneDeviceId &&
              webphoneDeviceId !== 'undefined' && (
                <button
                  className={classes.webPhoneButton}
                  onClick={openWebPhone}
                >
                  <Telicon name='phone' className={classes.webPhoneButton} />
                </button>
              )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

const getTitle = (route, t) => {
  const appSplit = route.split('/user_portal/')[1];
  const name = appSplit ? appSplit.split('/')[0] : appSplit;
  switch (name) {
    case 'home':
      return t('home.label');

    case 'voicemails':
      return t('voicemails.label');

    case 'call_history':
      return t('callHistory.label');

    case 'devices_numbers':
      return t('devicesNumbers.label');

    case 'faxes':
      return t('faxes.label');

    case 'settings':
      return t('settings.label');

    case 'conferences':
      return t('conferences.label');

    default:
      return t('home.label');
  }
};

const mapStateToProps = (state) => {
  return {
    lng: state.settings.language.toLowerCase()
  };
};

export default withRouter(connect(mapStateToProps)(Topbar));
