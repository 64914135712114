import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  centerContainer: {
    textAlign: 'center'
  },
  grid: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    width: 448,
    // height: 445,
    [theme.breakpoints.down('sm')]: {
      width: 280
    }
  },
  card: {
    width: 448,
    // height: 445,
    [theme.breakpoints.down('sm')]: {
      width: 280
    },
    overflow: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.white['500'],
    height: 30
  },
  button: {
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.primary.main,
    position: 'relative'
  },
  buttonContainer: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  header: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5)
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  check: {
    color: theme.palette.primary.main
  },
  link: {
    color: theme.palette.primary.main
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 10,
    fontSize: 14,
    color: 'rgb(46, 46, 50)'
  },
  firstSpan: { position: 'relative', padding: 0, marginTop: 8 },
  secondSpan: { position: 'relative', marginTop: 32 },
  progress: { position: 'absolute', right: 5, top: 0 }
}));
