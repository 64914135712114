import { toggleDeviceDoNotDisturb } from '../../../Actions/settings.js';
import { Typography, Switch } from '@material-ui/core';
import useStyles, {
  Container,
  FormControlLabelWithSwitch,
  DevicesList,
  DevicesListItem
} from '../styles.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { Trans } from 'react-i18next';

const DoNotDisturb = ({ devices, getI18n, handleToggle }) => {
  const classes = useStyles();

  return (
    devices.length > 0 && (
      <>
        <Container maxWidth='sm' className={classes.section}>
          <div className={classes.doNotDisturbHeader}>
            <Typography
              className={classes.doNotDisturbTitle}
              variant='subtitle1'
            >
              {getI18n('settings.doNotDisturb.label')}
            </Typography>
            <Typography variant='body2'>
              <Trans i18nKey='settings.doNotDisturb.info' />
            </Typography>
          </div>
          <DevicesList className={classes.doNotDisturbDevices}>
            {devices.map(device => (
              <Device
                {...device}
                handleToggle={handleToggle}
                key={device.id}
                classes={classes}
              />
            ))}
          </DevicesList>
        </Container>
      </>
    )
  );
};

const Device = ({
  id,
  name,
  isDoNotDisturbEnabled,
  isUpdating,
  classes,
  handleToggle
}) => (
  <DevicesListItem>
    <FormControlLabelWithSwitch
      // disabled={isUpdating}
      labelPlacement='start'
      onClick={e => {
        // ensure only Switch toggles
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
      control={
        <Switch
          checked={isDoNotDisturbEnabled}
          value={isDoNotDisturbEnabled}
          onChange={() => handleToggle(id)}
          color='primary'
        />
      }
      label={
        <Typography className={classes.typography} variant='body2'>
          {name}
        </Typography>
      }
    />
  </DevicesListItem>
);

const mapStateToProps = ({ userSettings: { devices } }) => ({
  devices
});

const mapDispatchToProps = (dispatch) => ({
  handleToggle: compose(dispatch, toggleDeviceDoNotDisturb)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DoNotDisturb);
