import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import prepareHomeState from '../../../../Selectors/Home';

import { CountWidget } from '../../../Common/CountWidget';

export const FaxCount = props => {
  const { newFaxCount, totalFaxCount, faxesLoading } = props;

  const { t } = useTranslation();

  return newFaxCount ? (
    <CountWidget
      type="info"
      count={newFaxCount}
      label={t('faxes.newFaxes.label')}
      routePath="/user_portal/faxes"
      icon={'device-fax'}
      isLoading={faxesLoading}
    />
  ) : (
    <CountWidget
      type="default"
      count={totalFaxCount}
      label={t('faxes.totalFaxes.label')}
      routePath="/user_portal/faxes"
      icon={'device-fax'}
      isLoading={faxesLoading}
    />
  );
};

const mapStateToProps = prepareHomeState;

export default connect(mapStateToProps)(FaxCount);
