import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesCallListTableBody = makeStyles(theme => ({
  wrapperCell: {
    '& p': {
      fontFamily: '"Source Sans Pro-Regular", sans-serif'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 20
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  wrapperItem: {
    '& span:nth-child(2)': {
      color: theme.palette.textGrey['500'],
      fontSize: 12
    }
  },
  caption: {
    textAlign: 'center !important'
  },
  fromTitle: {
    fontWeight: 600
  },
  red: {
    color: theme.palette.red['500']
  }
}));
