import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import prepareHomeState from '../../../../Selectors/Home';

import { CountWidget } from '../../../Common/CountWidget';

export const VoicemailCount = props => {
  const { newVoicemailCount, totalVoicemailCount, voicemailsLoading } = props;

  const { t } = useTranslation();

  return newVoicemailCount ? (
    <CountWidget
      type="success"
      count={newVoicemailCount}
      label={t('voicemails.newVoicemails.label')}
      routePath="/user_portal/voicemails?filter=new"
      icon={'voicemail'}
      isLoading={voicemailsLoading}
    />
  ) : (
    <CountWidget
      type="default"
      count={totalVoicemailCount}
      label={t('voicemails.totalVoicemails.label')}
      routePath="/user_portal/voicemails"
      icon={'voicemail'}
      isLoading={voicemailsLoading}
    />
  );
};

const mapStateToProps = prepareHomeState;

export default connect(mapStateToProps)(VoicemailCount);
