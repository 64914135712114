import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
import { connect } from 'react-redux';
import { Image } from '../../Common/Image';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import prepareDevicesAndNumbersState from '../../../Selectors/DevicesAndNumbers';

const PhoneNumber = ({ number }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper className={classes.box}>
        <div className={classes.card}>
          <p className={classes.number}>{number}</p>
          {parsePhoneNumberFromString(number) &&
            parsePhoneNumberFromString(number).country === 'US' && (
            <Image src='/usa.png' alt='number' />
          )}
        </div>
      </Paper>
    </Grid>
  );
};

export const NumberList = ({ phoneNumbers }) => (
  <Grid container>
    {phoneNumbers &&
      phoneNumbers.map((number, index) => (
        <PhoneNumber key={index} number={number} />
      ))}
  </Grid>
);

export default connect(prepareDevicesAndNumbersState)(NumberList);
