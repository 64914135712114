import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar as MuiToolbar } from '@material-ui/core';
import { drawerWidth, drawerMiniWidth } from '../../App';

export default makeStyles(theme => ({
  typography: {
    color: theme.palette.darkGrey['500'],
    fontSize: theme.spacing(2), // 16
    marginLeft: theme.spacing(1)
  },
  appBar: {
    backgroundColor: theme.palette.white['500'],
    [theme.breakpoints.up('sm')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: props =>
        props.miniMenu
          ? `calc(100% - ${drawerMiniWidth}px)`
          : `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  title: {
    border: 0,
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  webPhone: {
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  webPhoneButton: {
    border: 0,
    padding: 0,
    margin: 0,
    background: 'none',
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  menuButton: {
    color: theme.palette.darkGrey['500'],
    marginRight: theme.spacing(2)
    // [theme.breakpoints.up('md')]: {
    //   display: 'none'
    // }
  }
}));

export const Toolbar = withStyles({
  root: {
    '&>:not(:first-child)': {
      margin: 0
    }
  }
})(MuiToolbar);
