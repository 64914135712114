import React, { useRef } from 'react';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from 'moment-duration-format'; // MUST be imported!
import { Grid, Typography } from '@material-ui/core';
import { PlayCircleFilled, PauseCircleFilled } from '@material-ui/icons';
import useStyles from './styles.js';

export const Bar = props => {
  const { playing, setPlaying, duration, currentTime, onTimeUpdate } = props;

  const currentPercentage = (currentTime / duration) * 100 || 0;

  const classes = useStyles({ currentPercentage });

  const progressRef = useRef(null);

  function formatDuration (duration) {
    return moment.duration(duration, 'seconds').format('m:ss', { trim: false });
  }

  function calcClickedTime (e) {
    let clickPositionInPage;
    if (e.touches) {
      clickPositionInPage = e.touches[0].pageX;
    } else {
      clickPositionInPage = e.pageX;
    }
    const bar = progressRef.current;
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag (e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('touchmove', updateTimeOnMove);
    document.addEventListener('mousemove', updateTimeOnMove);
    const clear = () => {
      document.removeEventListener('touchmove', updateTimeOnMove);
      document.removeEventListener('mousemove', updateTimeOnMove);
    };
    document.addEventListener('touchend', () => {
      clear();
    });
    document.addEventListener('touchcancel', () => {
      clear();
    });
    document.addEventListener('mouseup', () => {
      clear();
    });
    document.addEventListener('mouseout', () => {
      clear();
    });
    document.addEventListener('mouseleave', () => {
      clear();
    });
  }

  return (
    <Grid container className='bar'>
      <Grid item xs={1}>
        {playing ? (
          <PauseCircleFilled
            className={classes.playPauseIcon}
            onClick={() => setPlaying(false)}
          />
        ) : (
          <PlayCircleFilled
            className={classes.playPauseIcon}
            onClick={() => setPlaying(true)}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.timing}>
          {formatDuration(currentTime)}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <div
          ref={progressRef}
          className={classes.progressBar}
          onPointerDown={e => handleTimeDrag(e)}
        >
          <div
            className={classes.knob}
            // className="bar__progress__knob"
            // style={{ left: `${currentPercentage - 2}%` }}
          >
            &nbsp;
          </div>
        </div>
      </Grid>
      <Grid item xs={2} className='bar__time'>
        <Typography className={classes.duration}>
          {formatDuration(duration)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Bar;
