// import CONFIG from "../Config";
import * as CONSTS from '../Constants';
import { persistReducer } from 'utils/KazooSDK/utils';
import { set, isString } from 'lodash';
import { AuthState, AuthAction } from './types';

// const remember = !!localStorage.getItem('rememberChk');
const persist = persistReducer('auth');

const initialState: AuthState = {
  auth_token: '', // jwt
  credentials: '', // md5(xyz)
  authenticated: false, //! !localStorage.getItem('authToken'),
  user_data: null,
  device_id: null,
  phone_numbers: {
    isLoading: false,
    isLoaded: false,
    numbers: []
  },
  error: '',
  loading: false,
  username: '', // remember ? localStorage.getItem('userName') : '',
  // password: '', // remember ? localStorage.getItem('userPass') : '',
  // accountname: '', //remember ? localStorage.getItem('accountName') : '',
  account_name: '',
  rememberChk: true, // remember,
  user_id: null,
  account_id: null,
  autoLogin: false,
  ready: false, // after processing querystring variables (TODO: NOT here cuz persisted, so pointless)
  mobile: false,
  allowUsernameLogin: false
};

const defaultState = persist(initialState, 'get', initialState);

export default (
  state: AuthState = defaultState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    // case CONSTS.SET_TOKEN:
    //   localStorage.setItem("authToken", action.payload.auth_token);
    //   localStorage.setItem("credentials", action.payload.credentials);
    //   return persist(
    //     {
    //       ...state,
    //       auth_token: action.payload.auth_token,
    //       credentials: action.payload.credentials
    //     },
    //     "set"
    //   );

    case CONSTS.LOGIN_SUCCESS:
      if (state.rememberChk) {
        // localStorage.setItem('userName', state.username);
        // localStorage.setItem('userPass', state.password);
        // localStorage.setItem('accountName', state.account_name);
      } else {
        // clear remnants of previous logins
        // localStorage.setItem('userName', '');
        // localStorage.setItem('userPass', '');
        // localStorage.setItem('accountName', '');
      }
      localStorage.setItem('authToken', action.payload.auth_token);
      // console.log(
      //   'CONSTS.LOGIN_SUCCESS',
      //   state.username,
      //   action.payload.username
      // );
      return persist(
        {
          ...state,
          loading: false,
          authenticated: true,
          auth_token: action.payload.auth_token,
          account_id: action.payload.data.account_id,
          user_id: action.payload.data.owner_id,
          device_id: action.payload.data.device_id
        },
        'set'
      );

    case CONSTS.SET_USER_NUMBERS:
      return persist(
        {
          ...state,
          phone_numbers: {
            isLoading: action.payload.isLoading,
            isLoaded: action.payload.isLoaded,
            numbers: action.payload.numbers
          }
        },
        'set'
      );

    case CONSTS.SET_USER_DATA:
      return persist(
        {
          ...state,
          user_data: action.payload
        },
        'set'
      );

    case CONSTS.SET_USER_DATA_LAST_SEEN:
      const user_data = state.user_data || {};
      set(user_data, `last_seen.${action.payload.key}`, action.payload.value);
      return persist(
        {
          ...state,
          user_data
        },
        'set'
      );

    case CONSTS.UPDATE_AUTH_QUERYSTRING:
      // Update auth state w/ querystring values
      // - if they exist, otherwise clear
      // - dont need to persist?
      if (
        isString(action.payload.auth_token) &&
        action.payload.auth_token.length
      ) {
        localStorage.setItem('authToken', action.payload.auth_token);
        return persist(
          {
            ...state,
            // api_url: localStorage.getItem('api_url'),
            auth_token: action.payload.auth_token,
            authenticated: state.auth_token === action.payload.auth_token,
            autoLogin: true,
            ready: true,
            mobile: action.payload.mobile,
            allowUsernameLogin: !action.payload.mobile
          },
          'set'
        );
      } else {
        return persist(
          {
            ...state,
            autoLogin: false,
            ready: true,
            auth_token: '',
            mobile: action.payload.mobile,
            allowUsernameLogin: !action.payload.mobile
          },
          'set'
        );
      }

    case CONSTS.UPDATE_AUTH_FORM:
      // if (typeof action.payload.rememberChk !== 'undefined') {
      action.payload = { ...action.payload, password: '' };
      if (!state.rememberChk && !action.payload.rememberChk) {
        // localStorage.setItem('userName', '');
        // localStorage.setItem('userPass', '');
        // localStorage.setItem('accountName', '');
        // localStorage.setItem('rememberChk', '');
        action.payload = {
          ...action.payload,
          username: '',
          // password: '',
          account_name: '',
          rememberChk: false
        };
      } else {
        // localStorage.setItem(
        //   'userName',
        //   action.payload.username ? action.payload.username : state.username
        // );
        // localStorage.setItem(
        //   'userPass',
        //   action.payload.password ? action.payload.password : state.password
        // );
        // localStorage.setItem(
        //   'accountName',
        //   action.payload.account_name
        //     ? action.payload.account_name
        //     : state.account_name
        // );
        // localStorage.setItem('rememberChk', 'true');
      }
      // }
      return persist({ ...state, ...action.payload }, 'set');

    case CONSTS.LOGIN_ERROR:
      localStorage.setItem('authToken', '');
      const persistedState = persist(
        {
          ...state,

          authenticated: false,
          autoLogin: false,
          loading: false
        },
        'set'
      );
      return { ...persistedState, error: action.payload };

    case CONSTS.START_LOGIN:
      return persist({
        ...state,
        error: '',
        loading: true
      });

    case CONSTS.LOGOUT:
      localStorage.setItem('authToken', '');
      localStorage.setItem('credentials', '');
      // localStorage.setItem('account_id', '');
      // localStorage.setItem('user_id', '');
      // localStorage.setItem('user', '');

      return persist(
        {
          ...initialState,
          rememberChk: state.rememberChk,
          ready: true,
          authenticated: false,
          username: state.username,
          account_name: state.account_name
        },
        'set'
      );

    default:
      return state;
  }
};
