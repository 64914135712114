import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';

const persist = persistReducer('calls');

let initialState = {
  calldata: [],
  phone_num: [],
  today_data: [],
  callHistory: [],
  isLoading: false,
  isLoaded: false
};

const defaultState = persist(initialState, 'get', initialState);

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.SET_CALL_DATA_LOADING:
      return persist({ ...state, ...action.payload });

    case CONSTS.SET_CALL_DATA:
      return persist({ ...state, ...action.payload });

    case CONSTS.RESET_CALL_DATA:
      return persist({ ...state, callHistory: [] });

    case CONSTS.LOGOUT:
      return persist(initialState);

    default:
      return state;
  }
};
