import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesPhoneCallStatus = makeStyles(theme => ({
  iconMissed: {
    color: theme.palette.red['500'],
    height: 16,
    width: 16
  },
  iconOutBound: {
    color: theme.palette.inboundGrey['500'],
    height: 16,
    width: 16
  },
  iconInBound: {
    // opacity: 0.001,
    color: theme.palette.activeGrey['500'],
    height: 16,
    width: 16
  }
}));
