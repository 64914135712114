import React, { useReducer, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { CallListTableHead } from './TableHead';
import { CallListTableBody } from './TableBody';
import { CallListTablePagination } from './TablePagination';
import { useStylesDesktopCallList } from './styles';
import { KazooSDK } from '@KazooSDK';
import { filterAllCallList } from '@KazooSDK/utils';

const DesktopCallList = ({ rows, filter, type }) => {
  const rowsPerPageOptions = [10, 25, 50, 100];
  const classes = useStylesDesktopCallList();

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    {
      order: 'asc',
      orderBy: '',
      selected: null,
      page: 0,
      rowsPerPage: 10
    }
  );

  const { order, orderBy, selected, page, rowsPerPage } = state;

  useEffect(() => setState({ page: 0 }), [filter, rows, type]);

  let rowsFilterByType =
    type === 'all' ? rows : rows.filter((ele) => KazooSDK.isMissedCall(ele));

  let rowsFilteredByText = filterAllCallList(rowsFilterByType, filter);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setState({ order: isDesc ? 'asc' : 'desc' });
    setState({ orderBy: property });
  };

  const handleClick = (id) => {
    setState({ selected: id });
  };

  const handleChangePage = (event, newPage) => {
    setState({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ rowsPerPage: parseInt(event.target.value, 10) });
    setState({ page: 0 });
  };

  return (
    <Grid item className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
          >
            <CallListTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsFilteredByText.length}
            />
            <CallListTableBody
              rowsFilteredByText={rowsFilteredByText}
              orderBy={orderBy}
              order={order}
              rowsPerPage={rowsPerPage}
              selected={selected}
              handleClick={handleClick}
              page={page}
            />
          </Table>
        </div>
        {rowsFilteredByText.length > rowsPerPageOptions[0] && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={rowsPerPageOptions.filter((option) =>
              option <= rowsFilteredByText.length
                ? option
                : option - rowsFilteredByText.length <=
                    rowsFilteredByText.length && option
            )}
            component='div'
            count={rowsFilteredByText.length}
            // count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={null}
            ActionsComponent={CallListTablePagination}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default DesktopCallList;
