import React from "react";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { useStylesTable } from "./styles";
import EnhancedTableHead from './HeadTable';
import PaginationActionsTable from './PaginationActionsTable'
import BodyTable from './BodyTable';

export const TableFaxBox = (props) => {
  const classes = useStylesTable();

  const {
    faxesData, faxBox, makeCheckedFax, handleCheckbox, updateFaxesState,
    order, orderBy, page, rowsPerPage,
    handleDeleteOneFax, handleOpenFile
  } = props;

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    updateFaxesState({ orderBy: property, order: isDesc ? "asc" : "desc" });
  };

  const handleChangePage = (event, newPage) => {
    updateFaxesState({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    updateFaxesState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={faxesData.length}
            />
            <BodyTable
              faxesData={faxesData}
              orderBy={orderBy}
              order={order}
              page={page}
              rowsPerPage={rowsPerPage}
              classes={classes}
              makeCheckedFax={makeCheckedFax}
              handleCheckbox={handleCheckbox}
              faxBox={faxBox}
              handleDeleteOneFax={handleDeleteOneFax}
              handleOpenFile={handleOpenFile}
            />
          </Table>
        </div>
        {faxesData.length > rowsPerPage && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={faxesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={null}
            ActionsComponent={PaginationActionsTable}
          />
        )}
      </Paper>
    </div>
  );
};

export default TableFaxBox;
