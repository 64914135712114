import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { useMediaQuery } from '@material-ui/core/';
import { useTheme } from '@material-ui/core/styles';
import useStyles, {
  useStylesTableMobile,
  useStylesTableDesktop
} from './styles';
import { Telicon } from '../../../Icons/index';
import { useTranslation } from 'react-i18next';
import {
  getDuration,
  getPhoneNumber,
  calculateTimeElapsed,
  capitalize
} from '@KazooSDK/utils';
import { AudioPlayer } from '../../AudioPlayer';
import { KazooSDK } from '@KazooSDK';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  fetchVoicemailTranscription,
  moveVoicemailToFolder
} from '../../../../Actions/voicemails';

import { toast } from '@Utils/toast';
import { FadeProps } from '../../../Common/FadeProps';

// const isMobile = window.sessionStorage.getItem('mobile');

const MobileVoicemailListItem = (props) => {
  const {
    device_id,
    table,
    vmBox,
    message,
    isSelected,
    setSelected,
    handleClick,
    handleHidden,
    handleCheckboxElement,
    // handleDeleteMessage,
    handleCall,
    // handleEnded,
    messageTranscription,
    fetchVoicemailTranscription,
    moveVoicemailToFolder,
    makeCheckedItem
  } = props;

  const theme = useTheme();

  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true
  });

  const { t } = useTranslation();

  const classes = useStylesTableMobile(props);
  const classesDesktop = useStylesTableDesktop(props);

  const URL_media = KazooSDK.getMessageUrl(vmBox.id, message.media_id);

  const [isLoadingTranscription, setIsLoadingTranscription] = useState(false);

  // const [actionSinceLastSelected, setActionSinceLastSelected] = useState(false);

  const folder = message.folder;

  const moveToFolder = useCallback(
    (toFolder) => {
      switch (toFolder) {
        case 'new':
        case 'saved':
        case 'deleted':
          break;
        default:
          return false;
      }
      // setActionSinceLastSelected(true);
      moveVoicemailToFolder(vmBox.id, message.media_id, toFolder);
      if (!isMobileBreakpoint) {
        toast({
          type: 'success',
          title: t('success.label'),
          message: t('voicemails.message.alertMovedTo.label', {
            from: t(`voicemails.message.folder${capitalize(folder)}.label`, 'Unknown'),
            to: t(`voicemails.message.folder${capitalize(toFolder)}.label`, 'Unknown')
          })
        });
      }
    },
    [
      vmBox.id,
      message.media_id,
      folder,
      moveVoicemailToFolder,
      isMobileBreakpoint,
      t
    ]
  );

  const moveToFolderNew = useCallback(() => moveToFolder('new'), [
    moveToFolder
  ]);
  const moveToFolderSaved = useCallback(() => moveToFolder('saved'), [
    moveToFolder
  ]);
  const moveToFolderDeleted = useCallback(() => moveToFolder('deleted'), [
    moveToFolder
  ]);

  // // fetch the voicemail for this message
  // // TODO: check that not already exists
  // useEffect(() => {
  //   if (!isSelected) {
  //     setActionSinceLastSelected(false);
  //   }
  // }, [isSelected]);

  // fetch the voicemail for this message
  // TODO: check that not already exists
  useEffect(() => {
    if (
      isSelected &&
      !isLoadingTranscription && // not currently loading transcription (todo: move to redux)
      !messageTranscription
    ) {
      setIsLoadingTranscription(true);
      (async () => {
        await fetchVoicemailTranscription(vmBox.id, message.media_id);
        setIsLoadingTranscription(false);
      })();
      return () => {};
    }
  }, [
    isSelected,
    isLoadingTranscription,
    fetchVoicemailTranscription,
    messageTranscription,
    vmBox.id,
    message.media_id
  ]);

  // useEffect(() => {
  //   // wait a few seconds before fully selecting?
  //   if (!actionSinceLastSelected && isSelected && message.folder !== "saved") {
  //     moveToFolderSaved();
  //   }
  // }, [isSelected, moveToFolderSaved, actionSinceLastSelected, message.folder]);

  const handlePlay = useCallback(() => {
    if (message.folder !== 'saved') {
      moveToFolderSaved();
    }
  }, [message.folder, moveToFolderSaved]);

  const cancelSelected = useCallback(
    (e) => {
      if (isSelected) {
        e.stopPropagation();
        setSelected(null);
      }
    },
    [setSelected, isSelected]
  );

  const handleCallButtonClick = useCallback(
    debounce(async (to, device_id) => handleCall(to, device_id), 5000, {
      leading: true,
      trailing: false
    }),
    []
  );

  if (table) {
    return (
      <>
        <TableRow
          hover
          onClick={() => handleClick(message.media_id)}
          aria-checked={isSelected}
          tabIndex={-1}
          key={message.media_id}
          selected={isSelected}
          className={classNames({
            [classesDesktop.selectedRow]: isSelected,
            [classesDesktop.selectedRowTop]: isSelected
          })}
        >
          <TableCell
            className={classesDesktop.wrapperCell}
            component='th'
            scope='row'
          >
            <div className={classesDesktop.wrapperItemRow}>
              <Checkbox
                name={message.media_id}
                value={message.media_id}
                checked={makeCheckedItem.includes(message.media_id)}
                onClick={handleCheckboxElement}
              />
              <Typography
                className={
                  message.folder === 'saved' ? 'listenedLabel' : 'newLabel'
                }
              >
                {message.folder === 'saved' ? 'Listened' : 'New'}
              </Typography>
            </div>
          </TableCell>
          <TableCell
            className={classesDesktop.wrapperCell}
            component='th'
            scope='row'
          >
            <div>
              <div className={classesDesktop.wrapperItem}>
                {message.caller_id_name && (
                  <span className={classesDesktop.wrapperWeight}>
                    {message.caller_id_name}
                  </span>
                )}
                <span>{getPhoneNumber(message.from.split('@')[0])}</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classesDesktop.wrapperCell}>
            <div>
              <div className={classesDesktop.wrapperItem}>
                <span>{getPhoneNumber(message.to.split('@')[0])}</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classesDesktop.wrapperCell}>
            <div>
              <div className={classesDesktop.wrapperItem}>
                <span>{calculateTimeElapsed(message.timestamp)}</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classesDesktop.pstRelative}>
            {getDuration(message.length / 1000)}
            {isSelected && (
              <Button
                className={classesDesktop.buttonHidden}
                onClick={handleHidden}
                aria-hidden
              >
                <Telicon name='chevron-up' />
              </Button>
            )}
          </TableCell>
        </TableRow>
        {isSelected && (
          <TableRow
            className={[
              classesDesktop.selectedRow,
              classesDesktop.selectedRowBot
            ]}
          >
            <TableCell />
            <TableCell colSpan={2}>
              {message.transcribed ? (
                <div>
                  <p>{t('voicemails.transcription.label')}</p>
                  <MessageTranscription
                    isLoading={isLoadingTranscription}
                    transcription={messageTranscription}
                  />
                </div>
              ) : (
                ''
              )}
            </TableCell>
            <TableCell colSpan={2}>
              <div>
                <div className={classesDesktop.wrapAudio}>
                  <AudioPlayer
                    mediaId={message.media_id}
                    vmBoxId={message.box_id}
                    URLMedia={URL_media}
                    // handleEnded={mediaId => handleEnded(mediaId)}
                    handlePlay={handlePlay}
                    handleEnded={() => {}}
                    autoPlay
                  />
                </div>
                <div className={classesDesktop.wrapperButtonsAction}>
                  <Button
                    className={classesDesktop.buttonAction}
                    onClick={() =>
                      handleCallButtonClick(
                        message.from.split('@')[0],
                        device_id
                      )
                    }
                  >
                    <Telicon name='phone' />
                  </Button>
                  <Button className={classesDesktop.buttonAction}>
                    <a href={URL_media}>
                      <Telicon name='download-cloud' />
                    </a>
                  </Button>
                  <Button
                    className={classesDesktop.buttonAction}
                    onClick={moveToFolderDeleted}
                  >
                    <Telicon name='trash' />
                  </Button>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }

  // Mobile
  return (
    <ListItem
      className={classNames([classes.wrapperItem], {
        [classes.selectedItem]: isSelected
      })}
      onClick={() => handleClick(message.media_id)}
    >
      <div className={classes.item} onClick={cancelSelected}>
        <div className={classes.wrapperLabel}>
          {/* <label
            className={
              message.folder === 'saved' ? 'listenedLabel' : 'newLabel'
            }
          >
            {message.folder === 'saved' ? 'Listened' : 'New'}
          </label> */}
          <FadeProps whenChange={message.folder}>
            <>
              {message.folder === 'new' && (
                <label className={classes.greenLabel}>New</label>
              )}
              {message.folder === 'saved' && (
                <label className={classes.grayLabel}>Listened</label>
              )}
            </>
          </FadeProps>
        </div>
        <div className={classes.wrapperContact}>
          <div>
            {message.caller_id_name &&
            message.caller_id_name !==
              getPhoneNumber(message.from.split('@')[0]) ? (
                <span>
                  <p className={classes.wrapperWeight}>
                    {message.caller_id_name}
                  </p>
                  <span className={classes.wrapperSmall}>
                    {getPhoneNumber(message.from.split('@')[0])}
                  </span>
                </span>
              ) : (
                <span>{getPhoneNumber(message.from.split('@')[0])}</span>
              )}

            <br />
            <p>{calculateTimeElapsed(message.timestamp)}</p>
          </div>
        </div>
        <div className={classes.wrapperRight}>
          <p>{getPhoneNumber(message.to.split('@')[0])} </p>
        </div>
      </div>
      {isSelected && (
        <>
          <div className={classes.wrapAudio}>
            <AudioPlayer
              mediaId={message.media_id}
              vmBoxId={message.box_id}
              URLMedia={URL_media}
              // handleEnded={mediaId => handleEnded(mediaId)}
              handlePlay={handlePlay}
              handleEnded={() => {}}
              autoPlay
            />
          </div>
          {message.transcribed ? (
            <div>
              <MessageTranscription
                isLoading={isLoadingTranscription}
                transcription={messageTranscription}
              />
            </div>
          ) : (
            ''
          )}
          <div className={classes.wrapperButtonsAction}>
            <Button
              className={classes.buttonAction}
              onClick={() =>
                handleCallButtonClick(message.from.split('@')[0], device_id)
              }
            >
              <Telicon name='phone' />
            </Button>
            <Button className={classes.buttonRemove}>
              <a href={URL_media}>
                <Telicon name='download-cloud' />
              </a>
            </Button>
            <Button
              className={classes.buttonAction}
              // onClick={() => handleDeleteMessage(message.media_id)}
              onClick={moveToFolderDeleted}
            >
              <Telicon name='trash' />
            </Button>
            <Button
              className={
                message.folder === 'saved'
                  ? classes.buttonAction
                  : classes.buttonRemove
              }
              onClick={moveToFolderNew}
            >
              <Telicon name='list-prioritize' />
            </Button>
            {/* <Button
              className={classes.buttonHidden}
              onClick={e => {
                e.stopPropagation();
                setSelected(null);
              }}
              aria-hidden
            >
              <HiddenIcon />
            </Button> */}
          </div>
        </>
      )}
    </ListItem>
  );
};

const MessageTranscription = (props) => {
  const { isLoading, transcription } = props;

  const { t } = useTranslation();

  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.transcriptionBox}>
        {t('voicemails.transcription.transcriptionLoading.label')}
      </div>
    );
  }

  if (!transcription || transcription.result !== 'success') {
    return (
      <div className={classes.transcriptionBox}>
        {t('voicemails.transcription.transcriptionMissing.label')}
      </div>
    );
  }

  return (
    <div className={classes.transcriptionBox}>
      {(transcription.text.length && transcription.text) ||
        t('voicemails.transcription.transcriptionMissing.label')}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    device_id: state.auth.device_id,
    messageTranscription:
      state.voicemails.messageTranscription[props.message.media_id]
  };
};

export default compose(
  connect(mapStateToProps, {
    fetchVoicemailTranscription,
    moveVoicemailToFolder
  })
)(MobileVoicemailListItem);
