import CONFIG from '../../../Config';
import * as CONSTS from '../../../Constants';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import md5 from 'md5';
import store from '../../../store';

class ApiService {
  constructor(auth_token = null) {
    if (!this.credentials) {
      try {
        this.credentials = JSON.parse(localStorage.getItem('credentials'));
      } catch (err) {
        console.error(err);
      }
    }
    this.auth_token = auth_token;
    this.isDoingReAuth = undefined; // promise when authenticating
    this.setOnExpired(async () => {
      try {
        /**
         * Mobile should never expire.
         * We can't reauthenticate on mobile, so we just kick the user out.
         */
        if (window.sessionStorage.getItem('mobile') === 'true') {
          console.error('Auth Token has expired.', {
            isMobile: window.sessionStorage.getItem('mobile'),
            auth_token: this.auth_token
          });
          store.dispatch({ type: CONSTS.LOGOUT });
          return;
        }
        if (!this.credentials) {
          this.credentials = JSON.parse(localStorage.getItem('credentials'));
          if (!this.credentials) {
            console.warn('User does not have credentials, logging out');
            store.dispatch({ type: CONSTS.LOGOUT });
            return;
          }
        }
        if (this.credentials && !this.refreshing) {
          console.info('Re authorizing user');
          this.refreshing = true;
          this.isDoingReAuth = new Promise(async (resolveReAuth) => {
            try {
              await this.authenticate(
                null,
                null,
                this.credentials['account_name'],
                this.credentials['credentials']
              );
            } catch (err) {
              // Failed re-auth
              // - go back to login/etc
              console.error('Failed ReAuth', err);
              store.dispatch({ type: CONSTS.LOGOUT });
            }
            this.refreshing = false;
            resolveReAuth(); // no rejection possible, just a "continue" promise
          });
          // setTimeout(() => {
          //   this.refreshing = false;
          // }, 5000);
        } else {
          await this.isDoingReAuth;
          // resolve();
          // await new Promise(resolve => {
          //   setTimeout(() => {
          //     resolve();
          //   }, 1500);
          // });
        }
      } catch (e) {
        console.warn(e);
      }
    });
    this.initService(auth_token);
  }

  async initService(auth_token) {
    return new Promise((resolve) => {
      const headers = { 'Content-Type': 'application/json' };
      if (auth_token) {
        headers['common'] = { 'X-AUTH-TOKEN': auth_token };
      }
      this.base_url = CONFIG.API_URL;
      this.service = axios.create({
        baseURL: CONFIG.API_URL,
        timeout: 6000,
        headers
      });
      this.authService = axios.create({
        baseURL: CONFIG.API_URL,
        timeout: 6000
      });

      this.service.interceptors.request.use(
        async (config) => {
          config.baseURL = this.getDynamicBaseUrl();
          return config;
        },
        (error) => Promise.reject(error)
      );
      this.authService.interceptors.request.use(
        async (config) => {
          config.baseURL = this.getDynamicBaseUrl();
          return config;
        },
        (error) => Promise.reject(error)
      );

      resolve();
    });
  }

  getDynamicBaseUrl() {
    const url = localStorage.getItem('api_url') || CONFIG.API_URL;
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  setExp(exp) {
    localStorage.setItem('exp', exp);
    this.exp = exp;
  }

  getExp() {
    let exp = this.exp;
    if (!exp) {
      exp = localStorage.getItem('exp');
    }
    return exp;
  }

  setOnExpired = (fn) => {
    this.onExpired = fn;
  };

  setUserData(account_id, user_id) {
    this.account_id = account_id;
    this.user_id = user_id;
    localStorage.setItem('account_id', account_id);
    localStorage.setItem('user_id', user_id);
  }

  async getUserService() {
    return this.service.get(
      `/accounts/${this.account_id}/users/${this.user_id}`
    );
  }

  getUserData() {
    let account_id = this.account_id;
    let user_id = this.user_id;
    let user = this.user;
    if (!account_id) {
      account_id = localStorage.getItem('account_id') || '';
      this.account_id = account_id;
    }
    if (!user_id) {
      user_id = localStorage.getItem('user_id') || '';
      this.user_id = user_id;
    }
    if (!user) {
      try {
        user = JSON.parse(localStorage.getItem('user') || '');
      } catch (e) {
        user = {};
      }

      this.user = user;
    }

    return {
      account_id,
      user_id,
      user
    };
  }

  async authenticate(username, password, account_name, credentials = null) {
    // Make sure to use "SERVICE" methods here!
    // - they dont have the loop for checking if authenticated/reauthenticating
    // debugger;
    const body = {
      data: {
        credentials: credentials ? credentials : md5(`${username}:${password}`),
        account_name
      }
    };
    const res = await this.authService.put('/desktop_auth', body);
    // const res = await this.put('/desktop_auth', body);
    this.setUserData(res.data.data.account_id, res.data.data.owner_id);
    await this.setAuthToken(res.data.auth_token);
    const { exp } = jwt.decode(res.data.auth_token);
    this.setExp(exp);
    this.user = (await this.getUserService()).data.data;
    localStorage.setItem(
      'credentials',
      JSON.stringify({
        credentials: credentials || md5(`${username}:${password}`),
        account_name
      })
    );
    this.credentials = {
      credentials: credentials ? credentials : md5(`${username}:${password}`),
      account_name
    };
    localStorage.setItem('user', JSON.stringify(this.user));
    return res.data;
  }

  async authenticateWithToken(auth_token) {
    // Make sure to use "SERVICE" methods here!
    // - they dont have the loop for checking if authenticated/reauthenticating

    const { exp, owner_id, account_id, device_id } = jwt.decode(auth_token);

    // const res = await this.put('/desktop_auth', body);
    this.setUserData(account_id, owner_id);
    await this.setAuthToken(auth_token);
    this.setExp(exp);
    this.user = (await this.getUserService()).data.data;
    localStorage.setItem('user', JSON.stringify(this.user));
    return {
      auth_token,
      data: {
        account_id,
        owner_id,
        device_id
      }
    };
  }

  async checkExpired() {
    await this.isDoingReAuth;

    if (!this.exp) {
      this.exp = this.getExp();
    }
    if (
      this.exp &&
      this.auth_token &&
      // localStorage.getItem("rememberChk") &&
      this.onExpired
    ) {
      const currentTimestamp = Math.ceil(new Date().getTime() / 1000);
      const expirationBuffer = 10; // seconds
      const expirationTimestamp = this.exp - expirationBuffer;
      if (currentTimestamp >= expirationTimestamp) {
        await this.onExpired();
      }
    }
  }

  getBaseUrl() {
    return this.getDynamicBaseUrl();
    // return this.base_url;
  }

  async setAuthToken(token) {
    this.auth_token = token;
    localStorage.setItem('authToken', token);
    await this.initService(token);
  }

  getAuthToken() {
    return this.auth_token;
  }

  getService() {
    return this.service;
  }

  async get(url, config) {
    await this.checkExpired();
    return this.service.get(url, config).catch(async (e) => {
      if (
        (typeof e === 'string' && e.includes('4')) ||
        e.message.includes('4')
      ) {
        await this.onExpired();
        return this.service.get(url, config);
      } else {
        throw e;
      }
    });
  }

  async delete(url, config) {
    await this.checkExpired();
    return this.service.delete(url, config).catch(async (e) => {
      if (
        (typeof e === 'string' && e.includes('4')) ||
        e.message.includes('4')
      ) {
        await this.onExpired();
        return this.service.delete(url, config);
      } else {
        throw e;
      }
    });
  }

  async post(url, data, config) {
    await this.checkExpired();
    return this.service.post(url, data, config).catch(async (e) => {
      if (
        (typeof e === 'string' && e.includes('4')) ||
        e.message.includes('4')
      ) {
        await this.onExpired();
        return this.service.post(url, data, config);
      } else {
        throw e;
      }
    });
  }

  async put(url, data, config) {
    await this.checkExpired();
    return this.service.put(url, data, config).catch(async (e) => {
      if (
        (typeof e === 'string' && e.includes('4')) ||
        e.message.includes('4')
      ) {
        await this.onExpired();
        return this.service.put(url, config);
      } else {
        throw e;
      }
    });
  }

  async patch(url, data, config) {
    await this.checkExpired();
    return this.service.patch(url, data, config).catch(async (e) => {
      if (
        (typeof e === 'string' && e.includes('4')) ||
        e.message.includes('4')
      ) {
        await this.onExpired();
        return this.service.patch(url, data, config);
      } else {
        throw e;
      }
    });
  }
}

export default ApiService;
