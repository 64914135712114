import React from 'react';
import useStyles from './styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';

export const SelectStatus = (props) => {
  const { statusSelected, handleChangeSelectStatus } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" className={classes.root}>
      {(statusSelected === 'all' || !statusSelected) && (
        <Checkbox
          className={classes.checkbox}
          value={'all'}
          checked={statusSelected === 'all'}
          onChange={(e) => handleChangeSelectStatus(e)}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      )}
      <Select
        className={classes.select}
        labelid="filter-status-label"
        id="filter-status"
        value={statusSelected}
        onChange={(e) => handleChangeSelectStatus(e)}
      >
        <MenuItem
          className={[classes.statusItem, classes.grayColor]}
          key="Processing"
          value="processing">{t('faxes.status.processing.label')}</MenuItem>
        <MenuItem
          className={[classes.statusItem, classes.grayColor]}
          key="Pending"
          value="pending">{t('faxes.status.pending.label')}</MenuItem>
        <MenuItem
          className={[classes.statusItem, classes.greyColor]}
          key="Sent"
          value="sent">{t('faxes.status.sent.label')}</MenuItem>
        <MenuItem
          className={[classes.statusItem, classes.redColor]}
          key="Failed"
          value="failed">{t('faxes.status.failed.label')}</MenuItem>
        <MenuItem
          className={[classes.statusItem, classes.greyColor]}
          key="Received"
          value="received">{t('faxes.status.received.label')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectStatus;
