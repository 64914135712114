import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';

export const SearchWidget = (props) => {
  const { handleChangeFilter } = props;

  const [val, setVal] = useState('');

  const classes = useStyles();

  useDebounce(
    () => handleChangeFilter(val),
    250,
    [val]
  );
  
  return (
    <>
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={e => setVal(e.target.value.trim())}
        />
        <SearchIcon />
      </Paper>
    </>
  );
};

export default SearchWidget;
