import React, { useState } from 'react';
import { Menu, MenuItem, Checkbox } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';


export default function ControlledOpenSelect (props) {
  const { handleCheckBoxCustom, checkBoxStatus, handleCheckBoxWithStatus } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (value) => {
    handleCheckBoxWithStatus(value);
    handleClose();
  };

  return (
    <div className={classes.containerCheckbox}>
      <div className={classes.root}>
        <Checkbox
          color='primary'
          value={checkBoxStatus}
          checked={checkBoxStatus}
          inputProps={{
            'aria-label': 'uncontrolled-checkbox'
          }}
          onChange={handleCheckBoxCustom}
        />
      </div>
      <div onClick={handleClick} className={classes.root1}>
        <div className={classes.root2} />
      </div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => { handleClose(); }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
      >
        <MenuItem name='all' onClick={() => handleChangeStatus('all')} >{t('voicemails.filterInput.menuItems.all')}</MenuItem>
        <MenuItem name='new' onClick={() => handleChangeStatus('new')} >{t('voicemails.filterInput.menuItems.new')}</MenuItem>
        <MenuItem name='saved' onClick={() => handleChangeStatus('saved')}>{t('voicemails.filterInput.menuItems.saved')}</MenuItem>
      </Menu>
    </div>
  );
}
