import * as React from 'react';
import { useEffect } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isElectron from 'is-electron';
import Grid from '@material-ui/core/Grid';
import { pdfjs } from 'react-pdf';
import prepareAppState from 'Selectors/App';
import { Login } from 'Components/Login/index';
import { UserPortal } from 'Applications/UserPortal';
import { ToastContainer } from 'utils/toast';
import { LaunchParams } from 'types';
import { App as AppState } from './types';
import useStyles from './styles';
import { updateAuthQueryString } from 'Actions/auth';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let DesktopTrayMenu: React.FC;
if (isElectron()) {
  DesktopTrayMenu = require('Components/Tray').DesktopTrayMenu;
}

/**
 * We are only using this to redirect to one of our static pages.
 */
const AppRedirect = ({ url }: { url: string }): null => {
  window.location.href = url;
  return null;
};

const App: React.FC = () => {
  const { authenticated, ready }: AppState = useSelector(prepareAppState);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const payload: LaunchParams = window.launchParams;
    dispatch(updateAuthQueryString(payload));
  }, [dispatch]);

  if (!ready) {
    return <></>;
  }

  return authenticated ? (
    <React.Fragment>
      {isElectron() && <DesktopTrayMenu />}
      <ToastContainer />
      <Switch>
        <Route path='/user_portal' component={UserPortal} />
        <Route
          path='/webphone'
          render={() => <AppRedirect url='/components/Webphone/index.html' />}
        />
        <Route
          path='/pdf_viewer'
          render={() => <AppRedirect url='/components/PDFViewer/index.html' />}
        />
        <Route render={() => <Redirect to='/user_portal' />} />
      </Switch>
    </React.Fragment>
  ) : (
    <Grid
      container
      spacing={3}
      justify='center'
      alignItems='center'
      className={classes.rootLogin}
    >
      {isElectron() && <DesktopTrayMenu />}
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route render={() => <Redirect to='/login' />} />
      </Switch>
    </Grid>
  );
};

export default withRouter(App);
