import React from 'react';
import List from '@material-ui/core/List';
import { useStylesMobileCallHistory } from './styles';
import { filterAllCallList } from '@KazooSDK/utils';
import { KazooSDK } from '@KazooSDK';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isString } from 'lodash';
import { EmptyResultBox } from '../../../Common/EmptyResultBox';
import { DefaultPageLoader } from '../../../Common/DefaultPageLoader';
import { MobileCallItem } from './MobileCallItem';
import { capitalize } from '@KazooSDK/utils';

const isConferenceCall = (number) =>
  isString(number) && number.startsWith('conference:');

const MobileItemConnected = connect(null, {
  // updateLastSeen
})(MobileCallItem);

const MobileCallList = ({
  rows,
  type,
  filter,
  isLoading,
  isLoaded,
  device_id
}) => {
  const classes = useStylesMobileCallHistory();

  let rowsFilteredInput = filterAllCallList(rows, filter);

  const { t } = useTranslation();

  const filteredRows =
    type === 'all'
      ? rowsFilteredInput
      : rowsFilteredInput.filter((ele) => KazooSDK.isMissedCall(ele));

  if (filteredRows.length) {
    return (
      <List className={classes.root}>
        {filteredRows.map(
          (row) =>
            !isConferenceCall(row.callee_id_number) && (
              <MobileItemConnected
                row={row}
                key={row.id}
                device_id={device_id}
              />
            )
        )}
      </List>
    );
  }

  if (!isLoading && isLoaded && !filteredRows.length) {
    return (
      <EmptyResultBox
        icon='device-voip-phone'
        title={t(`callHistory.empty${capitalize(type)}.title.label`)}
        text={t(`callHistory.empty${capitalize(type)}.text.label`)}
      />
    );
  }

  return (
    <DefaultPageLoader text={t(`callHistory.loading${capitalize(type)}.text.label`)} />
  );
};

export default MobileCallList;
