import { KazooSDK } from '@KazooSDK';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Avatar,
  Drawer,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  CommIo,
  CommIoNoText,
  // Home,
  // Voicemail,
  // List as ListIcon,
  // PhoneVoip,
  // DeviceFax
  Telicon
} from '../Icons';
import useStyles from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';

export const Sidebar = props => {
  const {
    container,
    handleDrawerToggle,
    mobileOpen,
    miniMenu,
    theme,
    fullName,
    history
  } = props;
  const {
    location: { pathname: location }
  } = history;
  const { t } = useTranslation();

  const [isMobile] = useSessionStorage('mobile', false);

  const isPermanent = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  });

  const classes = useStyles({ miniMenu: miniMenu && isPermanent });

  const tabsMetadata = [
    'home',
    'voicemails',
    'callHistory',
    !isMobile ? 'devicesNumbers' : null,
    'faxes',
    !isMobile ? 'conferences' : null
  ]
    .filter(tab => !!tab)
    .map(id => {
      const text = t(`sidebar.${id}.label`);
      const isSelected = location
        .toLowerCase()
        .includes(text.toLowerCase().split(' ')[0]);

      return {
        id,
        text,
        isSelected,
        className: clsx(classes.menuItem, {
          [classes.active]: isSelected
        })
      };
    });
  const tabsItems = tabsMetadata.map(({ id, text, isSelected, className }) => (
    <ListItem
      className={className}
      button
      key={id}
      onClick={route(history, text, handleDrawerToggle, isPermanent)}
      selected={isSelected}
    >
      <ListItemIcon>
        {getIcon(
          text,
          classes,
          location.toLowerCase().includes(text.toLowerCase().split(' ')[0])
        )}
      </ListItemIcon>
      <ListItemText className={classes.menuTitle} primary={text} />
    </ListItem>
  ));

  const drawerChildren = (
    <>
      {isMobile && <div className={classes.drawerLogo}></div>}
      {!isMobile && (
        <>
          {miniMenu && isPermanent ? (
            <CommIoNoText className={classes.logoMini} />
          ) : (
            <CommIo className={classes.logo} />
          )}
          <Divider />
        </>
      )}
      <List className={classes.list}>
        {tabsItems}
        <ListItem
          className={classes.gotoToSettings}
          button
          onClick={route(history, 'Settings', handleDrawerToggle, isPermanent)}
        >
          <Avatar className={classes.avatar}>
            {fullName.match(/\b\w/g).join('')}
          </Avatar>
          <Typography className={classes.gotoToSettingsLabel}>
            {fullName}
          </Typography>
        </ListItem>
      </List>
    </>
  );

  // Note that on mobile the drawer is opened OUTSIDE of the <nav> context
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawerChildren}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          // className={clsx(classes.drawerPaper, {
          //   [classes.drawerOpen]: mobileOpen,
          //   [classes.drawerClose]: !mobileOpen
          // })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: !miniMenu,
              [classes.drawerClose]: miniMenu
            })
          }}
          open
        >
          {drawerChildren}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const getIcon = (text, classes, active) => {
  switch (text) {
    case 'Home':
      return (
        <Telicon
          name="home"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );

    case 'Voicemails':
      return (
        <Telicon
          name="voicemail"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );

    case 'Call History':
      return (
        <Telicon
          name="list"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );

    case 'Devices & Numbers':
      return (
        <Telicon
          name="device-voip-phone"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );

    case 'Faxes':
      return (
        <Telicon
          name="device-fax"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );

      case 'Conferences':
        return (
          <Telicon
            name="user-group"
            className={active ? classes.sideBarIconActive : classes.sideBarIcon}
          />
        );

    default:
      return (
        <Telicon
          name="home"
          className={active ? classes.sideBarIconActive : classes.sideBarIcon}
        />
      );
  }
};

const route = (history, name, handleDrawerToggle, isPermanent) => () => {
  if (!isPermanent && handleDrawerToggle) {
    handleDrawerToggle();
  }
  switch (name) {
    case 'Home':
      history.push('/user_portal/home');
      break;

    case 'Voicemails':
      history.push('/user_portal/voicemails');
      break;

    case 'Call History':
      history.push('/user_portal/call_history');
      break;

    case 'Devices & Numbers':
      history.push('/user_portal/devices_numbers');
      break;

    case 'Faxes':
      history.push('/user_portal/faxes');
      break;

    case 'Settings':
      history.push('/user_portal/settings');
      break;

    case 'Conferences':
      history.push('/user_portal/conferences');
      break;

    default:
      break;
  }
};

const mapStateToProps = state => ({
  fullName: KazooSDK.getFullName()
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
