import React, { Fragment, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  toggleUserRingMyOtherDevices,
  saveUserCallForwardingNumber,
  toggleUserKeepCallerId,
  toggleUserCallForwarding
} from '../../../Actions/settings.js';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import {
  Checkbox,
  FormControl,
  // FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Switch
} from '@material-ui/core';
import useStyles, {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabelWithSwitch,
  FormControlLabelCheckbox
} from '../styles.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SquareIconButton } from '../../Common/SquareIconButton';
import { toast } from '@Utils/toast';
import { useTranslation } from 'react-i18next';

const parsePhoneNumber = (input) => parsePhoneNumberFromString(input, 'US');

const checkIfNumberValid = (input = '') => {
  const number = parsePhoneNumber(input);

  return number !== undefined && number.isValid();
};

const EnableCallForwarding = ({
  isRingMyOtherDevicesEnabled,
  isCallForwardingEnabled,
  callForwardingNumber,
  isKeepCallerIdEnabled,
  handleRingMyOtherDevicesChange,
  handleCallForwardingNumberChange,
  handleKeepCallerIdChange,
  handleCallForwardingChange,
  getI18n
}) => {
  const [showCollapsedControls, setShowCollapsedControls] = useState(
    isCallForwardingEnabled
  );
  const [state, setState] = useState({
    isNumberValid: checkIfNumberValid(callForwardingNumber),
    controlledNumber: callForwardingNumber
  });
  const { controlledNumber } = state;
  const isNumberDifferent =
    controlledNumber !== undefined && controlledNumber !== callForwardingNumber;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleNumberChange = ({ target: { value } }) => {
    setState({
      ...state,
      isNumberValid: checkIfNumberValid(value),
      controlledNumber: value
    });
  };

  const handleResetNumber = () => {
    setState({
      ...state,
      controlledNumber: callForwardingNumber
    });
    document.getElementById(
      'callForwardingNumber'
    ).value = callForwardingNumber;
  };

  const handleSaveNumber = async () => {
    const previousNumber = callForwardingNumber;
    const num = parsePhoneNumber(controlledNumber);
    if (!num) {
      toast({
        type: 'error',
        title: t('settings.toast.invalidForwardingNumber.label')
        // message: ''
      });
      return false;
    }
    await handleCallForwardingNumberChange(num.number);

    if (!previousNumber && !isCallForwardingEnabled) {
      await handleCallForwardingChange();
    }

    setState({
      ...state,
      controlledNumber: num.number
    });
    document.getElementById('callForwardingNumber').value = num.number;
  };

  return isCallForwardingEnabled === undefined ? null : (
    <Container maxWidth='sm' className={classes.section}>
      <Typography className={classes.sectionTitle} variant='subtitle1'>
        {getI18n('settings.callsHandling.label')}
      </Typography>
      <ExpansionPanel
        // disabled={isUpdating}
        expanded={showCollapsedControls}
      >
        <ExpansionPanelSummary>
          <FormControlLabelWithSwitch
            labelPlacement='start'
            onClick={(e) => {
              // ensure only Switch toggles
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            control={
              <Switch
                // disabled={isUpdating}
                checked={showCollapsedControls}
                value='isCallForwardingEnabled'
                onClick={() => {
                  const showControls = !showCollapsedControls;
                  setShowCollapsedControls(showControls);

                  if (callForwardingNumber) {
                    handleCallForwardingChange();
                  } else if (!showControls && isCallForwardingEnabled) {
                    handleCallForwardingChange();
                  }
                }}
                color='primary'
              />
            }
            label={
              <Typography className={classes.typography} variant='body2'>
                {getI18n('settings.enableCallForwarding.label')}
              </Typography>
            }
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.column}>
          <FormControl className={classes.row}>
            <TextField
              id='callForwardingNumber'
              className={classes.grow}
              defaultValue={callForwardingNumber}
              onChange={handleNumberChange}
              // disabled={isUpdating}
              label={getI18n('settings.forwardCallsTo.label')}
              variant='outlined'
            />
            {isNumberDifferent && (
              <Fragment>
                <SquareIconButton
                  color='default'
                  onClick={handleResetNumber}
                  // disabled={isUpdating}
                >
                  <CancelIcon fontSize='small' color='disabled' />
                </SquareIconButton>
                <SquareIconButton
                  color='primary'
                  onClick={handleSaveNumber}
                  // disabled={isUpdating}
                >
                  <CheckCircleIcon fontSize='small' color='action' />
                </SquareIconButton>
              </Fragment>
            )}
          </FormControl>
          <FormGroup>
            <FormControlLabelCheckbox
              onClick={(e) => {
                // ensure only Switch toggles
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              control={
                <Checkbox
                  // disabled={isUpdating}
                  checked={isRingMyOtherDevicesEnabled}
                  value='alsoRingMyOtherDevices'
                  onChange={handleRingMyOtherDevicesChange}
                  color='primary'
                />
              }
              label={
                <Typography
                  variant='body2'
                  onClick={handleRingMyOtherDevicesChange}
                >
                  {getI18n('settings.alsoRingMyOtherDevices.label')}
                </Typography>
              }
            />
            <FormControlLabelCheckbox
              onClick={(e) => {
                // ensure only Switch toggles
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              control={
                <Checkbox
                  // disabled={isUpdating}
                  checked={isKeepCallerIdEnabled}
                  value='isKeepCallerIdEnabled'
                  onChange={handleKeepCallerIdChange}
                  color='primary'
                />
              }
              label={
                <Typography variant='body2' onClick={handleKeepCallerIdChange}>
                  {getI18n('settings.keepCallerId.label')}
                </Typography>
              }
            />
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Container>
  );
};

const mapStateToProps = ({
  userSettings: {
    user: {
      isUpdating,
      isRingMyOtherDevicesEnabled,
      isCallForwardingEnabled,
      callForwardingNumber,
      isKeepCallerIdEnabled
    }
  }
}) => ({
  isUpdating,
  isRingMyOtherDevicesEnabled,
  isCallForwardingEnabled,
  callForwardingNumber,
  isKeepCallerIdEnabled
});

const mapDisptachToProps = (dispatch) => ({
  handleRingMyOtherDevicesChange: compose(
    dispatch,
    toggleUserRingMyOtherDevices
  ),
  handleCallForwardingNumberChange: compose(
    dispatch,
    saveUserCallForwardingNumber
  ),
  handleKeepCallerIdChange: compose(dispatch, toggleUserKeepCallerId),
  handleCallForwardingChange: compose(dispatch, toggleUserCallForwarding)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDisptachToProps)
)(EnableCallForwarding);
