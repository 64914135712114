import React from 'react';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { useStylesTable } from './styles';
import EnhancedTableHead from './HeadTable';
import PaginationActionsTable from './PaginationActionsTable';
import BodyTable from './BodyTable';

export const DesktopVoicemailList = props => {
  const rowsPerPageOptions = [10, 25, 50, 100];
  const classes = useStylesTable();

  const {
    messagesData,
    makeCheckedItem,
    updateVoicemailsState,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleCheckbox,
    handleDeleteMessage,
    vmBox,
    handleEnded,
    handleCall
  } = props;

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    updateVoicemailsState({
      orderBy: property,
      order: isDesc ? 'asc' : 'desc'
    });
  };

  const handleChangePage = (event, newPage) => {
    updateVoicemailsState({ page: newPage });
  };

  const handleChangeRowsPerPage = event => {
    updateVoicemailsState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={messagesData.length}
            />
            <BodyTable
              messagesData={messagesData}
              orderBy={orderBy}
              order={order}
              page={page}
              rowsPerPage={rowsPerPage}
              classes={classes}
              makeCheckedItem={makeCheckedItem}
              handleCheckbox={handleCheckbox}
              vmBox={vmBox}
              handleDeleteMessage={handleDeleteMessage}
              handleCall={handleCall}
              handleEnded={mediaId => handleEnded(mediaId)}
            />
          </Table>
        </div>
        {messagesData.length > rowsPerPageOptions[0] && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={rowsPerPageOptions.filter(option =>
              option <= messagesData.length
                ? option
                : option - messagesData.length <= messagesData.length && option
            )}
            component='div'
            count={messagesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={null}
            ActionsComponent={PaginationActionsTable}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' }
            }}
          />
        )}
      </Paper>
    </div>
  );
};

export default DesktopVoicemailList;
