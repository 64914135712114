import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  wrapperNone: {
    background: theme.palette.activeGreyTransparent['500'],
    borderRadius: 2,
    marginTop: 25,
    padding: 36,
    textAlign: 'center',
    width: '100%',
    '& svg': {
      color: theme.palette.inboundGrey['500'],
      height: 32,
      width: 32
    },
    '& h4': {
      color: theme.palette.grey['500'],
      fontSize: 16,
      margin: '16px 0 4px'
    },
    '& p': {
      color: theme.palette.grey['500'],
      fontSize: 14,
      margin: 0
    }
  }
}));

export default useStyles;
