import * as CONSTS from '../Constants';
import { KazooSDK } from '@KazooSDK';
import { get } from 'lodash';

export const setFaxboxesLoading = (loadingFaxboxes, loadedFaxboxes) => {
  return async (dispatch) => {
    dispatch({
      type: CONSTS.SET_FAXBOXES_LOADING,
      payload: {
        loadingFaxboxes,
        loadedFaxboxes
      }
    });
  };
};
export const setFaxboxFaxesLoading = (
  faxboxId,
  loadingFaxboxes,
  loadedFaxboxes
) => {
  return async (dispatch) => {
    dispatch({
      type: CONSTS.SET_FAXBOX_FAXES_LOADING,
      payload: {
        faxboxId,
        loadingFaxboxes,
        loadedFaxboxes
      }
    });
  };
};

export const fetchInitialFaxData = () => {
  return async (dispatch) => {
    try {
      dispatch(setFaxboxesLoading(true));
      const response = await KazooSDK.getAllFaxBoxes();
      const faxboxes = response.data.data;
      dispatch({ type: CONSTS.SET_FAXBOXES, payload: { faxboxes } });
      dispatch(setFaxboxesLoading(false, true));

      // fetch for individual faxboxes
      for (let faxbox of faxboxes) {
        dispatch(fetchFaxesForFaxbox(faxbox.id, faxbox.caller_id));
      }
    } catch (e) {
      dispatch(setFaxboxesLoading(false, true));
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const fetchFaxesForFaxbox = (
  faxboxId,
  faxboxCallerId,
  from = null,
  to = null
) => {
  return async (dispatch) => {
    try {
      dispatch(setFaxboxFaxesLoading(faxboxId, true));
      const { inboxFaxes, outboxFaxes } = await KazooSDK.getFaxboxFaxes(
        faxboxId,
        faxboxCallerId,
        from,
        to
      );

      dispatch({
        type: CONSTS.SET_FAXBOX_FAXES,
        payload: {
          faxboxId: faxboxId,
          inbox: inboxFaxes,
          outbox: outboxFaxes
        }
      });
      dispatch(setFaxboxFaxesLoading(faxboxId, false, true));
    } catch (e) {
      dispatch(setFaxboxFaxesLoading(faxboxId, false, true));
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const getFaxes = (props) => {
  const { from, to } = props;
  return async (dispatch) => {
    try {
      const allFaxes = await KazooSDK.getAllFaxes(from, to);

      dispatch({ type: CONSTS.GET_FAXES, payload: allFaxes });
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const getAllFaxes = (props) => {
  // const { from, to } = props;
  // debugger; // IGNORE THIS METHOD
  return async (dispatch) => {
    try {
      // const allFaxes = await KazooSDK.getAllFaxes(from, to);
      // let allBoxes = await KazooSDK.getAllFaxBoxes();
      // allBoxes = allBoxes.data.data;
      // const userId = KazooSDK.getUserData().user_id;
      // const usableBoxes = allBoxes.filter((box) => {
      //   let faxes_inbox = allFaxes.faxes_inbox_data.filter(element => box.caller_id === element.to)
      //   let faxes_outbox = allFaxes.faxes_outbox_data.filter(element => box.caller_id === element.from)
      //   box.count_faxes_inbox = faxes_inbox.length;
      //   box.count_faxes_outbox = faxes_outbox.length;
      //   if (!box.caller_id) {
      //     return false;
      //   }
      //   if (!box.owner_id) {
      //     return true;
      //   }
      //   if (box.notifications) {
      //     return true;
      //   }
      //   return (box.owner_id === userId);
      // });
      // dispatch({ type: CONSTS.GET_FAXES, payload: allFaxes });
      // dispatch({ type: CONSTS.GET_FAX_BOXES, payload: { usableBoxes: usableBoxes } });
      // // dispatch({ type: CONSTS.GET_FAX_BOXES, payload: { usableBoxes: allBoxes } });
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
  };
};

export const updateFaxBox = (state) => (dispatch) => {
  dispatch({
    type: CONSTS.UPDATE_FAXES_STATE,
    payload: {
      faxbox: {
        faxbox_id: state.id,
        faxbox_name: state.name,
        caller_name: state.caller_name,
        caller_id: state.caller_id,
        fax_identity: state.fax_identity
      }
    }
  });
};

export const resetFaxes = () => (dispatch) => {
  dispatch({
    type: CONSTS.RESET_FAXES
  });
};

export const updateFaxesState = (state) => {
  return (dispatch) => {
    dispatch({ type: CONSTS.UPDATE_FAXES_STATE, payload: state });
  };
};
