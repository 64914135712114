import React, { useReducer, useEffect, useCallback } from 'react';
import isElectron from 'is-electron';
import { useSessionStorage, useLocalStorage } from 'react-use';
import DesktopVoicemailList from '../Messages/Desktop';
import MobileVoicemailList from '../Messages/Mobile/List';
import VoicemailFilter from '../FilterInput';
import {
  Grid,
  Box,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  Button,
  Dialog,
  LinearProgress
} from '@material-ui/core';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import VoicemailsActionbar from '../ActionBar';
import { connect } from 'react-redux';
import {
  // resetVoicemails,
  updateVoicemailsState,
  changeVoicemailState,
  // moveVoicemailToFolder,
  fetchVmBoxes,
  fetchVoicemails,
  fetchVmMessages
} from '../../../Actions/voicemails';
import { toast } from '@Utils/toast';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
// import { useWebsocketBinding } from '@Util/websocketBinding';
import { getVoicemailMethods } from '@KazooSDK/helpers/VoicemailsListHelpers';
import { Telicon } from '../../Icons';
import SearchWidget from '../../Common/SearchWidget';
import { filterVoicemail } from '@KazooSDK/utils';
import { get, debounce } from 'lodash';
import { DefaultPageLoader } from '../../Common/DefaultPageLoader';
import { EmptyResultBox } from '../../Common/EmptyResultBox';
import CONFIG from './../../../../../Config';

export const VoicemailsBoxView = (props) => {
  const [isMobile] = useSessionStorage('mobile', false);
  const [deviceId] = useLocalStorage('webphone_device_id', false, {
    raw: true
  });
  const classes = useStyles();
  const methods = getVoicemailMethods(props);
  const {
    vmBoxes,
    // vmbox_id,
    history,
    messages,
    vmBoxIndex,
    vmBox,
    // vmboxById,
    loadingMessages,
    order,
    orderBy,
    page,
    rowsPerPage,
    // fetchVoicemails,
    fetchVmMessages,
    makeCheckedItem,
    makeCheckedItemObject,
    updateVoicemailsState
    // changeVoicemailState
  } = props;

  const { t } = useTranslation();

  const initState = {
    loading: false,
    filter: ''
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { loading, filter } = state;

  const handleCheckbox = (event) => {
    methods.handleCheckBox(event.target.name);
  };

  const handleCheckBoxWithStatus = (status) => {
    methods.handleCheckBoxWithStatus(status);
  };

  const handleCheckBoxCustom = () => {
    methods.handleCheckBoxCustom();
  };

  const handleMultiDelete = () => {
    if (window.confirm('Want to delete?')) {
      methods.handleMultiDelete();
    }
  };

  const handleDeleteMessage = (media_id) => {
    methods.handleDeleteMessage(media_id);
  };

  const handleCall = (to, device_id) => {
    toast({
      type: 'success',
      title: device_id
        ? t('voicemails.toast.ringingYourPhone.label')
        : t('voicemails.toast.ringingYourDevices.label')
    });
    methods.handleCall(to, device_id);
  };

  const handleDesktopCall = useCallback(
    debounce(
      async (to, device_id) => {
        if (
          !isMobile &&
          isElectron() &&
          CONFIG.ENABLE_WEBPHONE &&
          CONFIG.ENABLE_WEBPHONE !== 'false' &&
          deviceId &&
          deviceId !== 'undefined'
        ) {
          window.commio.webphone.sendWebphoneAction('dial-number', { to });
        } else {
          handleCall(to, device_id);
        }
      },
      5000,
      { leading: true, trailing: false }
    ),
    []
  );

  const handleActionBar = (value) => {
    setState({ loading: true });
    (async () => {
      await methods.handleActionBar(value);
      setState({ loading: false });
    })();
  };

  const handleChangeFilter = (value) => {
    setState({ filter: value });
    updateVoicemailsState({ page: 0 });
  };

  const handleChange = (event) => {
    let tempIndex = vmBoxes.findIndex(
      (element) => element.id === event.target.value
    );
    updateVoicemailsState({
      vm_box_index: tempIndex,
      page: 0,
      makeCheckedItem: [],
      makeCheckedItemObject: []
    });
    setState({ fliter: '' });
    history.push(`/user_portal/voicemails/vmbox/${event.target.value}`);
  };

  const messagesNotDeleted = filterVoicemail(
    messages,
    '',
    (message) => message.folder !== 'deleted'
  );
  const dataFiltered = filterVoicemail(messagesNotDeleted, filter);

  // scrollToTop on display,
  // - also triggers a fetch
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchVmMessages(vmBox.id);
  }, [history.location, vmBox.id, fetchVmMessages]);

  // // TODO: should be useCallback for fetchVmMessages?
  // useWebsocketBinding(
  //   'object.doc_edited.vmbox.' + vmBox.id, // this also gets NEW voicemails!
  //   data => {
  //     // console.log('FETCHING CUZ CHANGED!', data);
  //     fetchVmMessages(vmBox.id);
  //   }
  // );

  // const newMessageCount = get(vmBox, 'folders.new') || 0;
  // const savedMessageCount = get(vmBox, 'folders.saved') || 0;

  return (
    <div className={classes.root}>
      <Dialog className={classes.dialog} open={loading}>
        <LinearProgress />
      </Dialog>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {vmBoxes.length > 1 ? (
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                className={classes.selectVoicemail}
                labelid='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={vmBoxes[vmBoxIndex].id}
                onChange={handleChange}
                labelWidth={0}
              >
                {vmBoxes.map((vmBox) => {
                  const newMessages = get(vmBox, 'folders.new', 0);
                  const totalMessages =
                    get(vmBox, 'folders.new', 0) +
                    get(vmBox, 'folders.saved', 0);
                  return (
                    <MenuItem
                      className={classes.selectVoicemailItem}
                      key={vmBox.id}
                      value={vmBox.id}
                    >
                      <span className='vmbox-name'>{vmBox.name}</span>
                      {newMessages > 0 ? (
                        <span className='newLabel'>{newMessages} New</span>
                      ) : (
                        <span className='noneNew'>
                          {totalMessages} {t('voicemails.label')}
                        </span>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Box m={1} className={classes.vmParentBox}>
              <Box
                className={classes.vmText}
                style={{ fontSize: '20px', fontWeight: 'bold' }}
              >
                {vmBoxes[0] ? vmBoxes[0].name : ''}
              </Box>
              {vmBoxes[0] && get(vmBoxes[0], 'folders.new', 0) > 0 && (
                <Box p={0.3} ml={1} className={classes.newCountText}>
                  {' '}
                  {`${get(vmBoxes[0], 'folders.new', 0)} New`}{' '}
                </Box>
              )}
            </Box>
          )}
          {messagesNotDeleted.length > 0 && (
            <Box m={1} className={classes.vmParentBox}>
              <Box className={classes.vmTotalCount}>
                {messagesNotDeleted.length}
              </Box>
              <Box m={1} className={classes.vmCountText}>
                {t('voicemails.label')}
              </Box>
            </Box>
          )}
        </Grid>
        {messagesNotDeleted.length > 0 && (
          <>
            <Grid container item xs={12} className={classes.containerMargin}>
              <Hidden only='xs'>
                <Grid item xs container>
                  <VoicemailFilter
                    handleCheckBoxCustom={handleCheckBoxCustom}
                    handleCheckBoxWithStatus={handleCheckBoxWithStatus}
                    checkBoxStatus={makeCheckedItem.length > 0}
                  />
                  {makeCheckedItemObject.length > 0 && (
                    <div>
                      <VoicemailsActionbar
                        messages={messagesNotDeleted}
                        handleActionBar={handleActionBar}
                      />
                    </div>
                  )}
                  {makeCheckedItem.length > 0 && (
                    <Button
                      className={classes.buttonMultiDelete}
                      onClick={handleMultiDelete}
                    >
                      <Telicon name='trash' />
                    </Button>
                  )}
                </Grid>
              </Hidden>

              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                style={{ paddingBottom: '12px' }}
              >
                <SearchWidget handleChangeFilter={handleChangeFilter} />
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12}>
                <DesktopVoicemailList
                  messagesData={dataFiltered}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  makeCheckedItem={makeCheckedItem}
                  updateVoicemailsState={updateVoicemailsState}
                  handleCheckbox={handleCheckbox}
                  vmBox={vmBoxes[vmBoxIndex]}
                  handleDeleteMessage={handleDeleteMessage}
                  handleCall={handleDesktopCall}
                  // handleEnded={changeVoicemailState}
                />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <MobileVoicemailList
                vmBox={vmBoxes[vmBoxIndex]}
                handleDeleteMessage={handleDeleteMessage}
                messagesData={dataFiltered}
                handleCall={handleCall}
                // handleEnded={changeVoicemailState}
              />
            </Hidden>
          </>
        )}
      </Grid>
      {messagesNotDeleted.length === 0 && loadingMessages && (
        <DefaultPageLoader text={t('voicemails.loadingVoicemails.label')} />
      )}
      {messagesNotDeleted.length === 0 && !loadingMessages && (
        <EmptyResultBox
          icon='voicemail'
          title={t('voicemails.vmBoxEmptyTitle.label')}
          text={t('voicemails.vmBoxEmptyText.label')}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const vmBoxes = state.voicemails.vm_boxes || [];
  const vmboxById = state.voicemails.vmboxById || {};

  const tmpVmBoxIndex = vmBoxes.findIndex(
    (vmBox) => vmBox.id === props.vmbox_id
  );
  const vmBox = vmBoxes[tmpVmBoxIndex];
  const vmBoxMeta = vmboxById[props.vmbox_id] || {};
  const messages = (vmBoxMeta && vmBoxMeta.messages) || [];

  let selected = props.match.params.vmbox_id;
  let tempVmBoxIndex = vmBoxes.findIndex((element) => element.id === selected);
  tempVmBoxIndex = tempVmBoxIndex < 0 ? 0 : tempVmBoxIndex;

  return {
    messages, // [], //state.voicemails.messages,
    vmBox,
    vmBoxMeta,
    vmBoxes,
    vmboxById,
    loadingMessages: vmBoxMeta.loading,
    // : vmBoxes.map((box, index) => ({
    //   ...box,
    //   newMessages: state.voicemails.messages[index].filter(
    //     ({ folder }) => folder === "new"
    //   ).length
    // })),
    vmBoxIndex: tempVmBoxIndex,
    order: state.voicemails.order,
    orderBy: state.voicemails.orderBy,
    page: state.voicemails.page,
    rowsPerPage: state.voicemails.rowsPerPage,
    makeCheckedItem: state.voicemails.makeCheckedItem || [],
    makeCheckedItemObject: state.voicemails.makeCheckedItemObject || []
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchVmMessages,
    fetchVoicemails,
    fetchVmBoxes,
    // resetVoicemails,
    updateVoicemailsState,
    changeVoicemailState
  })
)(VoicemailsBoxView);
