import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const AuthError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Typography>{t('authError.label')}</Typography>
    </Grid>
  );
};

export default AuthError;
