import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesCallListTableHead = makeStyles(theme => ({
  headerTable: {
    '& th:first-child, th:nth-child(2)': {
      width: '30%'
    },
    '& th:nth-child(3), th:last-child': {
      width: '20%'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  iconSort: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'scale(0.6, 0.9)',
    '& > svg:first-child': { transform: 'translateY(8px)' },
    '& > svg:last-child': { transform: 'translateY(-8px)' }
  }
}));
