import React, {
  // useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo
} from 'react';
import uuidv4 from 'uuid/v4';
import { isArray } from 'lodash';
import useWebSocket, { ReadyState } from 'react-use-websocket';
// import { connect } from 'react-redux';

// TODO: handle reconnection and resubscription of bindings
// - on disconnect, go through bindings and create new "waitingForMessage" for each
// - also need to handle Handshake...dont actually "enable" the callback until the "success" reply is received

export const WebsocketContext = React.createContext({});

// handshakes hold the "waiting for successful confirmation of listener being applied"
// - is a TODO (not currently waiting for confirmation)
// const Handshakes = {};

const vtBindings = new Map();    // vector table for application callbacks to bindings
const waitingToSend = new Set(); // [JSON.stringify(msg)]

export const createSubscribeMsg = (binding, action) => {
  return JSON.stringify({
    action: action,
    auth_token: window.localStorage.getItem('authToken'),
    requestId: uuidv4(),
    data: {
      binding,
      account_id: window.localStorage.getItem('account_id')
    }
  });
};

export const WebsocketProvider = props => {
  const socketUrl =
    (window.localStorage.getItem('api_url') || '').indexOf('sandbox') > -1
      ? 'wss://sandbox.2600hz.com:5443'
      : 'wss://api.zswitch.net:5443';

  // const dynamicPropRef = useRef(null);
  // dynamicPropRef.current = /*some prop you want to use in the options callbacks*/
  const options = useMemo(
    () => ({
      share: false, // do NOT use, this kills reconnecting!
      // onMessage: message =>
      //   console.log(
      //     `onMessage with access to dynamicProp: ${dynamicPropRef.current}`,
      //     message
      //   ),
      // onClose: event => console.log('onClose', event),
      // onError: error => console.log('onError', error),
      // onOpen: event => console.log('onOpen', event),
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      shouldReconnect: (closeEvent) => {
        console.error("WEBSOCKET DISCONNECT DETECTED.");
        return true;
      }
    })
    ,[]
  );

  const [sendMessage, lastMessage, readyState, getWebSocket] = useWebSocket(
    socketUrl,
    options
  );


  // TODO: handle readyState management
  // - gathering subscriptions to make when connection is ready
  useEffect(() => {
    // console.log('ReadyState Change', readyState, ReadyState);
    switch (readyState) {
      case ReadyState.OPEN:
        // make requests to all pending
        // console.log('sending waitingToSend', waitingToSend);
        // debugger;
        console.log("WEBSOCKET OPEN");
        for (let msg of waitingToSend.values()) {
          sendMessage(msg);
        }

        for(const binding of vtBindings.keys()) {
            console.log(`"replaying subscribtion for ${binding}`);
            const msg = createSubscribeMsg(binding, 'subscribe');
            sendMessage(msg);
          }

        waitingToSend.clear();
        break;
      case ReadyState.CLOSED:
        console.log("WEBSOCKET CLOSED");
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('closed websocket');

        // bindingToUuid.forEach((bindingUuid, binding) => {
        //   const msg = createSubscribeMsg(binding, bindingUuid);
        //   waitingToSend.add(msg);
        // });
        break;
      case ReadyState.CLOSING:
        console.log("WEBSOCKET GOING DOWN");
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('closing websocket');
        break;
      case ReadyState.CONNECTING:
        console.log("WEBSOCKET CONNECTING");
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('connecting websocket');
        break;
      default:
        // trying to reconnect?
        break;
    }
  }, [readyState, sendMessage]);

  const subscribe = useCallback(
    (bindings, application, onDataFunc, shouldBind) => {
      bindings = isArray(bindings) ? bindings : [bindings];

      if (shouldBind) {
        for (let binding of bindings) {
          let data = vtBindings.get(binding);
          let maybeSubscribe = false;
          let exists =
            (data === undefined)
              ? false
              : data.findIndex(vte => vte['app'] === application) >= 0;

          // see if already subscribed
          if(exists) { continue }

          if (data === undefined) {
            // init vector table to an array
            vtBindings.set(binding, [{app: application, callback: onDataFunc}]);
            maybeSubscribe = true;
          } else if (isArray(data)) {
              data.push({app: application, callback: onDataFunc});
              vtBindings.set(binding, data);
              maybeSubscribe = true;
          } else {
            console.error(`"unknown error binding to ${binding} for ${application}"`);
          }

          if (maybeSubscribe) {
            const msg = createSubscribeMsg(binding, 'subscribe');
            if (ReadyState.OPEN === readyState) {
              sendMessage(msg);
            } else {
              waitingToSend.add(msg);
            }
          }
        }
    }
    },
    [readyState, sendMessage]
  );

  const unsubscribe = useCallback((bindings) => {
    // remove local subscription from globally-shared listeners
    // - TODO: clear empty/unsubscribe (instead of passing to no listeners)
    bindings = isArray(bindings) ? bindings : [bindings];
    bindings.forEach(binding => {
      if (vtBindings.delete(binding)) {
        const msg = createSubscribeMsg(binding, 'unsubscribe');
        console.log(msg);
        if (ReadyState.OPEN === readyState) {
          console.log(`"unsubscribing to binding ${binding}"`);
          sendMessage(msg);
        } else {
          waitingToSend.add(msg);
        }
      }
    })
  }, [readyState, sendMessage]);

  useEffect(() => {
    if (lastMessage) {
      // console.log('Parsing lastMessage for websocket:', lastMessage);

      let data;
      try {
        data = JSON.parse(lastMessage.data);
      } catch (err) {
        console.error('Failed parsing data from websocket');
        return false;
      }

      switch (data.action) {
        case 'reply':
          // console.log('Handling a websocket reply');
          // TODO: respond to the initiating request w/ a "this was setup successfully" response
          break;
        case 'event':
          // Update data for listeners
          const binding = data.subscribed_key;
          const callbacks = vtBindings.get(binding);
          if (callbacks) {
            callbacks.forEach(c => {
               if (c["callback"] && typeof c["callback"] === "function") {
                 c["callback"](data);
               }
            });
          }
          break;
        default:
          console.error('Invalid websocket action');
          return false;
      }
    } else {
      // console.error('MISSING lastMessage');
    }
    // debugger;
  }, [lastMessage, subscribe, unsubscribe]);

  const value = {
    subscribe,
    unsubscribe,
    readyState,
    getWebSocket
  };

  return (
    <WebsocketContext.Provider value={value}>
      {props.children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocketBinding = (bindings, application, callback, shouldBind, shouldUnsubscribe) => {
  const { subscribe, unsubscribe, readyState, getWebSocket } = useContext(
    WebsocketContext
  );

  // const context = useContext(WebsocketContext);
  const [lastMessage, setLastMessage] = useState(null);

  useEffect(() => {
    // subscribe
    // console.log('subscribing to:', binding, callback);

    // // TODO: remove for working websockets
    // if (window.sessionStorage.getItem('mobile')) {
    //   return;
    // }
    if (shouldBind) {
      subscribe(bindings,
        application,
        data => {
        // console.log('new event:', binding);
        setLastMessage(data);
        if (callback) {
          callback(data);
        }
       },
       shouldBind
      );
    } else if(shouldUnsubscribe) {
      console.log(`"UNSUBSCRIBE REQUEST for ${bindings}"`);
      unsubscribe(bindings);
    }
  }, [bindings, callback, subscribe, unsubscribe, application, shouldBind, shouldUnsubscribe]);

  return {
    lastMessage,
    readyState,
    getWebSocket
  };
};

export const useWebsocketStatus = () => {
  const [ status, setStatus ] = useState(-1);
  const { readyState } = useContext(
    WebsocketContext
  );

  useEffect(
    () => {
      if (status !== readyState) {
        setStatus(readyState);
      }
    }, [status, readyState]);

  return {status};
};
