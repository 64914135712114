import EnableCallForwarding from './Components/EnableCallForwarding.js';
import SendVoicemailToEmail from './Components/SendVoicemailToEmail.js';
import DoNotDisturb from './Components/DoNotDisturb.js';
import { fetchFullData } from '../../Actions/settings.js';
import { logout } from '../../../../Actions/auth';
import { PowerSettingsNew as PowerSettingsNewIcon } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';
import useStyles from './styles.js';
import i18n from '../../../../Components/i18n';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { DefaultPageLoader } from '../Common/DefaultPageLoader';
import { useSessionStorage } from 'react-use';
import isElectron from 'is-electron';

let SoftwareAccess;

export const Settings = ({
  fullName,
  lng,
  history,
  handleLogout,
  fetchFullData,
  ...props
}) => {
  const getI18n = (path) => i18n.t(path, { lng });
  const classes = useStyles();
  const { t } = useTranslation();

  const [isMobile] = useSessionStorage('mobile', false);

  useEffect(() => {
    fetchFullData();
  }, [fetchFullData]);

  if (props.isLoading && !props.isLoaded) {
    return <DefaultPageLoader text={t('settings.loading.label')} />;
  }

  if (isElectron()) {
    SoftwareAccess = require('./../Settings/Components/SoftwareAccess')
      .SoftwareAccess;
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.userFullName} variant='h5'>
        {fullName}
      </Typography>

      <EnableCallForwarding getI18n={getI18n} />

      <SendVoicemailToEmail getI18n={getI18n} />

      <DoNotDisturb getI18n={getI18n} />

      {!isMobile && (
        <>
          {isElectron() && <SoftwareAccess getI18n={getI18n} />}
          <div>
            <Typography className={classes.sectionTitle} variant='subtitle1'>
              {t('settings.session.label')}
            </Typography>
            <Button
              variant='contained'
              color='default'
              startIcon={<PowerSettingsNewIcon />}
              onClick={() => {
                handleLogout(history);
              }}
            >
              {t('settings.logOut.label')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({
  settings: { language },
  userSettings: {
    user: { fullName, isLoading, isLoaded }
  }
}) => ({
  fullName,
  lng: language.toLowerCase(),
  isLoading,
  isLoaded
});

const mapDispatchToProps = (dispatch) => ({
  fetchFullData: compose(dispatch, fetchFullData),
  handleLogout: compose(dispatch, logout)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Settings);
