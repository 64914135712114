import React from 'react';
import useStyles from './styles';

import { Telicon } from '../../Icons';

export const EmptyResultBox = props => {
  const { icon, title, text, Icon } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrapperNone}>
      {Icon && <Icon />}
      {icon && <Telicon name={icon} />}
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  );
};

export default EmptyResultBox;
