import { makeStyles } from '@material-ui/core/styles';

// TODO: break button styles into their own makeStyles? 
// - const classes = useButtonTheme('primary');

const useStyles = makeStyles(theme => ({
  root: {
    border: props => {
      switch(props.color){
        case 'default':
          return `1px solid ${theme.palette.inboundGrey["500"]}`;
        case 'primary':
          return `1px solid ${theme.palette.borderGreen["500"]}`;
        default:
          return "1px solid black";
      }
    },
    background: props => {
      switch(props.color){
        case 'default':
          return theme.palette.backgroundGreyLinear["500"];
        case 'primary':
          return theme.palette.green["500"];
        default:
          return 'none';
      }
    },
    display: 'inline-block',
    padding: '10px', //theme.spacing(1),
    textAlign: 'center',
    marginLeft: theme.spacing(1),
    borderRadius: '2px',
    '& svg' : {
      height: '100%'
    }
  }
}));

export default useStyles;
