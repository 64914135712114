import React from 'react';
// import ListItem from '@material-ui/core/ListItem';
// import { Link } from "react-router-dom";
import { faxBoxStyles } from './styles';
// import Hidden from '@material-ui/core/Hidden';
import { getPhoneNumber } from '@KazooSDK/utils';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { KazooSDK } from '@KazooSDK';
import { CountWidget } from '../Common/CountWidget';
import { PhoneNumberFlag } from '../Common/PhoneNumberFlag';
import { get } from 'lodash';
// import {
//   Container
// } from "@material-ui/core";

export const FaxBox = props => {
  const { faxbox, inboxFaxes, newFaxCount } = props;

  const { t } = useTranslation();

  const classes = faxBoxStyles();

  return (
    <>
      <div className={classes.wrapTitle}>
        <h6>{faxbox.name}</h6>
        <div className={classes.faxIdentity}>
          <p>
            {getPhoneNumber(faxbox.caller_id)}
            &nbsp;&nbsp;&nbsp;
            <PhoneNumberFlag ptn={faxbox.caller_id} size={12} />
          </p>
          {/* <img src="/usa.png" className="ml-1" alt="flag" /> */}
        </div>
      </div>
      {newFaxCount ? (
        <CountWidget
          type="info"
          count={newFaxCount}
          label={t('faxes.newFaxes.label')}
          routePath={`/user_portal/faxes/faxbox/${faxbox.id}`}
        />
      ) : (
        <CountWidget
          type="default"
          count={inboxFaxes.length}
          label={t('faxes.totalFaxes.label')}
          routePath={`/user_portal/faxes/faxbox/${faxbox.id}`}
        />
      )}
      {/* 
      <div className={classes.wrapTitle}>
        <h6>{faxbox.name}</h6>
        <Hidden smUp>
          <ListItem className={classes.faxIdentity}>
            <p>{getPhoneNumber(faxbox.caller_id)}</p>
            <img src='/usa.png' className='ml-1' alt='flag' />
          </ListItem>
        </Hidden>
      </div>
      <Link
        key={faxbox.id}
        to={`/user_portal/faxes/faxbox/${faxbox.id}`}
      >
        <div className={classes.root} >
          <div>
            <h4>{(faxbox. + data.count_faxes_outbox) || 0}</h4>
            <p>Faxes</p>
          </div>
        </div>
      </Link> */}
    </>
  );
};

const mapStateToProps = (state, props) => {
  let { auth, faxes } = state;

  const { faxboxById } = faxes;

  const { faxbox } = props;
  // console.log('props:', props);

  const user_data = auth.user_data || {};
  const user_last_seen = user_data.last_seen || {};
  const last_seen_faxbox_inbox_val = KazooSDK.lastSeenFor(
    user_last_seen,
    'faxbox_inbox',
    faxbox.id
  );

  const inboxFaxes = get(faxboxById, `${faxbox.id}.inbox`, []);
  const newFaxCount = inboxFaxes.filter(
    fax => fax.timestamp > last_seen_faxbox_inbox_val
  ).length;

  return {
    inboxFaxes,
    newFaxCount
  };
};

export default connect(mapStateToProps, {})(FaxBox);
