import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import classNames from 'classnames';
import { useStylesMobile } from './styles';
import { useTranslation } from 'react-i18next';

const FilterWidgetFaxMobile = (props) => {
  const {
    handleOnChangeType, type,
    faxesInboxLength, faxesOutboxLength
  } = props;
  
  const classes = useStylesMobile();

  const { t } = useTranslation();

  return (
    <div className={classes.containerFilter}>
      <ButtonGroup aria-label="full width outlined button group"
        className={classes.filterType}
      >
        <Button
          className={classNames(classes.button, {
            [classes.active]: type === 'received' || !type
          })}
          onClick={() => handleOnChangeType('received')}
        >
          {`${t('faxes.filter.received.label')} (${faxesInboxLength})`}
        </Button>
        <Button
          className={classNames(classes.button, {
            [classes.active]: type === 'sent'
          })}
          onClick={() => handleOnChangeType('sent')}
        >
          {`${t('faxes.filter.sent.label')} (${faxesOutboxLength})`}
        </Button>
      </ButtonGroup>
    </div>
  )
}
export default FilterWidgetFaxMobile
