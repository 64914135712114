import CONFIG from '../../../Config';
import moment from 'moment';
import { parsePhoneNumber } from 'libphonenumber-js';
import { parseISO } from 'date-fns';
import { isString, toNumber } from 'lodash';

// NOTE: do NOT simply add 1970 to a year to get this offset (breaks leap years!)
const epochOffset = 62167219200;

export function getStartEndTimeStamps(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const start_year = startDate.getFullYear(); // + 1970;
  const start_month = startDate.getMonth();
  const start_date = startDate.getDate();
  const end_year = endDate.getFullYear(); // + 1970;
  const end_month = endDate.getMonth();
  const end_date = endDate.getDate();
  const start_timestamp =
    Math.round(
      new Date(start_year, start_month, start_date, 0, 0, 0, 0).getTime()
    ) / 1000;
  const end_timestamp =
    Math.round(
      new Date(end_year, end_month, end_date, 23, 59, 59, 999).getTime()
    ) / 1000;
  return {
    start_timestamp: start_timestamp + epochOffset,
    end_timestamp: end_timestamp + epochOffset
  };
}

export function getTimeStamps() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth();
  const date = today.getDate();
  const lastday = new Date(today.setDate(today.getDate() - 7));
  const year1 = lastday.getFullYear();
  const month1 = lastday.getMonth();
  const date1 = lastday.getDate();
  const today_from_timestamp =
    Math.round(new Date(year, month, date, 0, 0, 0, 0).getTime()) / 1000;
  const today_to_timestamp =
    Math.round(new Date(year, month, date, 23, 59, 59, 999).getTime()) / 1000;
  const lastday_from_timestamp =
    Math.round(new Date(year1, month1, date1, 0, 0, 0, 0).getTime()) / 1000;
  return {
    today_from_timestamp: today_from_timestamp + epochOffset,
    today_to_timestamp: today_to_timestamp + epochOffset,
    lastday_from_timestamp: lastday_from_timestamp + epochOffset
  };
}

export function getLastWeekTimeStamps() {
  const today = new Date();
  const day = today.getDay();
  const pastweek = new Date();
  const countDaysFromMonday = (day || 7) - 1;
  pastweek.setDate(pastweek.getDate() - 7 - countDaysFromMonday);
  const pastyear = pastweek.getFullYear();
  const pastmonth = pastweek.getMonth();
  const past_fromdate = pastweek.getDate();
  const past_todate = pastweek.getDate() + 6;
  const lastweek_from_timestamp =
    Math.round(
      new Date(pastyear, pastmonth, past_fromdate, 0, 0, 0, 0).getTime()
    ) / 1000;
  const lastweek_to_timestamp =
    Math.round(
      new Date(pastyear, pastmonth, past_todate, 23, 59, 59, 999).getTime()
    ) / 1000;
  return {
    lastweek_from_timestamp: lastweek_from_timestamp + epochOffset,
    lastweek_to_timestamp: lastweek_to_timestamp + epochOffset
  };
}

export function getFaxTimestamps(from, to) {
  const fromDate = new Date(from);
  const from_year = fromDate.getFullYear();
  const from_month = fromDate.getMonth();
  const from_date = fromDate.getDate();
  const from_timestamp =
    Math.round(
      new Date(from_year, from_month, from_date, 0, 0, 0, 0).getTime()
    ) / 1000;

  const toDate = new Date(to);
  const to_year = toDate.getFullYear();
  const to_month = toDate.getMonth();
  const to_date = toDate.getDate();
  const to_timestamp =
    Math.round(
      new Date(to_year, to_month, to_date, 23, 59, 59, 999).getTime()
    ) / 1000;
  return {
    to_timestamp: to_timestamp + epochOffset,
    from_timestamp: from_timestamp + epochOffset
  };
}

export function gregorianToEpoch(timestamp) {
  timestamp = toNumber(timestamp);
  return (timestamp -= epochOffset);
}

export function toMomentUtc(timestamp) {
  // expecting gregorian timestamp 6***
  timestamp -= epochOffset;
  let stamp = new Date(timestamp * 1000);
  let year = stamp.getFullYear();
  let month = stamp.getUTCMonth() + 1;
  let date = '0' + stamp.getUTCDate();
  let hours = '0' + stamp.getUTCHours();
  let minutes = '0' + stamp.getUTCMinutes();
  let seconds = '0' + stamp.getUTCSeconds();
  let formattedDate = year + '-' + month + '-' + date.substr(-2);
  let formattedTime =
    hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  let dateTime1 = formattedDate + ' ' + formattedTime;
  let gmtDateTime = moment.utc(dateTime1, 'YYYY-MM-DD HH:mm:ss');
  return gmtDateTime;
}

export function getDateTime(timestamp) {
  let gmtDateTime = toMomentUtc(timestamp);
  let local = gmtDateTime.local().format('MM/DD/YYYY HH:mm:ss');
  let dateTime = { date: local.split(' ')[0], time: local.split(' ')[1] };
  return dateTime;
}

export function formatDateTime(timestamp, format) {
  let gmtDateTime = toMomentUtc(timestamp);
  let formatted = gmtDateTime.local().format(format);
  return formatted;
}

export function getDuration(totalSeconds) {
  totalSeconds = toNumber(totalSeconds) || 0;
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
  seconds = Math.round(seconds * 100) / 100;

  let result = '';
  if (hours !== 0) {
    result += (hours < 10 ? '0' + hours : hours) + ':';
  }
  result += (minutes < 10 ? '0' + minutes : minutes) + ':';
  result += seconds < 10 ? '0' + seconds : seconds;

  return result;
}

export function getPhoneNumber(number = '') {
  try {
    let phone_number = '';
    let phoneNumber;
    if(isString(number) && number.startsWith('conference:')){
      return 'conference-call';
    }
    if (!number.includes('+')) {
      if (number.length === 11 && `${parseInt(number, 10)}`.length === 11) {
        // make sure is a valid number to avoid parsing strings
        phone_number = parsePhoneNumber('+' + number);
        let phone_num = phone_number.formatInternational();
        let number_arr = phone_num.split(' ');
        phoneNumber =
          number_arr[0] +
          ' ' +
          number_arr[1] +
          '-' +
          number_arr[2] +
          '-' +
          number_arr[3];
        return phoneNumber;
      } else if (
        number.length === 10 &&
        `${parseInt(number, 10)}`.length === 10
      ) {
        phone_number = parsePhoneNumber('+1' + number);
        let phone_num = phone_number.formatInternational();
        let number_arr = phone_num.split(' ');
        phoneNumber =
          number_arr[0] +
          ' ' +
          number_arr[1] +
          '-' +
          number_arr[2] +
          '-' +
          number_arr[3];
        return phoneNumber;
      } else {
        return number;
      }
    } else {
      phone_number = parsePhoneNumber(number);
      let phone_num = phone_number.formatInternational();
      let number_arr = phone_num.split(' ');
      phoneNumber =
        number_arr[0] +
        ' ' +
        number_arr[1] +
        '-' +
        number_arr[2] +
        '-' +
        number_arr[3];
      return phoneNumber;
    }
  }catch(err){
    console.error('Failed parsing phone number', number);
    return "unknown";
  }
}

export const filtermailList = (
  messageRecords,
  perPage,
  currentPage,
  search
) => {
  let subMessageRecords = [];
  if (messageRecords && messageRecords.length > 0) {
    for (
      let index = perPage * currentPage;
      index < perPage * (currentPage + 1);
      index++
    ) {
      if (messageRecords[index]) {
        if (!search) {
          subMessageRecords.push(messageRecords[index]);
        } else {
          let searchKey = search.trim();
          let from = getPhoneNumber(messageRecords[index].from.split('@')[0]);
          let to = getPhoneNumber(messageRecords[index].to.split('@')[0]);
          if (
            from.includes(searchKey) ||
            to.includes(searchKey) ||
            messageRecords[index].caller_id_name.includes(searchKey)
          ) {
            subMessageRecords.push(messageRecords[index]);
          }
        }
      }
    }
  }
  return subMessageRecords;
};

export const filterCallList = (callRecords, perPage, currentPage, filter) => {
  let subCallRecords = [];
  if (callRecords && callRecords.length > 0) {
    for (
      let index = perPage * currentPage;
      index < perPage * (currentPage + 1);
      index++
    ) {
      if (callRecords[index]) {
        if (!filter) {
          subCallRecords.push(callRecords[index]);
        } else {
          if (
            getPhoneNumber(callRecords[index].callee_id_number).indexOf(
              filter
            ) >= 0 ||
            getPhoneNumber(callRecords[index].caller_id_number).indexOf(
              filter
            ) >= 0 ||
            getPhoneNumber(
              callRecords[index].callee_id_name.toLowerCase()
            ).indexOf(filter.toLowerCase()) >= 0 ||
            getPhoneNumber(
              callRecords[index].caller_id_name.toLowerCase()
            ).indexOf(filter.toLowerCase()) >= 0
          ) {
            subCallRecords.push(callRecords[index]);
          }
        }
      }
    }
  }
  return subCallRecords;
};

export const formatDuration = sec => {
  const date = new Date(null);
  date.setSeconds(sec ?? 0);
  let timeString = date.toISOString().substr(11, 8);
  timeString = timeString.split(':')[1] + ':' + timeString.split(':')[2];
  return timeString;
};

const parseDates = (state, result = {}) => {
  const keys = Object.keys(state);
  keys.forEach(key => {
    result[key] =
      state[key] === null
        ? null
        : key.toLowerCase().includes('date') && typeof state[key] === 'string'
        ? parseISO(state[key])
        : typeof state[key] === 'object' && !Array.isArray(state[key])
        ? parseDates(state[key], state[key])
        : Array.isArray(state[key])
        ? state[key].map(s => {
            // TODO: ensure correctly handling arrays, objects, numbers, etc.
            if (typeof s !== 'object') {
              return s;
            }
            return parseDates(s, s);
          })
        : state[key];
  });
  return result;
};

export const persistReducer = key => (
  state,
  type = 'set',
  defaultVal = null
) => {
  // TODO: different caches for content, auth
  const prefix = CONFIG.CACHE_PREFIX || 'v1';
  const newKey = [prefix, key].join('_');
  if (type === 'set') {
    localStorage.setItem(newKey, JSON.stringify(state));
    return state;
  } else {
    const val = localStorage.getItem(newKey);
    if (val) {
      try {
        return parseDates(JSON.parse(val));
      } catch (e) {
        console.error('failed parseDates:', e);
        localStorage.setItem(newKey, JSON.stringify(state));
        return state;
      }
    } else {
      localStorage.setItem(newKey, JSON.stringify(defaultVal));
      return defaultVal;
    }
  }
};

export const filterAllCallList = (callRecords, filter) => {
  if (!filter) return callRecords;
  console.log('filter:', filter);
  return callRecords.filter(
    record =>
      getPhoneNumber(record.callee_id_number).indexOf(filter) >= 0 ||
      getPhoneNumber(record.caller_id_number).indexOf(filter) >= 0 ||
      getPhoneNumber(record?.callee_id_name?.toLowerCase() ?? '').indexOf(
        filter.toLowerCase()
      ) >= 0 ||
      getPhoneNumber(record?.caller_id_name?.toLowerCase() ?? '').indexOf(
        filter.toLowerCase()
      ) >= 0
  );
};

export const calculateTimeElapsed = timestamp => {
  var dt = new Date(timestamp);
  var weekday = [];
  weekday[0] = 'Sunday';
  weekday[1] = 'Monday';
  weekday[2] = 'Tuesday';
  weekday[3] = 'Wednesday';
  weekday[4] = 'Thursday';
  weekday[5] = 'Friday';
  weekday[6] = 'Saturday';
  var timeDifference = new Date() - dt;
  timeDifference = Math.floor(timeDifference / 60000);

  if (timeDifference > 10080) {
    return getDateTime(dt.getTime()).date;
  } else if (timeDifference > 1440) {
    return weekday[dt.getDay()];
  }
  if (timeDifference > 60) {
    return Math.floor(timeDifference / 60) + ' hours';
  }
  return Math.floor(timeDifference) + ' minutes';
};

export const filterVoicemail = (messages, filter, func) => {
  // if (!filter) return messages;
  return messages.filter(
    message =>
      (!func || func(message)) &&
      (!filter ||
        message.caller_id_name.indexOf(filter) >= 0 ||
        getPhoneNumber(message.caller_id_name).indexOf(filter) >= 0 ||
        getPhoneNumber(message.caller_id_name.toLowerCase()).indexOf(
          filter.toLowerCase()
        ) >= 0 ||
        getPhoneNumber(message.from.split('@')[0].toLowerCase()).indexOf(
          filter
        ) >= 0 ||
        getPhoneNumber(message.to.split('@')[0].toLowerCase()).indexOf(
          filter
        ) >= 0)
  );
  // getPhoneNumber(message.from.split('@')[0].toLowerCase()).indexOf(filter.toLowerCase()) >= 0 ||
  // getPhoneNumber(message.to.split('@')[0].toLowerCase()).indexOf(filter.toLowerCase()) >= 0)
};

export const filterFaxes = (faxes, filter, faxBox) => {
  if (!filter) return faxes;
  return faxes.filter(
    fax =>
      getPhoneNumber(fax.to_number).indexOf(filter) >= 0 ||
      (fax.to_name &&
        getPhoneNumber(fax.to_name.toLowerCase()).indexOf(
          filter.toLowerCase()
        ) >= 0) ||
      getPhoneNumber(faxBox.caller_name).indexOf(filter) >= 0 ||
      getPhoneNumber(faxBox.faxbox_name).indexOf(filter) >= 0
  );
};

export const getFaxResult = fax => {
  return fax.tx_result || fax.rx_result;
};

export const snakeToCamel = value => {
  return value.replace(/_([a-zA-Z])/g, function(v1, v2) {
    return v2.toUpperCase()
  })
};

export const capitalize = value => {
  return value.charAt(0).toUpperCase() + value.substring(1);
};

// TODO: reorganize these helpers/utils
// export * from '../helpers/VoicemailsListHelpers';
// export * from '../helpers/FaxesHelpers';
