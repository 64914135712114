import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: 2,
    height: 56,
    flex: '0 0 auto',
    margin: 16,
    padding: 8,
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  card: {
    width: '100%',
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  number: { paddingRight: 8 }
}));

export default useStyles;
