import { createSelector } from 'reselect';
import { getAuthState, getCallState } from '../../../Selectors/common';
import { getConferencesState } from './Conferences';
import { KazooSDK } from '@KazooSDK';
import { toNumber } from 'lodash';

const prepareCallHistoryState = createSelector(
  [getAuthState, getCallState, getConferencesState],
  (auth, calls, conferences) => {
    const { isLoading, isLoaded } = calls;
    const device_id = auth.device_id;
    const user_data = auth.user_data || {};
    const user_last_seen = user_data.last_seen || {};

    const last_seen_missed_calls_val = KazooSDK.lastSeenFor(
      user_last_seen,
      'missed_calls'
    );
    const missedCalls = calls.callHistory
      .filter((call) => toNumber(call.timestamp) > last_seen_missed_calls_val)
      .filter((call) => KazooSDK.isMissedCall(call));

    const missedCallCount = missedCalls.length;
    const { callHistory } = calls;
    const callsWithConferencesExtension = callHistory.map((call) => {
      if (call.callee_id_number.includes('conference:')) {
        const callConference = conferences.find(
          (conference) =>
            conference.id ===
            call.callee_id_number.split('conference:')[1].split('@')[0]
        );
        if (!callConference) {
          return ' ';
        }
        call.conferenceExtension = callConference.conference_numbers[0] || ' ';
      }
      return call;
    });

    return {
      device_id,
      isLoading,
      isLoaded,
      rows: callsWithConferencesExtension,
      missedCallCount
    };
  }
);

export default prepareCallHistoryState;
