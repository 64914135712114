import { createSelector } from 'reselect';
import { getAuthState, getSettingsState } from './common';
import { AuthState, SettingsState } from 'Reducers/types';

const prepareAppState = createSelector(
  [getAuthState, getSettingsState],
  (auth: AuthState, settings: SettingsState) => {
    return {
      ...auth,
      language: settings.language
    };
  }
);

export default prepareAppState;
