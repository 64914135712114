import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p,span': {
      fontSize: 14
    },
    '& :focus': {
      outline: 'unset'
    },
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.white['500'],
    transform: 'translate(-50%, -50%)',
    maxWidth: 690,
    top: '50%',
    left: '50%',
    borderRadius: 12,
    overflow: 'hidden',
    border: `1px solid ${theme.palette.darkGrey['500']}`
  },
  head: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.darkGrey['500'],
    color: theme.palette.white['500'],
    position: 'relative',
    '& h3': {
      fontSize: 23,
      margin: 0,
      fontWeight: 'normal'
    }
  },
  cancelIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  main: {
    padding: theme.spacing(2)
  },
  wrapElement: {
    width: '100%',
    '& .ck-content': {
      minHeight: 100
    }
  },
  uploadFile: {
    display: 'flex',
    alignItems: 'center',
    '& input': {
      display: 'none'
    }
  },
  listFile: {
    height: 47,
    width: 'calc(100% - 100px)',
    border: `1px solid ${theme.palette.opacityGrey['500']}`,
    borderRadius: '5px 0 0 5px',
    padding: 12,
    transition: '0.5s',
    '& p': {
      padding: 0,
      margin: 0
    },
    '& .placeHolder': {
      color: theme.palette.sidebarIcon['500']
    },
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    }
  },
  buttonUpload: {
    width: 100,
    border: `1px solid ${theme.palette.opacityGrey['500']}`,
    borderRadius: '0 5px 5px 0',
    height: 47,
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, ${theme.palette.sidebarText['500']} 100%)`,
    padding: '13px 5px',
    textAlign: 'center',
    transition: '0.5s',
    '&:hover': {
      background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, ${theme.palette.activeGrey['500']})`
    }
  },
  select: {
    '& .MuiOutlinedInput-inputSelect': {
      transform: 'translateY(-3px)'
    }
  },
  footer: {
    padding: theme.spacing(2),
    textAlign: 'right',
    borderTop: `2px solid ${theme.palette.inboundGrey['500']}`,
    backgroundColor: theme.palette.sidebarText['500'],
    '& button': {
      marginLeft: 10
    }
  },
  sendButton: {
    backgroundColor: theme.palette.green['500'],
    transition: '0.5s',
    '&:hover': {
      backgroundColor: theme.palette.darkGreen['500']
    }
  }
}));

export default useStyles;
