import * as CONSTS from '../Constants';
import { KazooSDK } from '@KazooSDK';
import { fetchUserData } from '../../../Actions/auth';
import { fetchInitialData as fetchInitialDataCallHistory } from './callHistory';
import { fetchVmBoxes } from './voicemails';
import { fetchInitialFaxData } from './faxes';
import { fetchInitialData as fetchInitialDataSettings } from './settings';
// import { get } from 'lodash';

export const fetchInitialData = () => {
  return async dispatch => {
    try {
      dispatch(fetchInitialDataCallHistory());
      dispatch(fetchVmBoxes());
      dispatch(fetchUserData());
      dispatch(fetchInitialFaxData());
      dispatch(fetchInitialDataSettings());

      // Home needs:
      // - call history, user.last_seen./call_history_api/cdrs
      // - vmboxes (includes folder counts)
      // - fax history, user.last_seen/faxes/xyz/messages
      const {
        // faxes_inbox_data,
        // faxes_outbox_data,
        // faxbox,
        // newvoicemails,
        full_name,
        // calldata,
        userdata,
        register_device,
        unregister_device
        // phone_num,
        // today_data
      } = await KazooSDK.getNotifications();

      dispatch({
        type: CONSTS.SET_APPLICATION_DATA,
        payload: { full_name, userdata }
      });
      // dispatch({ type: CONSTS.SET_VOICEMAIL_DATA, payload: { newvoicemails } });
      // dispatch({
      //   type: CONSTS.SET_CALL_DATA,
      //   payload: { calldata, phone_num, today_data }
      // });
      dispatch({
        type: CONSTS.SET_DEVICE_DATA,
        payload: { register_device, unregister_device }
      });
      // dispatch({
      //   type: CONSTS.SET_FAXES_DATA,
      //   payload: { faxes_outbox_data, faxes_inbox_data, faxbox }
      // });
    } catch (e) {
      console.error(e);
      // dispatch({ type: CONSTS.LOGOUT }); // dont logout immediately
    }
  };
};
