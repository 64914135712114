import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    background: theme.palette.white['500'],
    borderRadius: 2,
    boxShadow: props => {
      switch (props.type) {
        case 'success':
          return `inset 0px 3px 0px 0px ${theme.palette.iconGreen['500']}, 0px 1px 4px 0px rgba(32, 32, 41, 0.24)`;
        case 'error':
          return `inset 0px 3px 0px 0px ${theme.palette.iconRed['500']}, 0px 1px 4px 0px rgba(32, 32, 41, 0.24)`;
        case 'info':
          return `inset 0px 3px 0px 0px ${theme.palette.iconBlue['500']}, 0px 1px 4px 0px rgba(32, 32, 41, 0.24)`;
        default:
          return '0px 1px 4px 0px rgba(32, 32, 41, 0.24)';
      }
    },
    height: 102,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
    // marginBottom: "16px"
  },
  iconContainer: {
    background: props => {
      switch (props.type) {
        case 'success':
          return theme.palette.iconGreen['500'];
        case 'error':
          return theme.palette.iconRed['500'];
        case 'info':
          return theme.palette.iconBlue['500'];
        default:
          return theme.palette.iconDefault['500'];
      }
    },
    height: 40,
    width: 40,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  icon: {
    color: theme.palette.white['500'],
    borderRadius: 0,
    height: 16,
    width: 16
  },
  leftContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  countText: {
    fontSize: theme.spacing(4), // 32px;
    fontWeight: 600,
    // height: theme.spacing(3),
    // marginBottom: theme.spacing(1),
    color: props => {
      switch (props.type) {
        case 'success':
          return theme.palette.iconGreen['500'];
        case 'error':
          return theme.palette.iconRed['500'];
        case 'info':
          return theme.palette.iconBlue['500'];
        default:
          return theme.palette.black['500'];
      }
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    height: 14,
    letterSpacing: 0
  }
}));

export default useStyles;
