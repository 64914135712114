import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';

const persist = persistReducer('application_user_portal');

let initialState = {
  fullName: '',
  userData: null
};

const defaultState = persist(initialState, 'get', initialState);

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.SET_APPLICATION_DATA:
      return persist({
        ...state,
        fullName: action.payload.full_name,
        userData: action.payload.userdata
      });
    case CONSTS.LOGOUT:
      return persist(initialState);
    default:
      return state;
  }
};
