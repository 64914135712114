import {
  toggleUserSendVmToEmail,
  saveUserEmail
} from '../../../Actions/settings.js';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import { TextField, FormControl, Typography, Switch } from '@material-ui/core';
import useStyles, {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabelWithSwitch
} from '../styles.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SquareIconButton } from '../../Common/SquareIconButton';

const SendVoicemailToEmail = ({
  emailAddress,
  isVoicemailToEmailEnabled,
  isUpdating,
  handleSendVmToEmailToggle,
  handleSaveEmailClick
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    controlledEmail: emailAddress
  });
  const { controlledEmail } = state;
  const isEmailForVoicemailDifferent =
    typeof controlledEmail !== 'undefined' && controlledEmail !== emailAddress;

  const handleEmailForVoicemailChange = ({ target: { value } }) => {
    setState({
      ...state,
      controlledEmail: value
    });
  };

  const { t } = useTranslation();
  const handleResetEmailForVoicemailClick = () => {
    setState({
      ...state,
      controlledEmail: emailAddress
    });
    document.getElementById('emailForVoicemail').value = emailAddress;
  };

  const handleSaveEmailForVoicemailClick = () => {
    handleSaveEmailClick(controlledEmail);
  };

  return isVoicemailToEmailEnabled === undefined ? (
    ''
  ) : (
    <Fragment>
      <Container maxWidth='sm' className={classes.section}>
        <Typography className={classes.sectionTitle} variant='subtitle1'>
          {t('settings.sendVoicemailtoEmail.label')}
        </Typography>
        <ExpansionPanel
          // disabled={isUpdating}
          expanded={isVoicemailToEmailEnabled}
        >
          <ExpansionPanelSummary>
            <FormControlLabelWithSwitch
              labelPlacement='start'
              onClick={e => {
                // ensure only Switch toggles
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              control={
                <Switch
                  checked={isVoicemailToEmailEnabled}
                  value='sendVoicemailtoEmail'
                  onChange={handleSendVmToEmailToggle}
                  color='primary'
                />
              }
              label={
                <Typography className={classes.typography} variant='body2'>
                  {t('settings.sendVoicemailtoEmail.label')}
                </Typography>
              }
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.row}>
            {typeof emailAddress !== 'undefined' && (
              <FormControl className={classes.grow}>
                <TextField
                  id='emailForVoicemail'
                  defaultValue={emailAddress}
                  onChange={handleEmailForVoicemailChange}
                  // disabled={isUpdating}
                  label={t('settings.emailAddress.label')}
                  variant='outlined'
                />
              </FormControl>
            )}
            {isEmailForVoicemailDifferent && (
              <Fragment>
                <SquareIconButton
                  color='default'
                  onClick={handleResetEmailForVoicemailClick}
                  // disabled={isUpdating}
                >
                  <CancelIcon fontSize='small' color='disabled' />
                </SquareIconButton>
                <SquareIconButton
                  color='primary'
                  onClick={handleSaveEmailForVoicemailClick}
                  // disabled={isUpdating}
                >
                  <CheckCircleIcon fontSize='small' color='action' />
                </SquareIconButton>
              </Fragment>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({
  userSettings: {
    user: { emailAddress, isUpdating, isVoicemailToEmailEnabled }
  }
}) => ({
  emailAddress,
  isUpdating,
  isVoicemailToEmailEnabled
});

const mapDispatchToProps = dispatch => ({
  handleSendVmToEmailToggle: compose(dispatch, toggleUserSendVmToEmail),
  handleSaveEmailClick: compose(dispatch, saveUserEmail)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SendVoicemailToEmail);
