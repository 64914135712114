import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';

import VoicemailBoxCountWidget from '../../Common/VoicemailBoxCountWidget';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    paddingBottom: '8px'
  },
  pos: {
    marginBottom: 12
  }
});

const VoicemailsBoxList = props => {
  const classes = useStyles();

  let history = useHistory();

  const { vmBoxes } = props;

  return (
    <Grid container spacing={2}>
      {vmBoxes.map(vmBox => (
        <Grid key={vmBox.id} item xs={12} sm={6} md={4}>
          <Typography
            className={classes.title}
            onClick={() => {
              history.push(`/user_portal/voicemails/vmbox/${vmBox.id}`);
            }}
          >
            {vmBox.name}
          </Typography>
          <VoicemailBoxCountWidget
            // count={1}
            // lng={lng}
            vmBox={vmBox}
            // history={history}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default VoicemailsBoxList;
