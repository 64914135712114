import { ApiService } from './services';
import * as Utils from './utils';
import { toNumber } from 'lodash';

class KazooSDK extends ApiService {
  constructor(auth_token = null) {
    let token = auth_token;
    if (!token) {
      token = localStorage.getItem('authToken');
    }
    super(token);
    this.getUserData();
  }

  initSDK() {
    this.user_id = '';
    this.user = {};
    this.account_id = '';
  }

  /*
   * pass url with value :val:
   * returns string with :val: replaced
   * with timestamp of same name
   * */
  addTimeStamps(url, timeStamps) {
    const keys = Object.keys(timeStamps);
    let finalUrl = url;
    keys.forEach((key) => {
      if (finalUrl.includes(`:${key}:`)) {
        finalUrl = finalUrl.replace(`:${key}:`, timeStamps[key]);
      }
    });
    return finalUrl;
  }

  setUserData(account_id, user_id) {
    this.account_id = account_id;
    this.user_id = user_id;
    localStorage.setItem('account_id', account_id);
    localStorage.setItem('user_id', user_id);
  }

  getUserData() {
    let account_id = this.account_id;
    let user_id = this.user_id;
    let user = this.user;
    if (!account_id) {
      account_id = localStorage.getItem('account_id') || '';
      this.account_id = account_id;
    }
    if (!user_id) {
      user_id = localStorage.getItem('user_id') || '';
      this.user_id = user_id;
    }
    if (!user) {
      try {
        user = JSON.parse(localStorage.getItem('user') || '');
      } catch (e) {
        user = {};
      }

      this.user = user;
    }

    return {
      account_id,
      user_id,
      user
    };
  }

  getFullName() {
    return `${this.user.first_name} ${this.user.last_name}`;
  }

  getMessageUrl(vmbox_id, media_id) {
    return `${this.getBaseUrl()}/accounts/${this.account_id}/users/${
      this.user_id
    }/vmboxes/${vmbox_id}/messages/${media_id}/raw?auth_token=${this.getAuthToken()}`;
  }

  getFaxUrl(fax) {
    return `${this.getBaseUrl()}/accounts/${this.account_id}/faxes/${
      fax.folder
    }/${fax.id}/attachment?auth_token=${this.getAuthToken()}`;
  }

  async getAccountRealm() {
    const {
      data: {
        data: { realm }
      }
    } = await this.get(`/accounts/${this.account_id}`);
    return realm;
  }

  async getUserDefaultWebphoneDevice() {
    const {
      data: { data }
    } = await this.get(
      `accounts/${this.account_id}/users/${this.user_id}/devices?filter_application.id="commio"&paginate=false`
    );
    return data[0];
  }

  async checkUserLogin() {
    const data = await this.get(
      `/accounts/${this.account_id}/users/${this.user_id}`
    );

    return {
      app_state: !!data
      // isMobile
      //   ? true
      //   : userInfo.data.data.desktop
      //     ? userInfo.data.data.desktop.enabled
      //   : false
    };
  }

  //
  async uploadFax(data) {
    const boundary = 'multipart-message-boundary';
    return this.put(
      `/accounts/${this.account_id}/users/${this.user_id}/faxes`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/mixed; boundary=' + boundary
        }
      }
    );
  }

  async deleteFax(deleteFax) {
    return this.delete(
      `/accounts/${this.account_id}/faxes/${deleteFax.folder}/${deleteFax.id}`
    );
  }

  async resendFax(resendFax) {
    return this.put(
      `/accounts/${this.account_id}/faxes/outbox/${resendFax.id}`,
      {
        action: 'resubmit',
        data: {}
      }
    );
  }

  async getAllFaxBoxes() {
    return this.get(
      `/accounts/${this.account_id}/faxboxes?filter_owner_id=${this.user_id}`
    );
  }

  async getFaxboxFaxes(faxboxId, faxboxCallerId, from, to) {
    const baseInboxRequestUrl = `/accounts/${this.account_id}/faxes/inbox?paginate=false&filter_faxbox_id=${faxboxId}`;
    const baseOutboxRequestUrl = `/accounts/${
      this.account_id
    }/faxes/outbox?filter_from_number=${encodeURIComponent(faxboxCallerId)}`;
    let inboxRequestUrl = baseInboxRequestUrl;
    let outboxRequestUrl = baseOutboxRequestUrl;

    if (from && to) {
      inboxRequestUrl = `${inboxRequestUrl}&created_from=:from_timestamp:&created_to=:to_timestamp:&paginate=false`;
      outboxRequestUrl = `${outboxRequestUrl}&created_from=:from_timestamp:&created_to=:to_timestamp:&status=failed`;
    }

    const data = await Promise.all([
      this.get(
        from && to
          ? this.addTimeStamps(
              inboxRequestUrl,
              Utils.getFaxTimestamps(from, to)
            )
          : inboxRequestUrl
      ),
      this.get(
        from && to
          ? this.addTimeStamps(
              outboxRequestUrl,
              Utils.getFaxTimestamps(from, to)
            )
          : outboxRequestUrl
      ),
      this.get(
        `/accounts/${
          this.account_id
        }/faxes/outgoing?filter_from_number=${encodeURIComponent(
          faxboxCallerId
        )}`
      )
    ]);
    const [inbox, outbox, outgoing] = data;
    const inboxFaxes = inbox.data.data;
    const outboxFaxes = outbox.data.data;
    const outgoingFaxes = outgoing.data.data;
    return { inboxFaxes, outboxFaxes, outgoingFaxes };
  }

  async getAllFaxes(from, to) {
    const data = await Promise.all([
      this.get(
        this.addTimeStamps(
          `/accounts/${this.account_id}/faxes/inbox?created_from=:from_timestamp:&created_to=:to_timestamp:&paginate=false&user=${this.user_id}`,
          Utils.getFaxTimestamps(from, to)
        )
      ),
      this.get(
        this.addTimeStamps(
          `/accounts/${this.account_id}/faxes/outbox?created_from=:from_timestamp:&created_to=:to_timestamp:&status=failed&user=${this.user_id}`,
          Utils.getFaxTimestamps(from, to)
        )
      ),
      this.getFaxesBox()
    ]);
    const [faxes_inbox, faxes_outbox, faxesbox] = data;
    const faxbox_id = faxesbox.data.data.length ? faxesbox.data.data[0].id : '';
    const faxbox_name = faxesbox.data.data.length
      ? faxesbox.data.data[0].name
      : '';
    const caller_name = faxesbox.data.data.length
      ? faxesbox.data.data[0].caller_name
      : '';
    const faxbox = { faxbox_id, faxbox_name, caller_name };
    const full_name = this.getFullName();
    const faxes_inbox_data = faxes_inbox.data.data;
    const faxes_outbox_data = faxes_outbox.data.data;
    return { faxbox, faxes_inbox_data, faxes_outbox_data, full_name };
  }

  getFaxAttachmentUrl(fax) {
    return `${this.getBaseUrl()}/accounts/${this.account_id}/faxes/${
      fax.folder
    }/${fax.id}/attachment?auth_token=${this.getAuthToken()}`;
  }

  async getAllDevices() {
    const data = await Promise.all([
      this.get(
        this.addTimeStamps(
          `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:today_from_timestamp:&created_to=:today_to_timestamp:&paginate=false`,
          Utils.getTimeStamps()
        )
      ),
      this.get(
        this.addTimeStamps(
          `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:lastweek_from_timestamp:&created_to=:lastweek_to_timestamp:&paginate=false`,
          Utils.getLastWeekTimeStamps()
        )
      ),
      this.get(`/accounts/${this.account_id}/users/${this.user_id}/devices`),
      this.get(
        `/accounts/${this.account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${this.user_id}&paginate=false`
      ),
      this.get(
        `/accounts/${this.account_id}/users/${this.user_id}/devices/status`
      )
    ]);
    const [todayData, pastweekData, devicesData, callList, device_state] = data;
    let alldevices = [];
    const full_name = this.getFullName();
    const devices = devicesData.data.data;
    const regsiter = device_state.data.data;
    const phone_num = callList.data.data[0]
      ? callList.data.data[0].numbers
      : [];
    const today_data = todayData.data.data;
    const pastweek_data = pastweekData.data.data;
    const total_data = { today_data, pastweek_data };
    devices.forEach((element1) => {
      let flag = false;
      regsiter.forEach((element2) => {
        if (element1.id === element2.device_id) {
          alldevices.push({
            device_type: element1.device_type,
            mac_address: element1.mac_address,
            name: element1.name,
            regsiter: true
          });
          flag = true;
        }
      });
      if (!flag) {
        alldevices.push({
          device_type: element1.device_type,
          mac_address: element1.mac_address,
          name: element1.name,
          regsiter: false
        });
      }
    });
    return { alldevices, phone_num, total_data, full_name };
  }

  async getTodayCallCount() {
    // `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:today_from_timestamp:&created_to=:today_to_timestamp:&paginate=false`
    return this.get(
      this.addTimeStamps(
        `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:today_from_timestamp:&created_to=:today_to_timestamp:&paginate=false`,
        Utils.getTimeStamps()
      )
    );
  }

  //
  async getCallHistory(start, end) {
    return this.get(
      this.addTimeStamps(
        `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:start_timestamp:&created_to=:end_timestamp:&paginate=false`,
        Utils.getStartEndTimeStamps(start, end)
      )
    );
  }

  async getDevices() {
    return this.get(
      `/accounts/${this.account_id}/users/${this.user_id}/devices`
    );
  }

  async getDeviceNumber() {
    return this.get(
      `/accounts/${this.account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${this.user_id}&paginate=false`
    );
  }

  async getDeviceState() {
    return this.get(`/accounts/${this.account_id}/devices/status`);
  }

  async getMissedCalls() {
    return this.get(
      this.addTimeStamps(
        `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?call=outbound&&created_from=:lastday_from_timestamp:&created_to=:today_to_timestamp:&paginate=false`,
        Utils.getTimeStamps()
      )
    );
  }

  async getFaxesInbox() {
    return this.get(
      `/accounts/${this.account_id}/faxes/inbox?paginate=false&user=${this.user_id}`
    );
  }

  async getFaxesOutbox() {
    return this.get(
      `/accounts/${this.account_id}/faxes/outbox?paginate=false&user=${this.user_id}`
    );
  }

  async getFaxesBox() {
    return this.get(
      `/accounts/${this.account_id}/faxboxes?filter_owner_id=${this.user_id}`
    );
  }

  async getConferencesCallInNumbers() {
    const data = await Promise.all([
      this.get(
        `/accounts/${this.account_id}/callflows?filter_type=conference&paginate=false`
      ),
      this.get(
        `/accounts/${this.account_id}/callflows?filter_type=conferencingCallInNumber&paginate=false`
      )
    ]);

    const [response1, response2] = data;
    return [...response1.data.data, ...response2.data.data];
  }

  async getConferences() {
    return this.get(`/accounts/${this.account_id}/conferences`);
  }

  async getConferenceVertoConfig(conference_id) {
    return this.get(
      `/accounts/${this.account_id}/conferences/${conference_id}/desktop/verto`
    );
  }

  async getVmBoxes() {
    return this.get(
      `/accounts/${this.account_id}/vmboxes?filter_owner_id=${this.user_id}`
    );
  }

  async getVmBoxEverybody() {
    return this.get(
      `/accounts/${this.account_id}/vmboxes?value_missing=owner_id`
    );
  }

  async getUser() {
    return this.get(`/accounts/${this.account_id}/users/${this.user_id}`);
  }

  async getVmMessages(id, query = '') {
    return this.get(
      `/accounts/${this.account_id}/vmboxes/${id}/messages${query}`
    );
  }

  async getVmMessageTranscription(vmbox_id, message_id) {
    return this.get(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${message_id}`
    );
  }

  async makeNewItem(vmbox_id, media_id) {
    return this.post(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`,
      {
        data: { folder: 'new' }
      }
    );
  }

  async makeListenedItem(vmbox_id, media_id) {
    return this.post(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`,
      {
        data: { folder: 'saved' }
      }
    );
  }

  async makeItem(vmbox_id, media_id, status) {
    return this.post(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`,
      {
        data: { folder: status }
      }
    );
  }

  async makeDeletedItem(vmbox_id, media_id) {
    return this.post(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`,
      {
        data: { folder: 'deleted' }
      }
    );
  }

  async audioPlayerEnd(vmbox_id, media_id) {
    return this.post(
      `/accounts/${this.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`
    );
  }

  async getAllVmMessages() {
    const vmRes = await this.getVmBoxes();
    // const vmRes2 = await this.getVmBoxEverybody();

    const vm_boxes = [...vmRes.data.data]; //, ...vmRes2.data.data];
    const messages = await Promise.all(
      vm_boxes.map(async (box) => {
        const res = await this.getVmMessages(box.id, '?paginate=false');
        return res.data.data.filter((element) => element.folder !== 'deleted');
      })
    );

    return {
      vm_boxes,
      messages: messages.map((list, index) =>
        list.map((message) => ({
          ...message,
          box_id: vm_boxes[index].id
        }))
      )
    };
  }

  async getNotifications() {
    const sorted = {
      // today_call_count: null,
      devices: null,
      device_num: null,
      device_state: null,
      // missed_call: null,
      // faxes_inbox: null,
      // faxes_outbox: null,
      // faxes_box: null,
      // vm_box: null,
      user_data: null
    };
    const keys = Object.keys(sorted);
    const results = await Promise.all([
      // this.getTodayCallCount(),
      this.getDevices(),
      this.getDeviceNumber(),
      this.getDeviceState(),
      // this.getMissedCalls(),
      // this.getFaxesInbox(),
      // this.getFaxesOutbox(),
      // this.getFaxesBox(),
      // this.getVmBoxes(),
      this.getUser()
    ]);
    results.forEach((result, i) => {
      sorted[keys[i]] = result.data.data;
    });

    // const new_voice_mails = [];
    const unregister_devices = [];
    const register_devices = [];
    const full_name = this.getFullName();
    // const phone_number = sorted.device_num[0].numbers;
    // const faxbox_name = sorted.faxes_box.length ? sorted.faxes_box[0].name : []; // ?
    // const caller_name = sorted.faxes_box.length
    //   ? sorted.faxes_box[0].caller_name
    //   : []; // ?
    // const fax_box = { faxbox_name, caller_name };

    sorted.devices.forEach((element1) => {
      const device = sorted.device_state.find(
        (d) => d.device_id === element1.id
      );
      if (device) {
        register_devices.push({
          id: element1.id,
          device_type: element1.device_type,
          mac_address: element1.mac_address,
          name: element1.name,
          register: true
        });
      } else {
        unregister_devices.push({
          id: element1.id,
          device_type: element1.device_type,
          mac_address: element1.mac_address,
          name: element1.name,
          register: false
        });
      }
    });

    // const all_messages = await Promise.all(
    //   sorted.vm_box.map(async box => {
    //     return this.getVmMessages(box.id);
    //   })
    // );

    // all_messages.forEach(msg => {
    //   const messages = msg.data.data;
    //   const new_msgs = messages.filter(message => message.folder === 'new');
    //   const new_message_count = new_msgs ? new_msgs.length : 0;
    //   new_voice_mails.push({ newmessagecount: new_message_count });
    // });

    // keeping original misspellings in return to keep same interface
    return {
      // faxes_inbox_data: sorted.faxes_inbox,
      // faxes_outbox_data: sorted.faxes_outbox,
      // faxbox: fax_box,
      // newvoicemails: new_voice_mails,
      full_name,
      // calldata: sorted.missed_call,
      userdata: sorted.user_data,
      register_device: register_devices,
      unregister_device: unregister_devices
      // phone_num: phone_number,
      // today_data: sorted.today_call_count
    };
  }

  async getDevicesAndNumbers() {
    // NOT USED YET
    const sorted = {
      devices: null,
      device_num: null,
      device_state: null
    };
    const keys = Object.keys(sorted);
    const results = await Promise.all([
      this.getDevices(),
      this.getDeviceNumber(),
      this.getDeviceState()
    ]);
    results.forEach((result, i) => {
      sorted[keys[i]] = result.data.data;
    });

    const unregister_devices = [];
    const register_devices = [];
    sorted.devices.forEach((element1) => {
      const device = sorted.device_state.find(
        (d) => d.device_id === element1.id
      );
      if (device) {
        register_devices.push({
          id: element1.id,
          device_type: element1.device_type,
          mac_address: element1.mac_address,
          name: element1.name,
          register: true
        });
      } else {
        unregister_devices.push({
          id: element1.id,
          device_type: element1.device_type,
          mac_address: element1.mac_address,
          name: element1.name,
          register: false
        });
      }
    });

    return {
      register_device: register_devices,
      unregister_device: unregister_devices
    };
  }

  async getMainCallflow() {
    return this.get(
      `/accounts/${this.account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${this.user_id}`
    );
  }

  async getDevice(id) {
    return this.get(`/accounts/${this.account_id}/devices/${id}`);
  }

  async patchDevice(id, payload) {
    return this.patch(`/accounts/${this.account_id}/devices/${id}`, {
      data: payload
    });
  }

  async patchUser(payload) {
    return this.patch(`/accounts/${this.account_id}/users/${this.user_id}`, {
      data: payload
    });
  }

  async getUserSettings() {
    const [userPayload] = await Promise.all([
      this.getUser()
      // this.getDevices()
    ]);
    const {
      email,
      username,
      vm_to_email_enabled: isVoicemailToEmailEnabled = false,
      call_forward: {
        substitute = true,
        enabled: isCallForwardingEnabled = false,
        number: callForwardingNumber = '',
        keep_caller_id: isKeepCallerIdEnabled = false
      } = {}
    } = userPayload.data.data;
    const user = {
      isVoicemailToEmailEnabled,
      isCallForwardingEnabled,
      callForwardingNumber,
      isKeepCallerIdEnabled,
      isRingMyOtherDevicesEnabled: !substitute,
      fullName: this.getFullName(),
      emailAddress: email || username
    };
    // const devicesPayload = await Promise.all(
    //   devicesListPayload.data.data.map(({ id }) => this.getDevice(id))
    // );
    // const devices = devicesPayload.map(
    //   ({
    //     data: {
    //       data: { id, name, do_not_disturb: { enabled = false } = {} }
    //     }
    //   }) => ({ id, name, isDoNotDisturbEnabled: enabled })
    // );

    return {
      user
      // devices
    };
  }

  async getUserSettingsAndDevices() {
    const [userPayload, devicesListPayload] = await Promise.all([
      this.getUser(),
      this.getDevices()
    ]);
    const {
      email,
      username,
      vm_to_email_enabled: isVoicemailToEmailEnabled = false,
      call_forward: {
        substitute = true,
        enabled: isCallForwardingEnabled = false,
        number: callForwardingNumber = '',
        keep_caller_id: isKeepCallerIdEnabled = false
      } = {}
    } = userPayload.data.data;
    const user = {
      isVoicemailToEmailEnabled,
      isCallForwardingEnabled,
      callForwardingNumber,
      isKeepCallerIdEnabled,
      isRingMyOtherDevicesEnabled: !substitute,
      fullName: this.getFullName(),
      emailAddress: email || username
    };
    const devicesPayload = await Promise.all(
      devicesListPayload.data.data.map(({ id }) => this.getDevice(id))
    );
    const devices = devicesPayload.map(
      ({
        data: {
          data: { id, name, do_not_disturb: { enabled = false } = {} }
        }
      }) => ({ id, name, isDoNotDisturbEnabled: enabled })
    );

    return {
      user,
      devices
    };
  }

  async getInitDataCallHistory() {
    return this.get(
      this.addTimeStamps(
        `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?created_from=:lastday_from_timestamp:&created_to=:today_to_timestamp:`,
        Utils.getTimeStamps()
      )
    ).then((resp) => {
      return { callHistory: resp.data.data };
    });
  }

  async filterDataCallHistory(props) {
    const { from, to } = props;
    return this.get(
      this.addTimeStamps(
        `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction?created_from=:from_timestamp:&created_to=:to_timestamp:`,
        Utils.getFaxTimestamps(from, to)
      )
    ).then((resp) => {
      return { callHistory: resp.data.data };
    });
  }

  async makeCall(props) {
    const { to, device_id } = props;
    if (device_id) {
      return this.post(
        `/accounts/${this.account_id}/devices/${device_id}/quickcall/${to}`
      );
    } else {
      return this.post(
        `/accounts/${this.account_id}/users/${this.user_id}/quickcall/${to}`
      );
    }
  }

  async getDataFaxFile(URL) {
    return this.get(URL, { responseType: 'arraybuffer' });
  }

  isIncomingCall(interaction) {
    /*
      outgoing: owner_id is me
      incoming: owner_id does not exist, or is NOT mine
    */
    /*
      owner_id, authorizing_id both belong to initiator

      call FROM on kazoo:
      - owner_id is me, direction inbound/outbound
      - authorizing id is
      both on account:
      - outgoing
      call TO on kazoo
      -
    */

    /* return (
      (interaction.owner_id === this.user_id &&
        interaction.inception_direction === 'termination') ||
      // (!interaction.owner_id || !interaction.owner_id.length)
      // ||
      interaction.owner_id !== this.user_id
    ); */

    /**
     * Don't ask me why this is like this.
     * Also, I left this really verbose just in case.
     */
    if (interaction.direction === 'outbound') {
      return true;
    } else {
      return false;
    }
  }

  isMissedCall(interaction) {
    return (
      this.isIncomingCall(interaction) &&
      interaction.hangup_cause !== 'NORMAL_CLEARING'
    );
  }

  getUserLastSeenMissedCallsUrl() {
    // note: we're using the interaction list, NOT the individual legs list
    return `/accounts/${this.account_id}/users/${this.user_id}/cdrs/interaction`; // should have a filter for missed_calls?
  }

  getUserLastSeenFaxboxUrl(faxbox_id) {
    // note: we're using the interaction list, NOT the individual legs list
    return `/accounts/${this.account_id}/faxes/inbox?filter_faxbox_id=${faxbox_id}`;
  }

  lastSeenFor(last_seen, type, id_1) {
    let result;
    let url;
    switch (type) {
      case 'missed_calls': // per-user interactions
        url = this.getUserLastSeenMissedCallsUrl();
        result = last_seen[url];
        break;
      case 'faxbox_inbox': // stored per-faxbox
        url = this.getUserLastSeenFaxboxUrl(id_1);
        result = last_seen[url];
        break;
      default:
        result = 0;
        break;
    }
    return toNumber(result) || 0;
  }
}

export default new KazooSDK();
