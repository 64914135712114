// import { KazooSDK } from './KazooSDK';
// import { getPhoneNumber } from './index';
// import fuzzy from 'fuzzy';

// export const makeListenedAudioItem = async (data) => {
//   const messages = data.messages.map(msg => {
//     if (msg.media_id === data.media_id) {
//       return {
//         ...msg,
//         folder: 'saved',
//       };
//     }
//     return msg;
//   });
//   const allmessages = { ...data.allmessages };
//   allmessages.allmessages = allmessages.allmessages.map((msgset) => {
//     if (msgset.vmbox.id === data.vmbox_id) {
//       return {
//         ...msgset,
//         messages
//       };
//     }
//     return msgset;
//   });
//   data.updateVMState({ messages, allmessages });
// };

// export const mailStateChange = (messages, checkKey) => {
//   let checkVoiceMails = [];
//   if (messages) {
//     messages.forEach((message) => {
//       if (message.media_id === checkKey) {
//         checkVoiceMails.push({ ...message, media_id: message.media_id, state: !message.state });
//       } else {
//         checkVoiceMails.push({ ...message, media_id: message.media_id, state: message.state });
//       }
//     });
//   }
//   return checkVoiceMails;
// };

// // return the messages that should be on the current page
// export const getDisplayableMessages = (allMessages, state) => {
//   try {
//     const {
//       checked,
//       searchKey,
//       perPage,
//       currentPage
//     } = state;

//     const search = searchKey ? searchKey.trim() : '';
//     const displayable = {};
//     const start = perPage * currentPage;
//     const end = start + perPage;
//     allMessages.forEach(mailbox => {
//       displayable[mailbox.vmbox.id] = mailbox.messages.map((msg, i) => {
//         // if not part of pagination values, this is frontend pagination
//         if (i < start || i > end) {
//           return null;
//         }
//         if (search) {
//           const searchable = [getPhoneNumber(msg.from.split('@')[0]), getPhoneNumber(msg.to.split('@')[0]), msg.caller_id_name];
//           const matches = fuzzy.filter(search, searchable);
//           if (matches.length) {
//             return { ...msg, checked: !!checked[`${msg.media_id}_${msg.folder}`] };
//           }
//           return null;
//         }
//         return {
//           ...msg,
//           checked: !!checked[`${msg.media_id}_${msg.folder}`]
//         };
//       }).filter((msg) => msg !== null);
//     });
//     return displayable;
//   } catch (e) {
//     return {};
//   }
// };

// export const hasFilterableProps = (payload) => {
//   return !!(payload.checkKey || payload.noneItem || payload.allItem || payload.newItem || payload.listenedItem || payload.deletedItem || payload.searchKey || payload.perPage || payload.currentPage);
// };

import { KazooSDK } from '../index.js';
import { remove } from 'lodash';
// import { fetchVmBoxes } from 'src/Applications/UserPortal/Actions/voicemails.js';

export const getVoicemailMethods = props => {
  const {
    vmBox,
    vmboxById,
    vmBoxes,
    vmBoxIndex,
    makeCheckedItem,
    makeCheckedItemObject,
    // messages,
    changeVoicemailState, // for an individual voicemail message
    updateVoicemailsState,
    fetchVmBoxes
    // fetchVoicemails
  } = props;

  return {
    handleCheckBox: messageId => {
      let temp = [...makeCheckedItem];
      let tempObjects = [...makeCheckedItemObject];
      let messagesData = vmboxById[vmBox.id].messages || [];
      if (makeCheckedItem.includes(messageId)) {
        remove(temp, element => element === messageId);
        remove(tempObjects, element => element.media_id === messageId);
      } else {
        let tempElement = messagesData.find(ele => ele.media_id === messageId);
        if (tempElement) {
          tempObjects.push({
            media_id: tempElement.media_id,
            vmbox_id: tempElement.vmbox_id,
            folder: tempElement.folder
          });
          temp.push(messageId);
        }
      }
      updateVoicemailsState({
        makeCheckedItem: temp,
        makeCheckedItemObject: tempObjects
      });
    },
    handleCheckBoxCustom: () => {
      if (makeCheckedItem.length > 0) {
        updateVoicemailsState({
          makeCheckedItem: [],
          makeCheckedItemObject: []
        });
      } else {
        let temp = [];
        let tempObjects = [];
        let messagesData = vmboxById[vmBox.id].messages || []; //messages[vmBoxIndex] || [];
        messagesData.forEach(element => {
          tempObjects.push({
            media_id: element.media_id,
            folder: element.folder
          });
          temp.push(element.media_id);
        });
        updateVoicemailsState({
          makeCheckedItem: temp,
          makeCheckedItemObject: tempObjects
        });
      }
    },
    handleCheckBoxWithStatus: value => {
      let temp = [];
      let tempObjects = [];
      let messagesData = vmboxById[vmBox.id].messages || []; //messages[vmBoxIndex];
      switch (value) {
        case 'all':
          messagesData.forEach(element => {
            tempObjects.push({
              media_id: element.media_id,
              folder: element.folder
            });
            temp.push(element.media_id);
          });
          break;
        case 'new':
          messagesData.forEach(element => {
            if (element.folder === 'new') {
              tempObjects.push({
                media_id: element.media_id,
                folder: element.folder
              });
              temp.push(element.media_id);
            }
          });
          break;
        case 'saved':
          messagesData.forEach(element => {
            if (element.folder === 'saved') {
              tempObjects.push({
                media_id: element.media_id,
                folder: element.folder
              });
              temp.push(element.media_id);
            }
          });
          break;
        default:
          break;
      }
      updateVoicemailsState({
        makeCheckedItem: temp,
        makeCheckedItemObject: tempObjects
      });
    },
    handleMultiDelete: async () => {
      let vmbox_id = vmBox.id; // (vmBoxes[vmBoxIndex] && vmBoxes[vmBoxIndex].id) || vmBoxes[0].id;
      try {
        if (makeCheckedItem.length > 0) {
          await Promise.all(
            makeCheckedItemObject.map(element => {
              changeVoicemailState({
                vmboxId: vmbox_id,
                messageId: element.media_id,
                data: {
                  folder: 'deleted'
                }
              });
              return KazooSDK.makeDeletedItem(vmbox_id, element.media_id).catch(
                e => {
                  console.warn(e);
                }
              );
            })
          )
            .then(() => {
              updateVoicemailsState({
                page: 0,
                makeCheckedItem: [],
                makeCheckedItemObject: []
              });
              fetchVmBoxes();
              // fetchVoicemails();
            })
            .catch(e => {
              console.warn(e);
            });
        }
      } catch (e) {
        console.warn(e, 'err deleting voicemail');
      }
    },
    handleDeleteMessage: media_id => {
      let vmbox_id =
        (vmBoxes[vmBoxIndex] && vmBoxes[vmBoxIndex].id) || vmBoxes[0].id;
      KazooSDK.makeDeletedItem(vmbox_id, media_id)
        .then(() => {
          updateVoicemailsState({ folder: 'deleted' });
          // fetchVoicemails();
        })
        .catch(e => {
          console.warn(e);
        });
    },
    handleCall: (to, device_id) => {
      KazooSDK.makeCall({ to, device_id })
        .then(res => {
          console.log(res);
        })
        .catch(e => {
          console.warn(e);
        });
    },
    handleActionBar: async value => {
      let vmbox_id =
        (vmBoxes[vmBoxIndex] && vmBoxes[vmBoxIndex].id) || vmBoxes[0].id;
      try {
        if (makeCheckedItem.length > 0) {
          await Promise.all(
            makeCheckedItemObject.map(element => {
              changeVoicemailState({
                vmboxId: vmbox_id,
                messageId: element.media_id,
                data: {
                  folder: value
                }
              });
              return KazooSDK.makeItem(
                vmbox_id,
                element.media_id,
                value
              ).catch(e => {});
            })
          )
            .then(() => {
              updateVoicemailsState({
                page: 0,
                makeCheckedItem: [],
                makeCheckedItemObject: []
              });
              fetchVmBoxes();
              // fetchVoicemails();
            })
            .catch(e => {
              console.warn(e);
            });
        }
      } catch (e) {
        console.warn(e, 'err masked voicemail');
      }
    }
  };
};

export default getVoicemailMethods;
