import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesMobileCallItem = makeStyles(theme => ({
  item: {
    boxShadow: `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']}`,
    justifyContent: 'space-between',
    padding: 12,
    '&:last-of-type': {
      boxShadow: `inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']}, 
        inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']}`,
      marginBottom: 20
    }
  },
  wrapperContact: {
    display: 'flex',
    '& svg': {
      margin: '12px 24px 10px 0'
    },
    '& p': {
      margin: 0
    },
    '& div > p:first-child': {
      color: theme.palette.darkGrey['500'],
      fontWeight: 'bold',
      fontSize: 14
    },
    '& div > p:nth-child(2)': {
      fontSize: 12,
      color: theme.palette.textGrey['500']
    },
    '& div > p:last-child': {
      fontSize: 14,
      color: theme.palette.darkGrey['500']
    }
  },
  red: {
    '& div p:first-child': {
      color: theme.palette.red['500']
    }
  },
  alignRight: {
    textAlign: 'right',
    '& div > p:first-child': {
      fontWeight: 'normal'
    }
  }
}));
