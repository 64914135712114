import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStylesCallListTableHead } from './styles';
import { useTranslation } from 'react-i18next';
import { snakeToCamel } from '@KazooSDK/utils';

const headCells = [
  {
    id: 'callee_id_name',
    sort: false
  },
  {
    id: 'caller_id_name',
    sort: false
  },
  {
    id: 'timestamp',
    sort: true
  },
  {
    id: 'duration_seconds',
    sort: true
  }
];

export const CallListTableHead = ({ order, orderBy, onRequestSort }) => {
  const classes = useStylesCallListTableHead();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow className={classes.headerTable}>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={headCell.sort ? createSortHandler(headCell.id) : null}
              hideSortIcon={!headCell.sort}
              IconComponent={() => (
                <div className={classes.iconSort}>
                  <ArrowDropUpIcon />
                  <ArrowDropDownIcon />
                </div>
              )}
            >
              {t(`callHistory.headers.${snakeToCamel(headCell.id)}.label`)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
