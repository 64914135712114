import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import telicons from 'telicon/dist/telicon-3.0.0.svg';

export const Telicon = props => {
  // xmlns="http://www.w3.org/2000/svg"
  // xmlnsXlink="http://www.w3.org/1999/xlink"
  return (
    <SvgIcon style={{ fill: 'currentColor' }} className={props.className}>
      <use xlinkHref={`${telicons}#${props.name}`} />
    </SvgIcon>
  );
};

export const CommIo = props => (
  <SvgIcon viewBox="0 0 274 64" className={props.className}>
    <radialGradient
      id="grad_purp-grn"
      cx="15847.8184"
      cy="31.7376"
      r="29.1704"
      gradientTransform="matrix(-0.9952 0 0 0.9982 15805.0469 0.3358)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5A32DC" />
      <stop offset="6.413472e-02" stopColor="#4C4FD8" />
      <stop offset="0.1711" stopColor="#387AD1" />
      <stop offset="0.2838" stopColor="#279ECC" />
      <stop offset="0.4012" stopColor="#19BCC8" />
      <stop offset="0.5248" stopColor="#0ED3C4" />
      <stop offset="0.6575" stopColor="#06E3C2" />
      <stop offset="0.8055" stopColor="#01EDC0" />
      <stop offset="1" stopColor="#00F0C0" />
    </radialGradient>

    <radialGradient
      id="grad_org-purp"
      cx="15849.584"
      cy="30.9955"
      r="33.1434"
      gradientTransform="matrix(-0.9952 0 0 0.9982 15805.0469 0.3358)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5A32DC" />
      <stop offset="1.977289e-02" stopColor="#6234D4" />
      <stop offset="0.1318" stopColor="#8C3EA9" />
      <stop offset="0.2499" stopColor="#AF4685" />
      <stop offset="0.3728" stopColor="#CC4D67" />
      <stop offset="0.5023" stopColor="#E35250" />
      <stop offset="0.6412" stopColor="#F35640" />
      <stop offset="0.7962" stopColor="#FC5836" />
      <stop offset="1" stopColor="#FF5933" />
    </radialGradient>

    <g id="wordmark">
      <path
        id="io"
        fill="#ff5933"
        d="M243.7,19v25.7h-7.9V19H243.7z M239.7,6.5c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2
        c2.3,0,4.2-1.9,4.2-4.2C243.9,8.4,242,6.5,239.7,6.5z M274,31.7c0,7.4-6,13.5-13.4,13.5c-7.4,0-13.4-6-13.4-13.5
        c0-7.4,6-13.5,13.4-13.5C268,18.3,274,24.3,274,31.7z M267.5,31.7c0-3.8-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7S267.5,35.6,267.5,31.7z
         M226.9,36.2c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2C231.1,38.1,229.2,36.2,226.9,36.2z"
      />
      <path
        id="comm"
        fill="#fff"
        d="M205.7,25.2c-2.6,0-4.4,1.8-4.7,4.7v14.7h-8v-14c0-3.3-1.7-5.3-4.5-5.4c-0.2,0-0.5,0-0.7,0.1
        l-0.2,0c-2.2,0.4-3.7,2.2-3.8,4.9v14.4h-8v-14c0-3.3-1.7-5.3-4.5-5.4c-0.3,0-0.6,0-1,0.1c-0.3,0.1-0.5,0.1-0.8,0.2
        c-0.1,0-0.1,0.1-0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.4,0.4
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.5-0.2,0.7
        c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5-0.1,0.8v14.3h-8v-14c0-3.3-1.7-5.3-4.5-5.4c-0.2,0-0.5,0-0.7,0.1l-0.2,0c-2.2,0.4-3.6,2.2-3.8,4.8
        l0,14.5h-8v-14c0-3.3-1.7-5.3-4.5-5.4c-0.4,0-0.7,0-1.1,0.1c-0.5-1.5-1.3-2.8-2.2-4.1c1.9-1.8,3.9-2.7,6.4-2.9
        c0.2,0,0.4,0,0.6-0.1l0.1,0c0,0,0.1,0,0.2,0l0.2,0c0.1,0,0.2,0,0.3,0l0.6,0c0.2,0,0.4,0,0.7,0.1c0.1,0,0.2,0,0.4,0.1l0.1,0
        c0.2,0,0.3,0.1,0.5,0.1c0.3,0.1,0.5,0.2,0.8,0.2c0.1,0,0.2,0.1,0.3,0.1l0.1,0.1c0,0,0.1,0,0.2,0.1l0.1,0c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4l0.1,0.1c0.8,0.6,1.4,1.4,1.9,2.3l0.6,1l0.8-0.9c1.2-1.3,2.4-2.3,3.7-3l0.2-0.1
        c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.3-0.1,0.5-0.2l0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.1,0.9-0.2l0.2,0
        c0.3-0.1,0.7-0.1,1.1-0.1l0.1,0c0,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0l0.2,0c1.7,0,3.2,0.4,4.4,1.1c0.2,0.1,0.5,0.3,0.7,0.5
        c0.8,0.6,1.5,1.4,2,2.3l0.6,1l0.8-0.9c2.5-2.8,5.1-4.1,8.4-4.1h0.3c0.1,0,0.2,0,0.3,0l0.6,0c0.2,0,0.4,0,0.7,0.1l0.1,0l0.4,0.1
        c0.2,0,0.3,0.1,0.5,0.1c0.3,0.1,0.5,0.2,0.8,0.2l0.1,0c0.1,0,0.1,0,0.2,0.1l0.2,0.1h0c0,0,0.1,0,0.1,0.1l0.1,0
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2l0.2,0.1l0.1,0.1l0.1,0.1
        c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4l0.2,0.2
        c0.1,0.1,0.2,0.3,0.3,0.5l0.8,1.5l0.8-0.9c1.2-1.3,2.4-2.3,3.7-3l0.2-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.1,0.5-0.2
        l0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.1,0.9-0.2l0.2,0c0.3-0.1,0.7-0.1,1.2-0.1l0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.5,0,0.6,0
        l0.1,0c3.2,0.1,5.5,1.5,6.8,3.9l0.6,1l0.8-0.9c2.5-2.9,5.1-4.1,8.5-4.1c5.4,0,8.5,3.3,8.5,9.2v17.1h-7.9v-14
        C210.3,27.2,208.5,25.2,205.7,25.2z M133.7,31.7c0,7.4-6,13.5-13.4,13.5s-13.4-6-13.4-13.5c0-7.4,6-13.5,13.4-13.5
        S133.7,24.3,133.7,31.7z M127.2,31.7c0-3.8-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7S127.2,35.6,127.2,31.7z M95.1,18.3
        c-7.9,0-13.8,5.8-13.8,13.4v0.1c0,7.5,5.9,13.4,13.4,13.4c4.8,0,8.3-1.7,11-5.3l-5.1-3.7c-1.3,1.4-2.9,2.6-5.4,2.6
        c-3.6,0-6.3-3-6.3-7v-0.1c0-3.9,2.7-6.9,6.2-6.9c2.1,0,3.8,0.8,5.2,2.6l5.2-3.9C103.2,20,99.7,18.3,95.1,18.3z"
      />
    </g>

    <g id="symbol">
      <path
        fill="#00F0C0"
        d="M45.1,36l17.4,5.2c0.9-2.9,1.3-6,1.3-9.2c0-3.1-0.5-6.2-1.3-9l-17.4,5.2c0.4,1.2,0.6,2.5,0.6,3.8
          C45.7,33.4,45.4,34.7,45.1,36z M38.4,44.1l8.7,16c5.4-2.9,9.9-7.4,12.8-12.8l-16-8.7C42.7,41,40.8,42.9,38.4,44.1z M44,25.5
          l16-8.7C57,11.3,52.6,6.9,47.2,3.9l-8.7,16C40.8,21.2,42.8,23.1,44,25.5z M25.3,19.9l-8.7-16c-5.4,3-9.9,7.5-12.8,12.9l16,8.7
          C21.1,23.1,23,21.2,25.3,19.9z"
      />

      <path
        fill="#FF5933"
        d="M0,32c0,17.7,14.3,32,31.9,32c3.1,0,6.2-0.5,9-1.3l-5.2-17.5c-1.2,0.4-2.5,0.6-3.9,0.6
          c-7.6,0-13.7-6.2-13.7-13.8c0-1.3,0.2-2.6,0.6-3.8L1.3,23C0.5,25.8,0,28.9,0,32z"
      />

      <path
        fill="#fff"
        d="M35.8,18.8c-1.2-0.4-2.6-0.6-3.9-0.6c-1.4,0-2.7,0.2-3.9,0.6L22.8,1.3C25.7,0.5,28.7,0,31.9,0
              c3.2,0,6.2,0.5,9.1,1.3L35.8,18.8z"
      />
      <path
        fill="#fff"
        d="M38.7,37.5c-3,3.8-8.5,4.3-12.3,1.3c-3.7-3.1-4.3-8.6-1.3-12.3c3-3.8,8.5-4.3,12.3-1.3
              C41.2,28.2,41.7,33.8,38.7,37.5z"
      />
    </g>
  </SvgIcon>
);

export const CommIoNoText = props => (
  <SvgIcon viewBox="0 0 64 64" className={props.className}>
    <radialGradient
      id="grad_purp-grn"
      cx="15847.8184"
      cy="31.7376"
      r="29.1704"
      gradientTransform="matrix(-0.9952 0 0 0.9982 15805.0469 0.3358)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5A32DC" />
      <stop offset="6.413472e-02" stopColor="#4C4FD8" />
      <stop offset="0.1711" stopColor="#387AD1" />
      <stop offset="0.2838" stopColor="#279ECC" />
      <stop offset="0.4012" stopColor="#19BCC8" />
      <stop offset="0.5248" stopColor="#0ED3C4" />
      <stop offset="0.6575" stopColor="#06E3C2" />
      <stop offset="0.8055" stopColor="#01EDC0" />
      <stop offset="1" stopColor="#00F0C0" />
    </radialGradient>

    <radialGradient
      id="grad_org-purp"
      cx="15849.584"
      cy="30.9955"
      r="33.1434"
      gradientTransform="matrix(-0.9952 0 0 0.9982 15805.0469 0.3358)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5A32DC" />
      <stop offset="1.977289e-02" stopColor="#6234D4" />
      <stop offset="0.1318" stopColor="#8C3EA9" />
      <stop offset="0.2499" stopColor="#AF4685" />
      <stop offset="0.3728" stopColor="#CC4D67" />
      <stop offset="0.5023" stopColor="#E35250" />
      <stop offset="0.6412" stopColor="#F35640" />
      <stop offset="0.7962" stopColor="#FC5836" />
      <stop offset="1" stopColor="#FF5933" />
    </radialGradient>

    <g id="symbol">
      <path
        fill="#00F0C0"
        d="M45.1,36l17.4,5.2c0.9-2.9,1.3-6,1.3-9.2c0-3.1-0.5-6.2-1.3-9l-17.4,5.2c0.4,1.2,0.6,2.5,0.6,3.8
          C45.7,33.4,45.4,34.7,45.1,36z M38.4,44.1l8.7,16c5.4-2.9,9.9-7.4,12.8-12.8l-16-8.7C42.7,41,40.8,42.9,38.4,44.1z M44,25.5
          l16-8.7C57,11.3,52.6,6.9,47.2,3.9l-8.7,16C40.8,21.2,42.8,23.1,44,25.5z M25.3,19.9l-8.7-16c-5.4,3-9.9,7.5-12.8,12.9l16,8.7
          C21.1,23.1,23,21.2,25.3,19.9z"
      />

      <path
        fill="#FF5933"
        d="M0,32c0,17.7,14.3,32,31.9,32c3.1,0,6.2-0.5,9-1.3l-5.2-17.5c-1.2,0.4-2.5,0.6-3.9,0.6
          c-7.6,0-13.7-6.2-13.7-13.8c0-1.3,0.2-2.6,0.6-3.8L1.3,23C0.5,25.8,0,28.9,0,32z"
      />

      <path
        fill="#fff"
        d="M35.8,18.8c-1.2-0.4-2.6-0.6-3.9-0.6c-1.4,0-2.7,0.2-3.9,0.6L22.8,1.3C25.7,0.5,28.7,0,31.9,0
              c3.2,0,6.2,0.5,9.1,1.3L35.8,18.8z"
      />
      <path
        fill="#fff"
        d="M38.7,37.5c-3,3.8-8.5,4.3-12.3,1.3c-3.7-3.1-4.3-8.6-1.3-12.3c3-3.8,8.5-4.3,12.3-1.3
              C41.2,28.2,41.7,33.8,38.7,37.5z"
      />
    </g>
  </SvgIcon>
);

export const SendIcon = props => (
  <SvgIcon viewBox="0 0 465.882 465.882" className={props.className}>
    <path d="m465.882 0-465.882 262.059 148.887 55.143 229.643-215.29-174.674 235.65.142.053-.174-.053v128.321l83.495-97.41 105.77 39.175z" />
  </SvgIcon>
);
