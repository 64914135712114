import { compose } from 'redux';
import {
  TOGGLE_RING_MY_OTHER_DEVICES,
  TOGGLE_SEND_VM_TO_EMAIL,
  SAVE_CALL_FORWARDING_NUMBER,
  TOGGLE_KEEP_CALLER_ID,
  TOGGLE_CALL_FORWARDING,
  USER_UPDATED,
  USER_UPDATING,
  SAVE_USER_EMAIL,
  SET_USER_SETTINGS_DATA,
  ADD_DEVICE,
  DEVICE_UPDATING,
  DEVICE_UPDATED,
  TOGGLE_DO_NOT_DISTURB,
  SET_SETTINGS_LOADING
} from '../Constants';
import { KazooSDK } from '@KazooSDK';

const addDevice = device => ({
  type: ADD_DEVICE,
  payload: device
});
const deviceUpdating = id => ({
  type: DEVICE_UPDATING,
  payload: id
});
const deviceUpdated = id => ({
  type: DEVICE_UPDATED,
  payload: id
});
const toggleDoNotDisturb = id => ({
  type: TOGGLE_DO_NOT_DISTURB,
  payload: id
});
const setUserSettingsData = user => ({
  type: SET_USER_SETTINGS_DATA,
  payload: user
});
const userUpdating = () => ({
  type: USER_UPDATING
});
const userUpdated = () => ({
  type: USER_UPDATED
});
const saveUserEmailAction = email => ({
  type: SAVE_USER_EMAIL,
  payload: email
});
const toggleCallForwarding = () => ({
  type: TOGGLE_CALL_FORWARDING
});
const toggleKeepCallerId = () => ({
  type: TOGGLE_KEEP_CALLER_ID
});
const saveCallForwardingNumber = number => ({
  type: SAVE_CALL_FORWARDING_NUMBER,
  payload: number
});
const toggleSendVmToEmail = () => ({
  type: TOGGLE_SEND_VM_TO_EMAIL
});
const toggleRingMyOtherDevices = () => ({
  type: TOGGLE_RING_MY_OTHER_DEVICES
});

// /*
//   @key: api eg: /v2/accounts/xyz/faxbox/xyz/messages
//   @value: timestamp
// */
// export const updateLastSeen = (key, value) =>
//   async (dispatch, getState) => {
//     let last_seen = {};
//     last_seen[key] = value;
//     return KazooSDK.patchUser({
//       last_seen
//     }).then(
//       undefined,
//     ).then(compose(dispatch, userUpdated));
//   };

export const setSettingsLoading = (isLoading, isLoaded) => {
  return async dispatch => {
    dispatch({
      type: SET_SETTINGS_LOADING,
      payload: {
        isLoading,
        isLoaded
      }
    });
  };
};

export const fetchInitialData = () => async dispatch => {
  const { user } = await KazooSDK.getUserSettings();

  compose(dispatch, setUserSettingsData)(user);
  // devices.forEach(compose(dispatch, addDevice));
};

export const fetchFullData = () => async dispatch => {
  dispatch(setSettingsLoading(true));
  const { devices, user } = await KazooSDK.getUserSettingsAndDevices();

  compose(dispatch, setUserSettingsData)(user);
  devices.forEach(compose(dispatch, addDevice));
  dispatch(setSettingsLoading(false, true));
};

export const toggleUserCallForwarding = () => async (dispatch, getState) => {
  compose(dispatch, userUpdating)();
  compose(dispatch, toggleCallForwarding)();

  const { isCallForwardingEnabled } = getState().userSettings.user;

  return KazooSDK.patchUser({
    call_forward: {
      enabled: isCallForwardingEnabled
    }
  })
    .then(undefined, compose(dispatch, toggleCallForwarding))
    .then(compose(dispatch, userUpdated));
};

export const toggleUserKeepCallerId = () => async (dispatch, getState) => {
  compose(dispatch, userUpdating)();
  compose(dispatch, toggleKeepCallerId)();

  const { isKeepCallerIdEnabled } = getState().userSettings.user;

  return KazooSDK.patchUser({
    call_forward: {
      keep_caller_id: isKeepCallerIdEnabled
    }
  })
    .then(undefined, compose(dispatch, toggleKeepCallerId))
    .then(compose(dispatch, userUpdated));
};

export const saveUserCallForwardingNumber = number => async (
  dispatch,
  getState
) => {
  compose(dispatch, userUpdating)();
  const original = getState().userSettings.user.callForwardingNumber;
  compose(dispatch, saveCallForwardingNumber)(number);

  return KazooSDK.patchUser({
    call_forward: {
      number
    }
  })
    .then(undefined, () => {
      compose(dispatch, saveCallForwardingNumber)(original);
    })
    .then(compose(dispatch, userUpdated));
};

export const toggleDeviceDoNotDisturb = id => async (dispatch, getState) => {
  compose(dispatch, deviceUpdating)(id);
  compose(dispatch, toggleDoNotDisturb)(id);

  const devices = getState().userSettings.devices;
  const { isDoNotDisturbEnabled } = devices.find(device => device.id === id);

  return KazooSDK.patchDevice(id, {
    do_not_disturb: {
      enabled: isDoNotDisturbEnabled
    }
  })
    .then(undefined, () => {
      compose(dispatch, toggleDoNotDisturb)(id);
    })
    .then(() => {
      compose(dispatch, deviceUpdated)(id);
    });
};

export const toggleUserSendVmToEmail = () => async (dispatch, getState) => {
  compose(dispatch, userUpdating)();
  compose(dispatch, toggleSendVmToEmail)();

  const { isVoicemailToEmailEnabled } = getState().userSettings.user;

  return KazooSDK.patchUser({
    vm_to_email_enabled: isVoicemailToEmailEnabled
  })
    .then(undefined, compose(dispatch, toggleSendVmToEmail))
    .then(compose(dispatch, userUpdated));
};

export const toggleUserRingMyOtherDevices = () => async (
  dispatch,
  getState
) => {
  compose(dispatch, userUpdating)();
  compose(dispatch, toggleRingMyOtherDevices)();

  const { isRingMyOtherDevicesEnabled } = getState().userSettings.user;

  return KazooSDK.patchUser({
    call_forward: {
      substitute: !isRingMyOtherDevicesEnabled
    }
  })
    .then(undefined, compose(dispatch, toggleRingMyOtherDevices))
    .then(compose(dispatch, userUpdated));
};

export const saveUserEmail = email => async (dispatch, getState) => {
  compose(dispatch, userUpdating)();
  const original = getState().userSettings.user.emailAddress;
  compose(dispatch, saveUserEmailAction)(email);

  return KazooSDK.patchUser({
    email
  })
    .then(undefined, () => {
      compose(dispatch, saveUserEmailAction)(original);
    })
    .then(compose(dispatch, userUpdated));
};
