import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Label from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import prepareLoginState from 'Selectors/Login';
import { login, loginWithAuthToken, updateAuthForm } from 'Actions/auth';
import { DefaultPageLoader } from 'Applications/UserPortal/Components/Common/DefaultPageLoader';
import { AuthError } from 'Components/AuthError';
import { LoginScreen } from './types';
import useStyles from './styles';

const Login: React.FC = () => {
  const [isMobile] = useSessionStorage('mobile', false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    rememberChk,
    auth_token,
    username,
    account_name,
    autoLogin,
    loading,
    error
  }: LoginScreen = useSelector(prepareLoginState);

  const [form, setForm] = useState({
    username: rememberChk ? username : '',
    password: '',
    account_name: rememberChk ? account_name : ''
  });

  useEffect(() => {
    const onkeydown = ({ key }: { key: string }) => {
      if (key === 'Enter') {
        dispatch(login(form.username, form.password, form.account_name));
      }
    };
    document.addEventListener('keydown', onkeydown);
    return () => {
      document.removeEventListener('keydown', onkeydown);
    };
  }, [dispatch, form.username, form.password, form.account_name]);

  useEffect(() => {
    if (autoLogin) {
      if (auth_token) {
        console.log('Authenticaing with token');
        dispatch(loginWithAuthToken(auth_token));
      } else {
        dispatch(login(form.username, form.password, form.account_name));
      }
    }
  }, [
    dispatch,
    autoLogin,
    auth_token,
    form.username,
    form.password,
    form.account_name
  ]);

  const handleChange = (name: string) => ({
    target: { value }
  }: {
    target: { value: string };
  }) => {
    setForm({ ...form, [name]: value });
  };

  if (autoLogin && !error) {
    return (
      <Container className={classes.centerContainer}>
        <DefaultPageLoader text={'Authenticating'} />
      </Container>
    );
  }

  if (isMobile) {
    return <AuthError />;
  }

  return (
    <Grid item xs={6} className={classes.grid}>
      <Paper className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography
              style={{ margin: 0 }}
              variant="h5"
              className={classes.header}
            >
              {t('signIn.label')}
            </Typography>
            {error && (
              <Typography color="secondary" align="center">
                {error}
              </Typography>
            )}
            <span className={classes.firstSpan}>
              <Label className={classes.label}>{t('username.label')}</Label>
              <TextField
                id="username"
                className={classes.textField}
                value={form.username}
                variant="outlined"
                onChange={handleChange('username')}
                margin="normal"
              />
            </span>
            <span className={classes.secondSpan}>
              <Label className={classes.label}>{t('password.label')}</Label>
              <TextField
                id="outlined-password-input"
                className={classes.textField}
                type="password"
                value={form.password}
                onChange={handleChange('password')}
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
              />
            </span>
            <span className={classes.secondSpan}>
              <Label className={classes.label}>{t('accountName.label')}</Label>
              <TextField
                id="account-name"
                className={classes.textField}
                value={form.account_name}
                variant="outlined"
                onChange={handleChange('account_name')}
                margin="normal"
              />
            </span>
            <div className={classes.row}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    checked={rememberChk}
                    onChange={(e) =>
                      dispatch(
                        updateAuthForm({
                          rememberChk: e.target.value !== 'true'
                        })
                      )
                    }
                    value={rememberChk}
                    color="default"
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                  />
                }
                label={t('rememberme.label')}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
                fullWidth
                onClick={() =>
                  dispatch(
                    login(form.username, form.password, form.account_name)
                  )
                }
                disabled={loading}
              >
                {t('signIn.label')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export default withRouter(Login);
