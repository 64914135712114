import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  playPauseIcon: {
    fontSize: 18
  },
  timing: {
    fontSize: 12
  },
  duration: {
    fontSize: 12,
    textAlign: 'right'
  },
  progressBar: {
    marginTop: 5,
    borderRadius: 4,
    height: 8,
    background: props =>
      props.currentPercentage
        ? `linear-gradient(to right, ${theme.palette.iconBlue['500']} ${props.currentPercentage}%, ${theme.palette.iconGrey['500']} 0)`
        : theme.palette.iconGrey['500']
  },
  knob: {
    position: 'relative',
    top: -4,
    left: props => `${props.currentPercentage - 2}%`,
    height: 16,
    width: 16,
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    borderRadius: '50%',
    backgroundColor: 'white'
  }
}));

export default useStyles;

// @import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");

// .App {
//   font-family: sans-serif;
//   text-align: center;
// }

// .player {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px 0;
//   background-color: #212527;

//   .song {
//     user-select: none;
//     margin: 0 20px;
//     .song__title {
//       text-align: left;
//       margin: 0;
//       color: white;
//       font-family: "Permanent Marker", cursive;
//       font-weight: normal;
//       font-size: 3.5em;

//       &:hover {
//         color: greenyellow;
//       }
//     }

//     .song__artist {
//       margin: 0;
//       color: white;
//       font-family: "Rock Salt", cursive;
//       font-weight: normal;
//       font-size: 1em;

//       &:hover {
//         color: greenyellow;
//         cursor: pointer;
//       }
//     }
//   }

//   .controls {
//     flex-grow: 1;
//     margin: 0 20px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }

//   .player__button {
//     width: fit-content;
//     margin-bottom: 15px;
//     background-color: transparent;
//     border: none;

//     &:focus {
//       outline: none;
//     }
//     &:hover {
//       cursor: pointer;
//       svg {
//         color: greenyellow;
//       }
//     }

//     svg {
//       font-size: 4em;
//       color: white;
//     }
//   }

//   .bar {
//     user-select: none;
//     width: 100%;
//     display: flex;
//     align-items: center;

//     .bar__time {
//       color: white;
//       font-size: 16px;
//     }

//     .bar__progress {
//       flex: 1;
//       border-radius: 5px;
//       margin: 0 20px;
//       height: 10px;
//       display: flex;
//       align-items: center;
//       cursor: pointer;

//       .bar__progress__knob {
//         position: relative;
//         height: 16px;
//         width: 16px;
//         border: 1.5px solid white;
//         border-radius: 50%;
//         background-color: orange;
//       }
//     }
//   }

//   @media screen and (max-width: 800px) {
//     flex-direction: column;

//     .controls {
//       width: 100%;
//       margin-top: 20px;
//     }

//     .bar {
//       width: 90%;
//     }
//   }

//   @media screen and (max-width: 500px) {
//     .song {
//       .song__title {
//         font-size: 2.5em;
//       }

//       .song__artist {
//         font-size: 0.8em;
//       }
//     }
//   }
// }
