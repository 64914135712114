import React from 'react';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
import { DeviceList } from './DeviceList';
import { NumberList } from './NumberList';

const DevicesAndNumbers = props => {
  return (
    <div>
      <div>Devices</div>
      <DeviceList />
      <div>Numbers</div>
      <NumberList />
    </div>
  );
};

export default DevicesAndNumbers;
