import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';
import { defaults, get } from 'lodash';

const persist = persistReducer('conferences');

const initialState = {
  // vm_box_index: 0, // NOT used anymore
  // verto_config: [], // NOT used anymore
  numbers: [],
  conferences: [],
  loadingConferences: false,
  loadedConferences: false,
  conferenceById: {
    // [conference_id]: { verto_config: JSON }
  }
};

const defaultConferenceStruct = {
  loading: false,
  loaded: false,
  verto_config: []
};

const defaultState = persist(initialState, 'get', initialState);

export default (state = defaultState, action) => {
  let conferenceById;
  var conference_id;
  var bool;
  var verto_config;
  switch (action.type) {

    case CONSTS.SET_CONFERENCES_CALL_IN_NUMBERS:
      const { numbers } = action.payload;

      return persist({
        ...state,
        numbers
      });

    case CONSTS.SET_CONFERENCES:
      const { conferences } = action.payload;
      conferenceById = state.conferenceById;
      for (let conference of conferences) {
        const conference_id = conference.id;
        if (!conferenceById[conference_id]) {
          conferenceById[conference_id] = {};
        }
        conferenceById[conference_id] = defaults(
          conferenceById[conference_id],
          defaultConferenceStruct
        );
      }
      // TODO: remove unused conferencees, verto_config

      return persist({
        ...state,
        conferences: conferences,
        conferenceById
      });

    case CONSTS.SET_CONFERENCES_LOADING:
      // loading ALL faxboxes
      let loadingConferences = get(
        action.payload,
        'loadingConferences',
        state.loadingConferences
      );
      let loadedConferences = get(
        action.payload,
        'loadedConferences',
        state.loadedConferences
      );

      return persist({
        ...state,
        loadingConferences,
        loadedConferences
      });

    case CONSTS.SET_CONFERENCE_VERTO_CONFIG_LOADING:
      // removes message dupes by media_id
      conference_id = action.payload.conference_id;
      bool = action.payload.bool;

      conferenceById = state.conferenceById || {};
      if (!conferenceById[conference_id]) {
        conferenceById[conference_id] = {};
      }
      conferenceById[conference_id] = defaults(
        conferenceById[conference_id],
        defaultConferenceStruct
      );

      conferenceById[conference_id].loading = bool;

      return persist({
        ...state,
        conferenceById
      });

    case CONSTS.SET_CONFERENCE_VERTO_CONFIG:
      // removes message dupes by media_id
      conference_id = action.payload.conference_id;
      verto_config = action.payload.verto_config;

      conferenceById = state.conferenceById || {};
      if (!conferenceById[conference_id]) {
        conferenceById[conference_id] = {};
      }
      conferenceById[conference_id] = defaults(
        conferenceById[conference_id],
        defaultConferenceStruct
      );
      conferenceById[conference_id].verto_config = verto_config;

      return persist({
        ...state,
        conferenceById
      });

    case CONSTS.LOGOUT:
      return persist(initialState);

    default:
      return persist(state);
  }
};
