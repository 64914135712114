import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(244, 244, 244)',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  rootLogin: {
    backgroundColor: 'rgb(244, 244, 244)',
    height: '100vh',
    width: '100vw',
    margin: 0
  }
}));
