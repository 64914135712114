import React, {useCallback} from 'react';

import { Grid, Button } from '@material-ui/core';
import useStyles from './styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { vertoLauncher } from './vertoLauncher';

import classNames from 'classnames';

import { useDebounce } from 'react-use';

import { useWebsocketBinding } from '@Util/websocketBinding';

import {
  fetchConferences
  // fetchConferenceVertoConfig
} from '../../../Actions/conferences';


const ConferenceItem = props => {
  const { conference, fetchConferences, allNumbers } = props;

  const [val, setVal] = React.useState(0);
  const [inConference, setInConference] = React.useState(false);
  const [unsubscribe, setUnsubscribe] = React.useState(false);


  const classes = useStyles();

  useDebounce(fetchConferences, 2000, [val]);

  useWebsocketBinding(
    [`conference.event.${conference.id}.*`], // TODO: get correct websockets to update automatically on New conferences
    'userportal.conferences',
    data => {
      // console.log(conference);
      //console.log('single conference event:', data);
      // fetchConferences(); // TODO: abort previous attempts to fetch

      // https://freeswitch.org/confluence/display/FREESWITCH/mod_conference#mod_conference-ConferenceProfileParameters
      // - look for "Table: Conference Events
      if (
        [
          'add-member',
          'del-member',
          'kick-member',
          'transfer',
          'conference-destroy',
          'lock',
          'unlock'
        ].includes(data.data.event)
      ) {
        setVal(val + 1);
        switch(data.data.event) {
          case 'conference-destroy':
            setInConference(false);
            setUnsubscribe(true);
            break;
          default:
            console.log(`"${data.data.event}" received`);
            break;
        }
      }
    },
    inConference,
    unsubscribe
  );

  // const isActive = conference._read_only.participant_count;
  const isLocked = conference._read_only.is_locked;
  const launchVertoConference = useCallback(() => {
    if(!inConference) {
      setInConference(true);
    }
    console.log("Currently in conference:", conference.id);
    vertoLauncher(conference, allNumbers);
  },[conference, allNumbers, inConference]);
  return (
    <Grid container spacing={2} className={classes.row}>
      <Grid item style={{ width: 120 }}>
        <Button
          variant="outlined"
          color="default"
          disableRipple
          className={classNames([
            classes.button,
            isLocked ? classes.buttonLocked : classes.buttonJoin
          ])}
          onClick={launchVertoConference}
          disabled={isLocked}
        >
          {isLocked ? 'LOCKED' : 'JOIN'}
        </Button>
      </Grid>
      {/* <Grid item xs={1}>
        <div style={{ textAlign: 'center', marginTop: 8 }}>
          {isActive ? (
            <>active: {conference._read_only.participant_count}</>
          ) : (
            <>
              <span style={{ fontStyle: 'italic' }}>inactive</span>
            </>
          )}
        </div>
      </Grid> */}
      <Grid item>
        <div>
          <span className={classes.name}>{conference.name}</span>
        </div>
        <div>Conference ID: {conference.numbers}</div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchConferences
  })
)(ConferenceItem);
