import * as CONSTS from '../Constants';
import { KazooSDK } from '@KazooSDK';
import { get } from 'lodash';

export const setCallDataLoading = (isLoading, isLoaded) => {
  return async dispatch => {
    dispatch({
      type: CONSTS.SET_CALL_DATA_LOADING,
      payload: {
        isLoading,
        isLoaded
      }
    });
  };
};

export const fetchInitialData = () => {
  return async dispatch => {
    try {
      dispatch(setCallDataLoading(true));
      // console.log('Shouldnt be loading "ALL" here'); // should be just looking for missed calls since last_seen
      // debugger;
      const { callHistory } = await KazooSDK.getInitDataCallHistory();
      dispatch({
        type: CONSTS.SET_CALL_DATA,
        payload: { callHistory }
      });
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
    dispatch(setCallDataLoading(false, true));
  };
};

export const filterCallHistory = props => {
  return async dispatch => {
    try {
      dispatch(setCallDataLoading(true));
      const { callHistory } = await KazooSDK.filterDataCallHistory(props);
      dispatch({
        type: CONSTS.SET_CALL_DATA,
        payload: { callHistory }
      });
    } catch (e) {
      if (get(e, 'response.status') === 401) {
        // dispatch({ type: CONSTS.LOGOUT });
      }
    }
    dispatch(setCallDataLoading(false, true));
  };
};

export const resetCallHistory = () => dispatch => {
  dispatch({
    type: CONSTS.RESET_CALL_DATA
  });
};
