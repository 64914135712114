import React from "react";
import useStyles from "./styles.js";

export const SquareIconButton = props => {
  const {
    color, 
    // disabled, 
    onClick, 
    children
  } = props;

  const classes = useStyles({color});

  return (
    <div className={classes.root} onClick={onClick}>
      {children}
    </div>
  )

}

export default SquareIconButton;
