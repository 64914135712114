import { combineReducers } from 'redux';
import {
  TOGGLE_RING_MY_OTHER_DEVICES,
  TOGGLE_SEND_VM_TO_EMAIL,
  SAVE_CALL_FORWARDING_NUMBER,
  TOGGLE_KEEP_CALLER_ID,
  TOGGLE_CALL_FORWARDING,
  USER_UPDATED,
  USER_UPDATING,
  SAVE_USER_EMAIL,
  SET_USER_SETTINGS_DATA,
  DEVICE_UPDATED,
  DEVICE_UPDATING,
  ADD_DEVICE,
  TOGGLE_DO_NOT_DISTURB,
  SET_SETTINGS_LOADING,
  LOGOUT
} from '../Constants';

import { get } from 'lodash';

const device = (
  state = {
    isUpdating: false
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_DEVICE:
      return {
        ...payload,
        ...state
      };
    case DEVICE_UPDATING:
      return state.id === payload
        ? {
            ...state,
            isUpdating: true
          }
        : state;
    case DEVICE_UPDATED:
      return state.id === payload
        ? {
            ...state,
            isUpdating: false
          }
        : state;
    case TOGGLE_DO_NOT_DISTURB:
      return state.id === payload
        ? {
            ...state,
            isDoNotDisturbEnabled: !state.isDoNotDisturbEnabled
          }
        : state;
    default:
      return state;
  }
};

const devices = (state = [], action) => {
  const { type } = action;

  switch (type) {
    case ADD_DEVICE:
      if (state.find(device => device.id === action.payload.id)) {
        return state;
      }
      return [...state, device(undefined, action)];
    case DEVICE_UPDATING:
    case DEVICE_UPDATED:
    case TOGGLE_DO_NOT_DISTURB:
      return state.map(d => device(d, action));
    default:
      return state;
  }
};

const user = (
  state = {
    isUpdating: false,
    isLoading: false,
    isLoaded: false
  },
  action
) => {
  const { type } = action;

  switch (type) {
    case SET_SETTINGS_LOADING:
      let isLoading = get(action.payload, 'isLoading', state.isLoading);
      let isLoaded = get(action.payload, 'isLoaded', state.isLoaded);

      return {
        ...state,
        isLoading,
        isLoaded
      };

    case SET_USER_SETTINGS_DATA:
      return {
        ...state,
        ...action.payload
      };
    case USER_UPDATING:
      return {
        ...state,
        isUpdating: true
      };
    case USER_UPDATED:
      return {
        ...state,
        isUpdating: false
      };
    case TOGGLE_KEEP_CALLER_ID:
      return {
        ...state,
        isKeepCallerIdEnabled: !state.isKeepCallerIdEnabled
      };
    case TOGGLE_CALL_FORWARDING:
      return {
        ...state,
        isCallForwardingEnabled: !state.isCallForwardingEnabled
      };
    case SAVE_CALL_FORWARDING_NUMBER:
      return {
        ...state,
        callForwardingNumber: action.payload
      };
    case TOGGLE_SEND_VM_TO_EMAIL:
      return {
        ...state,
        isVoicemailToEmailEnabled: !state.isVoicemailToEmailEnabled
      };
    case TOGGLE_RING_MY_OTHER_DEVICES:
      return {
        ...state,
        isRingMyOtherDevicesEnabled: !state.isRingMyOtherDevicesEnabled
      };
    case SAVE_USER_EMAIL:
      return {
        ...state,
        emailAddress: action.payload
      };
    case LOGOUT:
      // TODO: how to clear user state?
      console.log('state:', state);
      return { ...state, fullName: 'bobby' };
    default:
      return state;
  }
};

export default combineReducers({
  user,
  devices
});
