import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { rootStyles } from './styles';
import FaxBox from './FaxBox';
import { fetchInitialFaxData } from '../../Actions/faxes';
// import { Container } from '@material-ui/core';
// import { DeviceFax as DeviceFaxIcon } from '../Icons';
import { DefaultPageLoader } from '../Common/DefaultPageLoader';
import { useTranslation } from 'react-i18next';
import { EmptyResultBox } from '../Common/EmptyResultBox';

const Faxes = props => {
  const classes = rootStyles();
  const { faxboxes, fetchInitialFaxData, history } = props;
  // const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setLoading(true);
  //   (async () => {
  //     await getAllFaxes({});
  //     setLoading(false);
  //   })();
  // }, [getAllFaxes]);

  useEffect(() => {
    (async () => {
      await fetchInitialFaxData({});
    })();
  }, [fetchInitialFaxData]);
  let loading = false;

  // route to default vmBox if only 1
  useEffect(() => {
    if (faxboxes.length === 1) {
      history.replace(`/user_portal/faxes/faxbox/${faxboxes[0].id}`);
    }
  }, [loading, faxboxes, history]);

  if (faxboxes.length > 1) {
    return (
      <>
        <div className={classes.root}>
          <h2 className={classes.singleTitle}>
            {t('faxes.faxBoxMultiple.label')}
          </h2>
          <Grid container className={classes.container} spacing={3}>
            {faxboxes.map(element => (
              <Grid
                key={element.id}
                item
                md={4}
                sm={6}
                xs={12}
                className={classes.gridItem}
              >
                <FaxBox faxbox={element} />
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    );
  }

  if (faxboxes.length === 0 && !loading) {
    return (
      <EmptyResultBox
        icon={'device-fax'}
        title={t('faxes.faxBoxNoneTitle.label')}
        text={t('faxes.faxBoxNoneText.label')}
      />
    );
  }

  return <DefaultPageLoader text="Loading Fax Boxes" />;
};

const mapStateToProps = state => {
  return {
    faxboxes: (state.faxes.faxboxes || []).sort((fb1, fb2) => {
      return (fb2?.newFaxes ?? 0) - (fb1.newFaxes ?? 0);
    })
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchInitialFaxData })
)(Faxes);
