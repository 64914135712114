import React from "react";
import useStyles from "./styles";
import { Loader } from '../Loader';
import {
  Grid
} from "@material-ui/core";

export const DefaultPageLoader = props => {
  const {
    text
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.rootLoading}
    >
      <Grid item xs={12}>
        <div><Loader /></div>
        <div>{text}</div>
      </Grid>
    </Grid>
  );
};

export default DefaultPageLoader;
