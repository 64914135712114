import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(1)
  },
  body: {
    backgroundColor: props => {
      switch (props.type) {
        case 'success':
        case 'nice':
          return theme.palette.successLightGreen['500'];
        case 'mean':
        case 'error':
        case 'fail':
        default:
          return theme.palette.failLightRed['500'];
      }
    },
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2)
    // margin: theme.spacing(2)
  },
  icon: {
    color: props => {
      switch (props.type) {
        case 'success':
        case 'nice':
          return theme.palette.voicemailIconGreen['500'];
        case 'mean':
        case 'error':
        case 'fail':
        default:
          return theme.palette.red['500'];
      }
    }
  },
  iconContainer: {
    textAlign: 'center'
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    // fontSize: theme.spacing(2),
    color: theme.typography.color
  },
  message: {
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color
  }
}));

export default useStyles;
