import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 321,
    overflow: 'auto',
    position: 'relative'
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    cursor: 'pointer'
  },
  totalRow: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  totalNumber: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(4)
  },
  totalText: {
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontFamily: 'Source Sans Pro',
    color: theme.palette.darkGrey['500']
  },
  unregisteredNumber: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(4),
    marginLeft: theme.spacing(3),
    color: theme.palette.red['500']
  },
  unregisteredText: {
    color: theme.palette.darkGrey['500'],
    fontSize: theme.spacing(2) - 2,
    fontFamily: 'Source Sans Pro',
    fontWeight: 'normal',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  carousel: {
    height: 200,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'no-wrap',
    padding: theme.spacing(2)
  },
  box: {
    borderRadius: 2,
    height: 166,
    width: 244,
    flex: '0 0 auto',
    margin: 16,
    padding: 8,
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  unregisteredIcon: {
    position: 'absolute',
    right: 16,
    top: 16,
    height: 16,
    width: 16,
    color: theme.palette.red['500']
  },
  registeredIcon: {
    position: 'absolute',
    right: 16,
    top: 16,
    height: 16,
    width: 16,
    color: theme.palette.voicemailIconGreen['500']
  },
  squareContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default useStyles;
