import { persistReducer } from 'utils/KazooSDK/utils';
import { SettingsState } from './types';
const persist = persistReducer('settings');

const initialState = {
  language: 'en'
};

const defaultState = persist(initialState, 'get', initialState);

export default (
  state: SettingsState = defaultState,
  action: { type: string }
): SettingsState => {
  switch (action.type) {
    default:
      return state;
  }
};
