import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesCallListTablePagination = makeStyles(theme => ({
  root: {
    '& p': {
      fontFamily: '"Source Sans Pro-Regular", sans-serif'
    },
    flexShrink: 0,
    margin: 0,
    '& button': {
      background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, 
        ${theme.palette.sidebarText['500']} 100%)`,
      color: theme.palette.textGrey['500'],
      fontSize: 14,
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderRadius: 0,
      height: 38,
      padding: '6px 10px',
      minWidth: 38,
      '&:first-child': {
        borderRadius: '2px 0 0 2px'
      },
      '&:last-child': {
        borderRadius: '0 2px 2px 0'
      }
    }
  },
  activeButton: {
    background: `${theme.palette.inboundGrey['500']} !important`,
    border: `1px solid ${theme.palette.inboundGrey['500']} !important`,
    color: `${theme.palette.grey['500']} !important`
  }
}));