import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'inline-flex',
    flexDirection: 'row',
    width: '1rem',
    height: '1rem',
    backgroundColor: 'white',
    borderRadius: '50%',
    color: props => {
      switch(props.type){
        case 'success':
          return theme.palette.iconSuccess['500'];
        case 'error':
          return theme.palette.iconRed['500'];
        default:
          return theme.palette.iconBlue['500'];
      }
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    verticalAlign: 'middle',
    marginTop: props => props.hasOwnProperty('marginTop') ? props.marginTop : 0
  },

  dot: {
    animation: '$mui-loader 1.4s infinite ease-in-out',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    height: '25%',
    width: '25%',

    '&:nth-child(2)': {
      animationDelay: '0.4s'
    },

    '&:nth-child(3)': {
      animationDelay: '0.8s'
    }
  },

  '@keyframes mui-loader': {
    '0%': {
      opacity: 0.4,
      transform: 'scale(1, 1)'
    },

    '50%': {
      opacity: 1,
      transform: 'scale(1.2, 1.2)'
    },

    '100%': {
      opacity: 0.4,
      transform: 'scale(1, 1)'
    }
  }

}));

export default useStyles;
