import React, { useReducer, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import {
  // Container,
  Select,
  Button,
  Hidden,
  ListItem,
  MenuItem
} from '@material-ui/core';
import { compose } from 'redux';
import { orderBy as _orderBy, get } from 'lodash';
import TableFaxBox from './TableFaxBox';
import TableMobileFaxBox from './TableMobileFaxBox';
import classNames from 'classnames';
import { rootStyles } from './styles';
import { SendIcon, Telicon } from '../Icons/index';
import { PhoneNumberFlag } from '../Common/PhoneNumberFlag';
import SearchWidget from '../Common/SearchWidget';
import FilterWidgetFax from '../Common/FilterWidgetFax/FilterWidgetFax';
import FilterWidgetFaxMobile from '../Common/FilterWidgetFax/FilterWidgetFaxMobile';
import SelectStatus from '../Common/SelectStatus/SelectStatus';
import FormFax from '../Common/FormFax/FormFax';
import {
  resetFaxes,
  updateFaxesState,
  getFaxes,
  updateFaxBox,
  fetchFaxesForFaxbox
} from '../../Actions/faxes';
import { KazooSDK } from '@KazooSDK';
import { filterFaxes } from '@KazooSDK/utils';
import { getFaxMethods } from '@KazooSDK/helpers/FaxesHelpers';
import { useTranslation } from 'react-i18next';

import { updateLastSeen } from '../../../../Actions/auth';
import { EmptyResultBox } from '../Common/EmptyResultBox';

export const FaxBox = (props) => {
  const classes = rootStyles();
  const methods = getFaxMethods(props);
  const { t } = useTranslation();

  const {
    faxesInboxData,
    faxesOutboxData,
    faxBox,
    // usableBoxes,
    faxboxes,
    type,
    listStatus,
    statusSelected,
    fetchFaxesForFaxbox,
    makeCheckedFax,
    updateFaxesState,
    match,
    updateLastSeen,
    updateFaxBox,
    history,
    order,
    orderBy,
    page,
    rowsPerPage,
    to,
    from,
    typeFilter,
    listStatusFilter,
    makeCheckedFaxObject
  } = props;

  const initState = {
    selected: match.params.faxbox_id,
    filter: '',
    openSendForm: window.openSendForm || false,
    loading: false
  };

  // TODO: remove temporary
  window.openSendForm = false;

  const [state, setState] = useReducer(
    (preState, newState) => ({
      ...preState,
      ...newState
    }),
    initState
  );

  const { selected, filter, openSendForm, loading } = state;

  const lastTimestamp = get(faxesInboxData, [0, 'timestamp']);

  let faxesData = _orderBy(
    [...faxesInboxData, ...faxesOutboxData],
    'created',
    'desc'
  );

  // useEffect(() => {
  //   setState({ loading: true });
  //   (async () => {
  //     await getAllFaxes({});
  //     setState({ loading: false });
  //   })();
  //   // return () => {
  //   //   resetFaxes();
  //   // }
  // }, [getAllFaxes]);

  // scrollToTop on display,
  // - also triggers a fetch
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchFaxesForFaxbox(faxBox.id, faxBox.caller_id);
  }, [history.location, faxBox.id, faxBox.caller_id, fetchFaxesForFaxbox]);

  const handleChange = (event) => {
    setState({ selected: event.target.value });
    updateFaxBox(faxboxes.find((element) => element.id === event.target.value));
    history.push(`/user_portal/faxes/faxbox/${event.target.value}`);
  };

  const handleFilter = (params) => {
    methods.filterFaxes(params);
  };

  const handleOnChangeType = (value) => {
    methods.updateType(value);
  };

  const handleData = () => {
    let data = [];
    switch (type) {
      case 'all':
        data = faxesData.filter(
          (ele) =>
            (ele.folder === 'inbox' && listStatus.includes('received')) ||
            listStatus.includes(ele.status)
        );
        break;
      case 'received':
        data = listStatus.includes('received') ? faxesInboxData : [];
        break;
      case 'sent':
        data = faxesOutboxData.filter((ele) => listStatus.includes(ele.status));
        break;
      default:
        data = faxesData;
        break;
    }
    return filterFaxes(data, filter, faxBox) || [];
  };

  const handleChangeFilter = (value) => {
    setState({ filter: value });
    updateFaxesState({ page: 0 });
  };

  const handleCheckbox = (event) => {
    methods.handleCheckBox(event.target.name);
  };

  const handleDelete = () => {
    if (window.confirm('Want to delete?')) {
      methods.deleteFaxes(faxesData);
    }
  };

  const handleDeleteOneFax = (folder, id) => {
    // if (window.confirm('Want to delete?')) {
    methods.deleteFax(folder, id);
    // }
  };

  const handleResend = () => {
    if (window.confirm('Want to resend?')) {
      methods.resendFax();
    }
  };

  const handleOnClick = () => setState({ openSendForm: true });

  const handleClose = () => setState({ openSendForm: false });

  const handleChangeSelectStatus = (e) => {
    methods.handleSelectStatus(e.target.value, handleData());
  };

  const handleOpenFile = (URL_file) => {
    methods.handleOpenFile(URL_file);
  };

  useEffect(() => {
    // Update last_seen for this faxbox
    if (!lastTimestamp) {
      return;
    }
    const url = KazooSDK.getUserLastSeenFaxboxUrl(faxBox.id);
    updateLastSeen(url, lastTimestamp);
  }, [faxBox.id, lastTimestamp, updateLastSeen]);

  return (
    <>
      <div className={classes.root}>
        <FormControl className={classes.selectWrapper} variant='outlined'>
          {faxboxes.length > 1 ? (
            <Select
              className={classes.select}
              id='fax-box-detail'
              value={selected}
              onChange={(event) => handleChange(event)}
            >
              {faxboxes.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <h3>{faxboxes[0] && faxboxes[0].name}</h3>
          )}
        </FormControl>
        <div className={classes.wrapTop}>
          <ListItem>
            {faxBox.fax_identity ? (
              <>
                <h6>{faxBox.fax_identity}</h6>
                <PhoneNumberFlag ptn={faxBox.caller_id} size={12} />
              </>
            ) : (
              <>
                <h6>{faxBox.fax_identity}</h6>
                <PhoneNumberFlag ptn={faxBox.caller_id} size={12} />
              </>
            )}
            {/* <img src="/usa.png" className="ml-1" alt="flag" /> */}
          </ListItem>
          <Hidden xsDown>
            <Button
              variant='contained'
              color='primary'
              className={classNames([classes.button, classes.buttonSend])}
              endIcon={<SendIcon />}
              onClick={handleOnClick}
            >
              Send Fax
            </Button>
          </Hidden>
        </div>
        <div className={classes.wrapMid}>
          <Hidden xsDown>
            <div className={classes.wrapSelectStatus}>
              <SelectStatus
                statusSelected={statusSelected}
                handleChangeSelectStatus={handleChangeSelectStatus}
              />
              {makeCheckedFaxObject.length > 0 &&
                makeCheckedFaxObject.every(
                  (element) =>
                    element.status === 'failed' || element.status === 'pending'
                ) && (
                  <Button
                    className={[classes.buttonAction, classes.buttonReSend]}
                    onClick={handleResend}
                  >
                    <Telicon name='refresh' />
                  </Button>
                )}
              {makeCheckedFax.length > 0 && (
                <Button className={classes.buttonAction} onClick={handleDelete}>
                  <Telicon name='trash' />
                </Button>
              )}
            </div>
          </Hidden>
          <div className='wrapMidFlex'>
            <Hidden xsDown>
              <FilterWidgetFax
                from={from}
                to={to}
                typeFilter={typeFilter}
                listStatusFilter={listStatusFilter}
                updateFaxesState={updateFaxesState}
                handleFilter={handleFilter}
              />
            </Hidden>
            <Hidden smUp>
              <FilterWidgetFaxMobile
                type={type}
                handleOnChangeType={handleOnChangeType}
                faxesInboxLength={faxesInboxData.length}
                faxesOutboxLength={faxesOutboxData.length}
              />
            </Hidden>
            <SearchWidget handleChangeFilter={handleChangeFilter} />
          </div>
        </div>
        <div>
          <Hidden xsDown>
            <TableFaxBox
              order={order}
              orderBy={orderBy}
              selected={selected}
              page={page}
              rowsPerPage={rowsPerPage}
              type={type}
              listStatus={listStatus}
              faxesData={handleData()}
              filter={filter}
              faxBox={faxBox}
              handleCheckbox={handleCheckbox}
              makeCheckedFax={makeCheckedFax}
              updateFaxesState={updateFaxesState}
              handleDeleteOneFax={handleDeleteOneFax}
              handleOpenFile={handleOpenFile}
            />
            {!loading && !handleData().length && (
              <EmptyResultBox
                icon='device-fax'
                title={t('faxes.faxBox.empty.title.label')}
                text={t('faxes.faxBox.empty.text.label')}
              />
            )}
          </Hidden>
          <Hidden smUp>
            <TableMobileFaxBox
              loading={loading}
              type={type}
              faxBox={faxBox}
              faxesInboxData={filterFaxes(faxesInboxData, filter, faxBox)}
              faxesOutboxData={filterFaxes(faxesOutboxData, filter, faxBox)}
              filter={filter}
              handleDeleteOneFax={handleDeleteOneFax}
            />
          </Hidden>
        </div>
        <FormFax
          openSendForm={openSendForm}
          handleClose={handleClose}
          faxboxes={faxboxes}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  // let usableBoxes = state.faxes.usableBoxes;
  // let faxBox = usableBoxes.find(element => element.id === props.match.params.faxbox_id) || {};
  // let inboxData = state.faxes.faxes_inbox_data.filter(element => faxBox.caller_id === element.to) || [];
  // let outboxData = state.faxes.faxes_outbox_data.filter(element => faxBox.caller_id === element.from) || [];

  let { auth, faxes } = state;

  const { faxboxById, faxboxes } = faxes;

  const faxBox =
    faxes.faxboxes.find(
      (faxbox) => faxbox.id === props.match.params.faxbox_id
    ) || {};
  const user_data = auth.user_data || {};
  const user_last_seen = user_data.last_seen || {};
  const last_seen_faxbox_inbox_val = KazooSDK.lastSeenFor(
    user_last_seen,
    'faxbox_inbox',
    faxBox.id
  );

  const inboxFaxes = get(
    faxboxById,
    `${faxBox.id || faxBox.faxbox_id}.inbox`,
    []
  );
  const outboxFaxes = get(
    faxboxById,
    `${faxBox.id || faxBox.faxbox_id}.outbox`,
    []
  );
  const newFaxCount = inboxFaxes.filter(
    (fax) => fax.timestamp > last_seen_faxbox_inbox_val
  ).length;

  return {
    faxesInboxData: inboxFaxes,
    faxesOutboxData: outboxFaxes,
    faxboxes,
    faxBox: faxBox,
    newFaxCount,
    // usableBoxes: usableBoxes,
    type: state.faxes.type,
    listStatus: state.faxes.listStatus,
    statusSelected: state.faxes.statusSelected,
    makeCheckedFax: state.faxes.makeCheckedFax,
    order: state.faxes.order,
    orderBy: state.faxes.orderBy,
    page: state.faxes.page,
    rowsPerPage: state.faxes.rowsPerPage,
    from: state.faxes.from,
    to: state.faxes.to,
    typeFilter: state.faxes.typeFilter,
    listStatusFilter: state.faxes.listStatusFilter,
    makeCheckedFaxObject: state.faxes.makeCheckedFaxObject
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchFaxesForFaxbox,
    getFaxes,
    updateFaxBox,
    resetFaxes,
    updateFaxesState,
    updateLastSeen
  })
)(FaxBox);
