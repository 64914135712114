import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';

const persist = persistReducer('devices');

const initialState = {
  register_device: [],
  unregister_device: []
};

const defaultState = persist(initialState, 'get', initialState);

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.SET_DEVICE_DATA:
      // , payload: { register_device, unregister_device }
      return persist({ ...state, ...action.payload });

    case CONSTS.LOGOUT:
      return persist(initialState);
    default:
      return state;
  }
};
