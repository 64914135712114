import makeStyles from '@material-ui/core/styles/makeStyles';

export const rootStyles = makeStyles(theme => ({
  root: {
    '& :focus': {
      outline: 'unset'
    },
    width: 'calc(100% + 24px)',
    minHeight: 'calc(100vh - 100px)',
    margin: -12,
    '& h6': {
      fontSize: 16,
      fontWeight: 600,
      margin: '10px 10px 10px 0'
    },
    '& .statusLabel': {
      borderRadius: 20,
      fontSize: 12,
      padding: '3px 10px 1px',
      textTransform: 'capitalize'
    },
    '& .grayLabel': {
      backgroundColor: theme.palette.textGrey['500'],
      color: theme.palette.white['500']
    },
    '& .redLabel': {
      backgroundColor: theme.palette.red['500']
    },
    '& .greyLabel': {
      backgroundColor: theme.palette.activeGrey['500']
    },
    '& .wrapNone': {
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
      padding: 30,
      '& svg': {
        color: theme.palette.inboundGrey['500'],
        width: 32,
        height: 32
      }
    },
  },
  selectWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '& h3': {
      fontSize: 24,
      margin: '10px 0 0'
    }
  },
  select: {
    padding: 0,
    height: 48,
    minWidth: 150,
    margin: '10px 0 0',
    '& .MuiInputBase-input': {
      fontSize: 24,
      height: '48px !important',
      padding: '15px 40px 15px 20px',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'calc(100vw - 28px)'
      },
    }
  },
  wrapTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& li': {
      padding: 0
    }
  },
  buttonSend: {
    background: theme.palette.iconBlue['500'],
    borderRadius: 2,
    border: `1px solid ${theme.palette.darkBlue['500']}`,
    height: 36,
    minWidth: 120,
    color: theme.palette.darkGrey['500'],
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& .MuiButton-endIcon': {
        margin: '0 8px 0 -4px'
      }
    }
  },
  wrapMid: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    float: 'right',
    '& .wrapMidFlex': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: '100%'
      }
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'start'
    }
  },
  wrapSelectStatus: {
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    '& > span > svg': {
      margin: '0 10px',
      cursor: 'pointer',
      opacity: '0.7',
      transition: '0.5s',
      '&:hover': {
        opacity: '1',
      }
    }
  },
  buttonAction: {
    height: 38,
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, ${theme.palette.sidebarText['500']} 100%)`,
    borderRadius: 2,
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    margin: '10px 0 20px 8px',
    minWidth: 38,
    color: theme.palette.textGrey['500'],
    transition: '0.5s',
    '& span': {
      width: 'auto',
      '& svg': {
        width: 16
      }
    },
    '&:hover': {
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderColor: theme.palette.black['500'],
      color: theme.palette.black['500']
    }
  },
  buttonReSend: {
    marginLeft: 16,
    transform: 'rotateY(180deg)'
  },

}));

export const useStylesTable = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary['500']
    }
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tableWrapper: {
    overflowX: 'visible',
    '& .MuiTableRow-hover': {
      transition: '0.5s'
    },
    '& .MuiTableRow-hover:hover, .Mui-selected': {
      backgroundColor: theme.palette.white['500'],
      boxShadow:
        `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']},
      inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 1px 4px 0px ${theme.palette.inboundGrey['500']}`,
    },
    '& .MuiTableCell-head .MuiTableSortLabel-root': {
      color: theme.palette.textGrey['500'],
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 600
    },
    '& .MuiTableCell-head': {
      paddingBottom: 0
    }
  },
  cellHeaderTable: {
    width: '25%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  wrapperCell: {
    verticalAlign: 'top',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  wrapperAction: {
    flexDirection: 'row !important',
    marginTop: 10,
  },
  buttonAction: {
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%,
      ${theme.palette.sidebarText['500']} 100%)`,
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 'unset',
    width: 42,
    height: 42,
    color: theme.palette.textGrey['500'],
    transition: '0.5s',
    boxSizing: 'border-box',
    padding: 11,
    marginRight: 10,
    borderRadius: '100%',
    '& span': {
      width: 'auto',
      '& svg': {
        width: 16,
        height: 16,
        color: theme.palette.iconBlue['500'],
      }
    },
    '&:hover': {
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderColor: theme.palette.textGrey['500'],
      color: theme.palette.black['500']
    },
  },
  wrapperButtonsAction: {
    '& button:first-child span svg': {
      color: theme.palette.voicemailIconGreen['500'],
    },
    '& button:nth-child(2) span svg': {
      color: theme.palette.iconBlue['500'],
    },
    '& button:last-child span svg': {
      color: theme.palette.red['500'],
    }
  },
  wrapAudio: {
    marginBottom: 20
  },
  wrapperItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    '& span:nth-child(2)': {
      color: theme.palette.textGrey['500'],
      fontSize: 12
    }
  },
  wrapperItemRow: {
    flexDirection: 'row !important',
    alignItems: 'center'
  },
  iconSort: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'scale(0.6, 0.9)',
    '& > svg:first-child': { transform: 'translateY(8px)' },
    '& > svg:last-child': { transform: 'translateY(-8px)' }
  },
  pagination: {
    marginTop: 20,
    '& .MuiTablePagination-caption': {
      padding: '0 32px 0 0',
      fontWeight: 'bold'
    },
    '& .MuiTablePagination-input': {
      backgroundColor: theme.palette.white['500'],
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 5px 5px 15px',
      '& .MuiInputBase-inputSelect': {
        marginBottom: 3,
        color: theme.palette.selectBlack['500'],
        fontSize: 14,
        padding: '6px 8px 7px 8px',
        marginRight: -20,
        '&:focus': {
          backgroundColor: theme.palette.white['500']
        }
      },
      '&>.MuiSelect-select::before': {
        content: "'View '"
      },
      '&>.MuiSelect-select::after': {
        content: "' per page'",
        paddingRight: 40
      },
      '& svg': {
        position: 'relative'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTablePagination-toolbar ': {
        display: 'block'
      },
      '& .MuiTablePagination-selectRoot': {
        marginBottom: 20,
        width: 200,
        marginLeft: 0
      },
      '& .MuiTablePagination-caption': {
        float: 'left'
      }
    }
  },
  pstRelative: {
    position: 'relative',
    verticalAlign: 'top',
  },
  buttonHidden: {
    position: 'absolute',
    borderRadius: '100%',
    right: 0,
    top: 0,
    minWidth: 20,
    '&:hover': {
      backgroundColor: theme.palette.white['500']
    }
  },
  selectedRow: {
    backgroundColor: theme.palette.white['500'],
    boxShadow: 'none',
    '& th,td': {
      borderBottom: 'none'
    }
  },
  selectedRowTop: {
    boxShadow:
      `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 1px 4px 0px ${theme.palette.inboundGrey['500']} !important`,
  },
  selectedRowBot: {
    '& th,td,p': {
      paddingTop: 0
    },
    '& p': {
      margin: 0
    },
    verticalAlign: 'top',
    boxShadow:
      `inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 3px 4px 0px ${theme.palette.inboundGrey['500']}`,
  }
}));

export const useStylesPagination = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    margin: 0,
    '& button': {
      background:
        `linear - gradient(-180deg, ${theme.palette.white['500']} 0 %,
    ${ theme.palette.sidebarText['500']} 100 %)`,
      color: theme.palette.textGrey['500'],
      fontSize: 14,
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderRadius: 0,
      height: 38,
      padding: '6px 10px',
      minWidth: 38,
      '&:first-child': {
        borderRadius: '2px 0 0 2px'
      },
      '&:last-child': {
        borderRadius: '0 2px 2px 0'
      }
    }
  },
  activeButton: {
    background: `${theme.palette.inboundGrey['500']}!important`,
    border: `1px solid ${theme.palette.inboundGrey['500']}!important`,
    color: `${theme.palette.grey['500']}!important`
  }
}));


export default { rootStyles, useStylesTable, useStylesPagination }
