import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container as MuiContainer,
  Divider as MuiDivier,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  FormControlLabel as MuiFormControlLabel,
  List as MuiList,
  ListItem as MuiListItem
} from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      margin: '0 -16px'
    }
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    marginBottom: '32px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  typography: {
    color: theme.palette.darkGrey['500']
  },
  userFullName: {
    display: 'none',
    color: theme.palette.darkGrey['500'],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  sectionTitle: {
    display: 'none',
    fontWeight: 600,
    color: theme.palette.darkGrey['500'],
    marginBottom: 24,
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  doNotDisturbHeader: {
    paddingBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px'
    }
  },
  doNotDisturbTitle: {
    display: 'inherit',
    fontWeight: 600,
    marginBottom: 16
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  grow: {
    dispplay: 'flex',
    flexGrow: 1
  },
  doNotDisturbDevices: {
    borderBottom: '1px solid rgb(188, 188, 194)'
  },
  accessTitle: {
    display: 'inherit',
    fontWeight: 600,
    marginBottom: 16
  },
  appIcon: {
    width: 64,
    height: 64,
    marginRight: 16
  },
  appVersionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24
  },
  appVersionInfo: {
    height: 64
  },
  appVersionInfoTitle: {
    lineHeight: '32px',
    fontWeight: 600
  },
  appVersionInfoSubtitle: {
    lineHeight: '32px'
  }
}));

export const Container = withStyles({
  root: {
    marginLeft: 0,
    padding: 0
  }
})(MuiContainer);

export const Divider = withStyles({
  root: {
    backgroundColor: '#fafafa',
    height: 24
  }
})(MuiDivier);

export const ExpansionPanel = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    border: '1px solid rgb(188, 188, 194)',
    borderLeft: 0,
    borderRight: 0,
    '&:last-child': {
      borderRadius: 0
    },
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    '&$expanded': {
      minHeight: 48
    },
    cursor: 'default !important'
  },
  content: {
    height: 20,
    marginTop: 14,
    marginBottom: 14,
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0 16px 12px'
  }
})(MuiExpansionPanelDetails);

export const FormControlLabelWithSwitch = withStyles({
  root: {
    width: '100%',
    marginLeft: 16,
    marginRight: 4,
    justifyContent: 'space-between',
    cursor: 'default'
  }
  // label: {
  //   cursor: 'default !important'
  // }
})(MuiFormControlLabel);

export const FormControlLabelCheckbox = withStyles({
  root: {
    cursor: 'default'
  }
})(MuiFormControlLabel);

export const DevicesList = withStyles({
  root: {
    padding: 0
  }
})(MuiList);

export const DevicesListItem = withStyles({
  root: {
    padding: '5px 0',
    borderTop: '1px solid rgb(188, 188, 194)'
  }
})(MuiListItem);

export const SoftwareAccessList = withStyles({})(MuiList);
