import React, { useReducer } from 'react';
import useStyles from './styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import { KazooSDK } from '@KazooSDK';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Trans, useTranslation } from 'react-i18next';

export const FormFax = (props) => {
  const { faxboxes, openSendForm, handleClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const initState = {
    sendTo: '',
    sendFrom: 'none',
    uploadFile: ''
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { sendTo, sendFrom, uploadFile } = state;

  const craftMessage = (base64Document, metadata) => {
    const boundary = 'multipart-message-boundary';
    let message = '';
    let contentLength = 0;
    try {
      contentLength = atob(base64Document.split(',')[1]).length;
    } catch (e) {
      // if pass an empty pdf will err
      contentLength = 0;
    }
    const bodyParts = [
      {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: metadata
        })
      },
      {
        headers: {
          'Content-Type': 'application/pdf',
          'Content-Length': contentLength,
          'Content-Transfer-Encoding': 'base64'
        },
        body: base64Document.replace(/\s/g, '')
      }
    ];
    bodyParts.forEach(function (part) {
      message += '--' + boundary + '\r\n';
      let header;
      for (header in part.headers) {
        message += header + ': ' + part.headers[header] + '\r\n';
      }
      message += '\r\n' + part.body + '\r\n';
    });
    return message + '--' + boundary + '--';
  };

  const handleChangeInput = (e) =>
    setState({ [e.target.name]: e.target.value });

  const handleChangeInputFile = (e) => {
    let FileSize = e.target.files[0].size / 1024 / 1024;
    if (FileSize > 15) {
      alert(t('faxes.faxForm.alerts.sizeExceeds.label'));
    } else {
      setState({ [e.target.name]: e.target.files[0] });
    }
  };

  const handleClear = () => setState(initState);

  const handleCancel = () => {
    setState(initState);
    handleClose();
  };

  const handleUploadFile = (e) => {
    if (sendTo && sendFrom && sendFrom !== 'none' && uploadFile) {
      e.preventDefault();
      const { sendTo, sendFrom, uploadFile } = state;
      const phoneNumber = parsePhoneNumberFromString(sendTo);
      const to = phoneNumber ? phoneNumber.formatInternational() : sendTo;
      let reader = new FileReader();
      reader.readAsDataURL(uploadFile);
      reader.onload = () => {
        let b64data = reader.result;
        let json = {
          from_number: sendFrom,
          to_number: to.replace(/ /g, ''),
          retries: 3
        };
        let data = craftMessage(b64data, json);
        KazooSDK.uploadFax(data)
          .then((res) => {
            if (res.data.status === 'success') {
              toast.success(t('faxes.faxForm.alerts.faxQueued.label'), {
                position: toast.POSITION.TOP_RIGHT
              });
              handleClose();
            }
          })
          .catch((err) => {
            toast.error(err.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          });
        reader.onerror = function (error) {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        };
      };
    } else {
      toast.error(t('faxes.faxForm.error.fillForm.label'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={openSendForm}
      onClose={handleCancel}
      className={classes.root}
    >
      <div className={classes.paper}>
        <div className={classes.head}>
          <h3>{t('faxes.faxForm.title.label')}</h3>
          <span
            aria-hidden
            className={classes.cancelIcon}
            onClick={handleClose}
          >
            <CancelIcon />
          </span>
        </div>
        <div className={classes.main}>
          <FormControl variant='outlined' className={classes.wrapElement}>
            <p>
              <strong>{t('faxes.faxForm.sendTo.label')}</strong>
            </p>
            <OutlinedInput
              id='send-to'
              value={sendTo}
              name='sendTo'
              onChange={(e) => handleChangeInput(e)}
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight'
              }}
              labelWidth={0}
              placeholder='+14158862600'
            />
          </FormControl>
          <FormControl variant='outlined' className={classes.wrapElement}>
            <p>
              <strong>{t('faxes.faxForm.sendFrom.label')}</strong>
            </p>
            <Select
              className={classes.select}
              id='send-from-input'
              name='sendFrom'
              defaultValue='none'
              value={sendFrom}
              onChange={(e) => handleChangeInput(e)}
            >
              <MenuItem value='none' disabled>
                {t('faxes.faxForm.sendFrom.placeholder')}
              </MenuItem>
              {faxboxes.map((element) => (
                <MenuItem key={element.id} value={element.caller_id}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='outlined' className={classes.wrapElement}>
            <p>
              <strong>{t('faxes.faxForm.uploadFile.label')}</strong>
            </p>
            <div className={classes.uploadFile}>
              <div className={classes.listFile}>
                {uploadFile && <p>{uploadFile.name}</p>}
                {!uploadFile && (
                  <p className='placeHolder'>
                    {t('faxes.faxForm.noFile.label')}
                  </p>
                )}
              </div>
              <label className={classes.buttonUpload} htmlFor='upload'>
                <span aria-hidden='true'>
                  {t('faxes.faxForm.chooseFile.label')}
                </span>
                <input
                  name='uploadFile'
                  type='file'
                  id='upload'
                  onChange={(e) => {
                    handleChangeInputFile(e);
                  }}
                  accept='image/*,.pdf,.doc,.docx,.xml,.csv,.xls, .xlsx'
                />
              </label>
            </div>
            <p>
              <Trans i18nKey='faxes.faxForm.restrictions.label' />
            </p>
          </FormControl>
        </div>
        <div className={classes.footer}>
          <Button onClick={handleCancel}>
            {t('faxes.faxForm.cancel.label')}
          </Button>
          <Button variant='outlined' onClick={handleClear}>
            {t('faxes.faxForm.clearForm.label')}
          </Button>
          <Button
            variant='outlined'
            className={classes.sendButton}
            onClick={(e) => handleUploadFile(e)}
          >
            {t('faxes.faxForm.sendFax.label')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FormFax;
