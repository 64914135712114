import { KazooSDK } from '@KazooSDK';
import isElectron from 'is-electron';

export const vertoLauncher = async (conference, numbers) => {
  // TODO: have the conference and numbers auto-updating
  // - push into verto from desktop-application (shared redux state?)
  //   - have a react component below storage Provider that handles pushing to Verto page
  let configJSON;
  try {
    const response = await KazooSDK.getConferenceVertoConfig(conference.id);
    configJSON = response.data.data;
  } catch (err) {
    alert('Unable to launch conference, please try again');
    return false;
  }

  //   configJSON.autocall = `Conference-ID:${conference.id}`;
  configJSON.title = conference.name;
  configJSON.profile_name = 'video';
  configJSON.dialIn = numbers;
  configJSON.pins = conference.conference_numbers;

  const config = btoa(JSON.stringify(configJSON));

  if (isElectron()) {
    window.commio.conferences.openVertoConference(config);
  }
};
