import React, { useState, useCallback } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { List, ListItem, Button, Modal, Grid } from '@material-ui/core';
import { useStylesTableMobile, useModalStyles } from './styles';
import { Telicon } from '../Icons/index';
import {
  gregorianToEpoch,
  getPhoneNumber,
  getFaxResult
} from '@KazooSDK/utils';
import { KazooSDK } from '@KazooSDK';
// import AllPages from './AllPages'
import { Document, Page } from 'react-pdf/dist/entry';
// import { toast } from '@Utils/toast';
import { useTranslation } from 'react-i18next';
import { EmptyResultBox } from '../Common/EmptyResultBox';

const ViewModal = (props) => {
  const { modalOpen, setModalOpen, fax, loadDocument } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const pdfUrl = KazooSDK.getFaxUrl(fax);

  const modalClasses = useModalStyles();
  const { t } = useTranslation();

  return (
    <Modal open={modalOpen}>
      <Grid
        container
        direction='column'
        justify='flex-start'
        alignItems='stretch'
        className={modalClasses.root}
      >
        <Grid
          className={modalClasses.close}
          item
          onClick={() => setModalOpen(false)}
        >
          {t(`faxes.faxBox.misc.close.label`)}
        </Grid>
        <Grid
          className={modalClasses.pageNav}
          item
          // onClick={()=>setModalOpen(false)}
          // onClick={()=>{setPageNumber(pageNumber + 1)}}
        >
          <Grid container justify='space-around' alignItems='center'>
            <Grid item xs={1}>
              <div style={{ minWidth: 10 }}>
                {pageNumber > 1 && (
                  <div
                    onClick={() => {
                      setPageNumber(pageNumber - 1);
                    }}
                  >
                    &lt;
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={10}
              onClick={() => {
                setPageNumber(
                  pageNumber < getFaxResult(fax).total_pages
                    ? pageNumber + 1
                    : 1
                );
              }}
            >
              Page {pageNumber}/{getFaxResult(fax).total_pages}
            </Grid>
            <Grid item xs={1}>
              <div style={{ minWidth: 10 }}>
                {pageNumber && pageNumber < getFaxResult(fax).total_pages && (
                  <div
                    onClick={() => {
                      setPageNumber(pageNumber + 1);
                    }}
                  >
                    &gt;
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={modalClasses.page}>
          <div>
            {loadDocument && (
              <Document
                className={modalClasses.document}
                file={pdfUrl}
                loading={t('faxes.loading.pdf.label')}
              >
                <Page pageNumber={pageNumber} loading={t('faxes.loading.label')} />
                {/* {
                  range(5).map(i=>(
                    <Page
                      key={i+1}
                      pageNumber={i+1}
                    />
                  ))
                } */}
              </Document>
            )}
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

const FaxItem = (props) => {
  const {
    fax,
    typeFax,
    // faxBox,
    handleDeleteOneFax,
    selected,
    setSelected
  } = props;

  const classes = useStylesTableMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const pdfUrl = KazooSDK.getFaxUrl(fax);

  const handleClick = (id) => setSelected(id);

  const interjectHandleDeleteOneFax = useCallback(
    (...args) => {
      // toast({
      //   type: 'success',
      //   title: t('success.label'),
      //   message: 'Moved to Trash'
      // });
      handleDeleteOneFax(...args);
    },
    [handleDeleteOneFax]
  );

  let isSelected = selected === fax.id;

  const getStatus = (status) => (
    <label
      className={`statusLabel 
        ${
    status === 'failed'
      ? 'redLabel'
      : status === 'pending' || status === 'processing'
        ? 'grayLabel'
        : 'greyLabel'
    }`}
    >
      {status}
    </label>
  );

  const cancelSelected = useCallback(
    (e) => {
      if (isSelected) {
        e.stopPropagation();
        setSelected(null);
      }
    },
    [setSelected, isSelected]
  );

  return (
    <ListItem
      key={fax.id}
      className={classNames([classes.wrapperItem], {
        [classes.selectedItem]: isSelected
      })}
      onClick={() => {
        handleClick(fax.id);
      }}
    >
      <ViewModal
        fax={fax}
        loadDocument={modalOpen && isSelected}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <div className={classes.item} onClick={cancelSelected}>
        <div className={classes.wrapperLabel}>
          {typeFax === 'received' ? (
            <label className='statusLabel greyLabel'>Received</label>
          ) : (
            getStatus(fax.status)
          )}
        </div>
        <div className={classes.wrapperContact}>
          <div>
            {typeFax === 'received' ? (
              <>
                <p>{fax.from}</p>
                <p>{fax.from_number}</p>
              </>
            ) : (
              <>
                {fax.to && <p>{getPhoneNumber(fax.to)}</p>}
                <p>{getPhoneNumber(fax.to_number)}</p>
              </>
            )}

            <br />
            <p>{moment.utc(gregorianToEpoch(fax.created) * 1000).fromNow()}</p>
          </div>
        </div>
        <p className={classes.pageLabel}>
          {getFaxResult(fax).total_pages > 1
            ? `${getFaxResult(fax).total_pages} pages`
            : `${getFaxResult(fax).total_pages} page`}{' '}
        </p>
      </div>
      {isSelected && (
        <div className={classes.wrapperAction}>
          <Button
            className={classes.buttonAction}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <Telicon name='eye' />
          </Button>
          {navigator.platform.indexOf('iPhone') === -1 && (
            <Button className={classes.buttonAction}>
              <a href={pdfUrl}>
                <Telicon name='download-cloud' />
              </a>
            </Button>
          )}

          <Button
            className={classes.buttonAction}
            onClick={() => interjectHandleDeleteOneFax(fax.folder, fax.id)}
          >
            <Telicon name='trash' />
          </Button>
        </div>
      )}

      {/* {isSelected && (
        <Button
          className={classes.buttonHidden}
          onClick={e => {
            e.stopPropagation();
            setSelected(null);
          }}
          aria-hidden
        >
          <HiddenIcon />
        </Button>
      )} */}
    </ListItem>
  );
};

const TableMobileFaxBox = (props) => {
  const { faxesInboxData, faxesOutboxData, type } = props;

  const classes = useStylesTableMobile();

  const [selected, setSelected] = useState(null);

  // const handleClick = (id) => setSelected(id)

  const NoneData = ({ type }) => {
    const { t } = useTranslation();
    return (
      <Grid container>
        <EmptyResultBox
          icon={'device-fax'}
          title={t('faxes.faxBox.empty.title.label')}
          text={t('faxes.faxBox.empty.text.label')}
        />
      </Grid>
    );
  };

  // const getStatus = (status) =>
  //   (
  //     <label className={
  //       `statusLabel
  //       ${status === 'failed' ? 'redLabel' :
  //         (status === 'pending' || status === 'processing') ? 'grayLabel' : 'greyLabel'}`}
  //     >{status}</label>
  //   )

  if ((type === 'received' || !type) && !faxesInboxData.length) {
    return <NoneData type='received' />;
  }

  if (type === 'sent' && !faxesOutboxData.length) {
    return <NoneData type='sent' />;
  }

  return (
    <List className={classes.root}>
      {type === 'received' || !type ? (
        !faxesInboxData.length ? (
          <NoneData type='received' />
        ) : (
          faxesInboxData.map((fax) => (
            <FaxItem
              key={fax.id}
              fax={fax}
              typeFax='received'
              selected={selected}
              setSelected={setSelected}
              {...props}
            />
          ))
        )
      ) : !faxesOutboxData.length ? (
        <NoneData type='sent' />
      ) : (
        faxesOutboxData.map((fax) => (
          <FaxItem
            key={fax.id}
            fax={fax}
            typeFax='sent'
            selected={selected}
            setSelected={setSelected}
            {...props}
          />
        ))
      )}
    </List>
  );
};

export default TableMobileFaxBox;
