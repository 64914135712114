export const LOGOUT = 'LOGOUT';
export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA';
export const SET_VOICEMAIL_DATA = 'SET_VOICEMAIL_DATA';
export const SET_CALL_DATA_LOADING = 'SET_CALL_DATA_LOADING';
export const SET_CALL_DATA = 'SET_CALL_DATA';
export const RESET_CALL_DATA = 'RESET_CALL_DATA';
export const SET_DEVICE_DATA = 'SET_DEVICE_DATA';
export const SET_FAXES_DATA = 'SET_FAXES_DATA';
export const SET_FAXBOXES = 'SET_FAXBOXES';
export const SET_FAXBOX_FAXES = 'SET_FAXBOX_FAXES';
export const SET_FAXBOXES_LOADING = 'SET_FAXBOXES_LOADING';
export const SET_FAXBOX_FAXES_LOADING = 'SET_FAXBOX_FAXES_LOADING';

export const GET_FAXES = 'GET_FAXES';
export const GET_FAX_BOXES = 'GET_FAX_BOXES';
export const RESET_FAXES = 'RESET_FAXES';
export const UPDATE_FAXES_STATE = 'UPDATE_FAXES_STATE';

export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const RESET_VOICEMAIL = 'RESET_VOICEMAIL';
export const SET_VOICEMAIL_TRANSCRIPTION = 'SET_VOICEMAIL_TRANSCRIPTION';
export const UPDATE_VOICEMAIL_STATE = 'UPDATE_VOICEMAIL_STATE';
export const CHANGE_VOICEMAIL_STATE = 'CHANGE_VOICEMAIL_STATE';
export const SET_VMBOXES_LOADING = 'SET_VMBOXES_LOADING';
export const SET_VMBOXES = 'SET_VMBOXES';
export const SET_VMBOX_MESSAGES = 'SET_VMBOX_MESSAGES';
export const SET_VMBOX_LOADING = 'SET_VMBOX_LOADING';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_CONFERENCES_LOADING = 'SET_CONFERENCES_LOADING';
export const SET_CONFERENCE_VERTO_CONFIG_LOADING =
  'SET_CONFERENCE_VERTO_CONFIG_LOADING';
export const SET_CONFERENCES = 'SET_CONFERENCES';
export const SET_CONFERENCE_VERTO_CONFIG = 'SET_CONFERENCE_VERTO_CONFIG';
export const SET_CONFERENCES_CALL_IN_NUMBERS =
  'SET_CONFERENCES_CALL_IN_NUMBERS';

export const TOGGLE_DO_NOT_DISTURB = 'TOGGLE_DO_NOT_DISTURB';
export const ADD_DEVICE = 'ADD_DEVICE';
export const DEVICE_UPDATING = 'DEVICE_UPDATING';
export const DEVICE_UPDATED = 'DEVICE_UPDATED';
export const SET_USER_SETTINGS_DATA = 'SET_USER_SETTINGS_DATA';
export const SAVE_USER_EMAIL = 'SAVE_USER_EMAIL';
export const USER_UPDATING = 'USER_UPDATING';
export const USER_UPDATED = 'USER_UPDATED';
export const TOGGLE_CALL_FORWARDING = 'TOGGLE_CALL_FORWARDING';
export const TOGGLE_KEEP_CALLER_ID = 'TOGGLE_KEEP_CALLER_ID';
export const SAVE_CALL_FORWARDING_NUMBER = 'SAVE_CALL_FORWARDING_NUMBER';
export const TOGGLE_SEND_VM_TO_EMAIL = 'TOGGLE_SEND_VM_TO_EMAIL';
export const TOGGLE_RING_MY_OTHER_DEVICES = 'TOGGLE_RING_MY_OTHER_DEVICES';

export const SET_SETTINGS_LOADING = 'SET_SETTINGS_LOADING';
