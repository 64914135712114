import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 281
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    // width: 62,
    cursor: 'pointer'
  },
  topRow: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  secondRow: {
    display: 'flex',
    alignItems: 'center'
  },
  totalText: {
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal',
    letterSpacing: 0
  },
  total: {
    fontSize: theme.spacing(4),
    fontWeight: 'normal',
    marginRight: theme.spacing(1)
  },
  usageText: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(22),
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal',
    letterSpacing: 0
  },
  bottomRow: {
    // display: 'flex'
  },
  listContainer: {
    // maxHeight: 200,
    // // width: 200,
    // overflow: 'auto'
  },
  list: {
    maxHeight: 176,
    overflow: 'auto'
  },
  selected: {
    // width: 200,
    borderRadius: '2px 0px 0px 2px',
    boxShadow:
      'inset -3px 0px 0px 0px rgb(34, 151, 255),0px 1px 4px 0px rgba(32, 32, 41, 0.24)'
  },
  circleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 200
  },
  progressBar: {
    height: 96,
    width: 96
  },
  percentContainer: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  selectedPercent: {
    fontSize: theme.spacing(4)
  },
  selectedCount: {
    color: theme.palette.textGrey['500'],
    fontSize: theme.spacing(2) - 4,
    fontWeight: 'normal'
  }
}));

export default useStyles;
