import { makeStyles } from '@material-ui/core/styles';

export const useStylesTableDesktop = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary['500']
    }
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tableWrapper: {
    overflowX: 'visible',
    '& .MuiTableRow-hover': {
      transition: '0.5s'
    },
    '& .MuiTableRow-hover:hover, .Mui-selected': {
      backgroundColor: theme.palette.white['500'],
      boxShadow: `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']},
      inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 1px 4px 0px ${theme.palette.inboundGrey['500']}`
    },
    '& .MuiTableCell-head .MuiTableSortLabel-root': {
      color: theme.palette.textGrey['500'],
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 600
    },
    '& .MuiTableCell-head': {
      paddingBottom: 0
    }
  },
  cellHeaderTable: {
    width: '25%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  wrapperCell: {
    verticalAlign: 'top',
    '& > div': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  wrapperAction: {
    flexDirection: 'row !important',
    marginTop: 10
  },
  buttonAction: {
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%,
      ${theme.palette.sidebarText['500']} 100%)`,
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 'unset',
    width: 42,
    height: 42,
    color: theme.palette.textGrey['500'],
    transition: '0.5s',
    boxSizing: 'border-box',
    padding: 11,
    marginRight: 10,
    borderRadius: '100%',
    '& span': {
      width: 'auto',
      '& svg': {
        width: 16,
        height: 16,
        color: theme.palette.iconBlue['500']
      }
    },
    '&:hover': {
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderColor: theme.palette.textGrey['500'],
      color: theme.palette.black['500']
    }
  },
  wrapperButtonsAction: {
    '& button:first-child span svg': {
      color: theme.palette.voicemailIconGreen['500']
    },
    '& button:nth-child(2) span svg': {
      color: theme.palette.iconBlue['500']
    },
    '& button:last-child span svg': {
      color: theme.palette.red['500']
    }
  },
  wrapAudio: {
    marginBottom: 20
  },
  wrapperItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    '& span:nth-child(2)': {
      color: theme.palette.textGrey['500'],
      fontSize: 12
    }
  },
  wrapperItemRow: {
    flexDirection: 'row !important',
    alignItems: 'center'
  },
  iconSort: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'scale(0.6, 0.9)',
    '& > svg:first-child': { transform: 'translateY(8px)' },
    '& > svg:last-child': { transform: 'translateY(-8px)' }
  },
  pagination: {
    marginTop: 20,
    '& .MuiTablePagination-caption': {
      padding: '0 32px 0 0',
      fontWeight: 'bold'
    },
    '& .MuiTablePagination-input': {
      backgroundColor: theme.palette.white['500'],
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 5px 5px 15px',
      '& .MuiInputBase-inputSelect': {
        marginBottom: 3,
        color: theme.palette.selectBlack['500'],
        fontSize: 14,
        padding: '6px 8px 7px 8px',
        marginRight: -20,
        '&:focus': {
          backgroundColor: theme.palette.white['500']
        }
      },
      '&>.MuiSelect-select::before': {
        content: "'View '"
      },
      '&>.MuiSelect-select::after': {
        content: "' per page'",
        paddingRight: 40
      },
      '& svg': {
        position: 'relative'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTablePagination-toolbar ': {
        display: 'block'
      },
      '& .MuiTablePagination-selectRoot': {
        marginBottom: 20,
        width: 200,
        marginLeft: 0
      },
      '& .MuiTablePagination-caption': {
        float: 'left'
      }
    }
  },
  pstRelative: {
    position: 'relative',
    verticalAlign: 'top'
  },
  buttonHidden: {
    position: 'absolute',
    borderRadius: '100%',
    right: 0,
    top: 0,
    minWidth: 20,
    '&:hover': {
      backgroundColor: theme.palette.white['500']
    }
  },
  selectedRow: {
    backgroundColor: theme.palette.white['500'],
    boxShadow: 'none',
    '& th,td': {
      borderBottom: 'none'
    }
  },
  selectedRowTop: {
    boxShadow: `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 1px 4px 0px ${theme.palette.inboundGrey['500']} !important`
  },
  selectedRowBot: {
    '& th,td,p': {
      paddingTop: 0
    },
    '& p': {
      margin: 0
    },
    verticalAlign: 'top',
    boxShadow: `inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']},
      0px 3px 4px 0px ${theme.palette.inboundGrey['500']}`
  }
}));

export const useStylesTableMobile = makeStyles(theme => ({
  root: {
    fontSize: 14,
    overflow: 'auto',
    paddingBottom: 10,
    marginBottom: '46px !important',
    margin: '0 -16px',
    width: '100vw',
    '& li': {
      padding: '0 16px 8px'
    }
  },
  rootExplain: {
    // // margin: '0 0 0 -24px',
    // marginLeft: '-24px',
    // width: 'calc(100% + 48px)',
    // '& li': {
    //   margin: '0 16px 8px',
    //   width: 'calc(100% - 32px)'
    // }
  },
  wrapperItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    boxShadow: `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']}`,
    '&:last-of-type': {
      boxShadow: `inset 0px - 1px 0px 0px ${theme.palette.inboundGrey['500']},
      inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']}`,
      marginBottom: 20
    }
  },
  wrapperWeight: {
    fontWeight: 600
  },
  wrapperSmall: {
    fontSize: 12,
    color: theme.palette.textGrey['500']
  },
  selectedItem: {
    backgroundColor: theme.palette.white['500']
    // width: '100% !important',
    // padding: '0 15px 10px',
    // margin: '0 0 -1px !important',
    // boxShadow: `inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']}`,
  },
  item: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '12px 0'
  },
  wrapperLabel: {
    width: 80,
    marginTop: 10
  },
  folderLabel: {
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 10,
    // backgroundColor: props => {
    //   return theme.palette.activeGrey['500'];
    //   // switch (props.message.folder) {
    //   //   case 'saved':
    //   //   case 'deleted':
    //   //     return theme.palette.activeGrey['500'];
    //   //   case 'new':
    //   //   default:
    //   //     return theme.palette.voicemailIconGreen['500'];
    //   // }
    // },
    padding: '2px 8px'
  },
  greenLabel: {
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 10,
    backgroundColor: theme.palette.voicemailIconGreen['500'],
    padding: '2px 8px'
  },
  grayLabel: {
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 10,
    backgroundColor: theme.palette.activeGrey['500'],
    padding: '2px 8px'
  },
  wrapperContact: {
    width: 'calc(50% - 40px)',
    display: 'flex',
    '& svg': {
      margin: '12px 24px 10px 0'
    },
    '& p': {
      margin: 0
    },
    '& div > p:first-child': {
      color: theme.palette.darkGrey['500'],
      fontWeight: 'bold',
      fontSize: 14
    },
    '& div > p:nth-child(2)': {
      fontSize: 12,
      color: theme.palette.textGrey['500']
    },
    '& div > p:last-child': {
      fontSize: 14,
      color: theme.palette.darkGrey['500'],
      marginTop: 10
    }
  },
  wrapperRight: {
    width: 'calc(50% - 40px)',
    textAlign: 'right',
    color: theme.palette.darkGrey['500'],
    paddingRight: 5,
    '& p': {
      margin: 0
    }
  },
  wrapperAction: {
    flexDirection: 'row !important',
    margin: '5px 0 10px'
  },
  buttonAction: {
    background: `linear - gradient(-180deg, ${theme.palette.white['500']} 0 %, ${theme.palette.sidebarText['500']} 100 %)`,
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    minWidth: 'unset',
    width: 40,
    height: 40,
    color: theme.palette.textGrey['500'],
    transition: '0.5s',
    boxSizing: 'border-box',
    padding: 11,
    marginRight: 10,
    borderRadius: '100%',
    '& span': {
      width: 'auto',
      '& svg': {
        width: 16,
        height: 16,
        color: theme.palette.iconBlue['500']
      }
    },
    '&:hover': {
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      borderColor: theme.palette.textGrey['500'],
      color: theme.palette.black['500']
    }
  },
  buttonRemove: {
    display: 'none'
  },
  buttonHidden: {
    position: 'absolute',
    right: 0,
    bottom: 8,
    '&:hover': {
      backgroundColor: theme.palette.white['500']
    }
  },
  wrapperButtonsAction: {
    '& button:first-child span svg': {
      color: theme.palette.voicemailIconGreen['500']
    },
    '& button:nth-child(2) span svg': {
      color: theme.palette.iconBlue['500']
    },
    '& button:nth-child(3) span svg': {
      color: theme.palette.red['500']
    }
  },
  wrapAudio: {
    width: '100%'
  }
}));

export default makeStyles(theme => ({
  transcriptionBox: {
    marginTop: theme.spacing(1),
    marginLeft: 0
  }
}));
