import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import prepareHomeState from '../../../../Selectors/Home';
// import { PhoneMissed as PhoneMissedIcon } from '../../../Icons';

import { CountWidget } from '../../../Common/CountWidget';

export const CallCount = props => {
  const { missedCallCount, recentCallData, calls } = props;

  const { t } = useTranslation();

  return missedCallCount ? (
    <CountWidget
      type="error"
      count={missedCallCount}
      label={t('callHistory.missedCalls.label')}
      routePath="/user_portal/call_history?filter=missed"
      icon={'phone-missed'}
      isLoading={calls.isLoading}
    />
  ) : (
    <CountWidget
      type="default"
      count={recentCallData.length}
      label={t('callHistory.callsToday.label')}
      routePath="/user_portal/call_history"
      icon={'phone-missed'}
      isLoading={calls.isLoading}
    />
  );
};

const mapStateToProps = prepareHomeState;

export default connect(mapStateToProps)(CallCount);
