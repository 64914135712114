import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import useStyles from './styles';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Telicon } from '../../Icons';
import { Image } from '../../Common/Image';

import prepareDevicesAndNumbersState from '../../../Selectors/DevicesAndNumbers';

const DeviceCard = props => {
  const { device } = props;
  // const { t } = useTranslation();

  const classes = useStyles(props);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper className={classes.box}>
        <div className={classes.squareContainer}>
          {device.device_type === 'sip_device' && (
            <div>
              <Telicon
                name="device-voip-phone"
                className={classes.registeredIcon}
              />
              <Image src="/desk.png" alt="device" />
            </div>
          )}
          {device.device_type === 'cellphone' && (
            <div>
              <Telicon
                name="device-mobile"
                className={classes.registeredIcon}
              />
              <Image src="/cell.png" alt="device" />
            </div>
          )}
          {device.device_type === 'softphone' && (
            <div>
              <Telicon
                name="device-soft-phone"
                className={classes.registeredIcon}
              />
              <Image src="/device-soft.png" alt="device" />
            </div>
          )}
          {device.device_type === 'application' && (
            <div>
              <Telicon
                name="device-sprint-phone"
                className={classes.registeredIcon}
              />
              <Image src="/application.png" alt="device" />
            </div>
          )}
          <div className="mt-2 name">
            {device.name.length > 50 ? device.name.substr(0, 50) : device.name}
          </div>
          <div className="number">{device.mac_address}</div>
        </div>
      </Paper>
    </Grid>
  );
};

export const DeviceList = props => {
  const {
    devices: { register_device, unregister_device }
    // history
  } = props;
  const merged = [...register_device, ...unregister_device];
  const unregister = unregister_device ? unregister_device.length : 0;
  const register = register_device ? register_device.length : 0;
  const total = unregister + register;

  // const { t } = useTranslation();

  // const classes = useStyles();

  return (
    <>
      <Grid container>
        {merged &&
          merged.map((device, index) => {
            return <DeviceCard key={index} device={device} />;
          })}

        {/* {unregister_device &&
          unregister_device.map((device, index) => {
            return (
              <Paper key={index} className={classes.box}>
                <div className={classes.squareContainer}>
                  {device.device_type === 'sip_device' && (
                    <div>
                      <DeviceVoipPhone className={classes.unregisteredIcon} />
                      <Image src="/desk.png" alt="device" />
                    </div>
                  )}
                  {device.device_type === 'cellphone' && (
                    <div>
                      <DeviceMobile className={classes.unregisteredIcon} />
                      <Image src="/cell.png" alt="device" />
                    </div>
                  )}
                  {device.device_type === 'softphone' && (
                    <div>
                      <DeviceSoftPhone className={classes.unregisteredIcon} />
                      <Image src="/device-soft.png" alt="device" />
                    </div>
                  )}
                  {device.device_type === 'application' && (
                    <div>
                      <DeviceSprintPhone className={classes.unregisteredIcon} />
                      <Image src="/application.png" alt="device" />
                    </div>
                  )}
                  <div className="mt-2 name">
                    {device.name.length > 50
                      ? device.name.substr(0, 50)
                      : device.name}
                  </div>
                  <div className="number">{device.mac_address}</div>
                </div>
              </Paper>
            );
          })}
        {register_device &&
          register_device.map((device, index) => (
            <Paper key={index} className={classes.box}>
              <div className={classes.squareContainer}>
                {device.device_type === 'sip_device' && (
                  <div>
                    <DeviceVoipPhone className={classes.registeredIcon} />
                    <Image src="/desk.png" alt="device" />
                  </div>
                )}
                {device.device_type === 'cellphone' && (
                  <div>
                    <DeviceMobile className={classes.registeredIcon} />
                    <Image src="/cell.png" alt="device" />
                  </div>
                )}
                {device.device_type === 'softphone' && (
                  <div>
                    <DeviceSoftPhone className={classes.registeredIcon} />
                    <Image src="/device-soft.png" alt="device" />
                  </div>
                )}
                {device.device_type === 'application' && (
                  <div>
                    <DeviceSprintPhone className={classes.registeredIcon} />
                    <Image src="/application.png" alt="device" />
                  </div>
                )}
                <div className="mt-2 name">
                  {device.name.length > 50
                    ? device.name.substr(0, 50)
                    : device.name}
                </div>
                <div className="number">{device.mac_address}</div>
              </div>
            </Paper>
          ))} */}
        {total > 1 && <></>}
      </Grid>
    </>
  );
};

export default connect(prepareDevicesAndNumbersState)(DeviceList);
