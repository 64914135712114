import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    // height: 321,
    height: 281,
    overflow: 'auto'
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    marginBottom: theme.spacing(3),
    cursor: 'pointer'
  },
  outbound: {
    color: theme.palette.inboundGrey['500'],
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  inbound: {
    // opacity: 0.001,
    color: theme.palette.activeGrey['500'],
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  missed: {
    color: theme.palette.red['500'],
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  callerId: {
    color: theme.palette.darkGrey['500'],
    fontSize: theme.spacing(2) - 2,
    fontWeight: 'normal'
  },
  callerIdNumber: {
    color: theme.palette.textGrey['500'],
    fontSize: theme.spacing(2) - 4,
    fontWeight: 'normal'
  },
  red: {
    color: theme.palette.red['500']
  }
}));

export default useStyles;
