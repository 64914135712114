import * as CONSTS from '../Constants';
import { persistReducer } from '@KazooSDK/utils';
import moment from 'moment';
import { defaults, get } from 'lodash';

const persist = persistReducer('faxes');

// const day = new Date();
const initialState = {
  full_name: '',
  faxes_inbox_data: [],
  faxes_outbox_data: [],
  loadingFaxboxes: false,
  loadedFaxboxes: false,
  faxboxes: [],
  faxboxById: {
    // faxboxId: { inbox: [], outbox: []}
  },
  faxbox: {},
  usableBoxes: [],
  type: null,
  listStatus: [],
  statusSelected: '',
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 10,
  from: moment()
    .subtract(7, 'day')
    .format('MM/DD/YYYY'),
  to: moment().format('MM/DD/YYYY'),
  typeFilter: 'all',
  listStatusFilter: [
    'received',
    'completed',
    'pending',
    'processing',
    'failed'
  ],
  makeCheckedFax: [],
  makeCheckedFaxObject: []
};

const defaultState = persist(initialState, 'get', initialState);
defaultState.from = moment().subtract(7, 'day').format('MM/DD/YYYY');
defaultState.to = moment().format('MM/DD/YYYY');

const defaultFaxboxesStruct = {
  loading: false,
  loaded: false,
  inbox: [],
  outbox: [],
  outgoing: []
};

export default (state = defaultState, action) => {
  let faxboxById;
  switch (action.type) {
    case CONSTS.SET_FAXES_DATA:
    case CONSTS.GET_FAXES:
    case CONSTS.GET_FAX_BOXES:
    case CONSTS.UPDATE_FAXES_STATE:
      return persist({ ...state, ...action.payload });

    case CONSTS.SET_FAXBOXES_LOADING:
      // loading ALL faxboxes
      let loadingFaxboxes = get(
        action.payload,
        'loadingFaxboxes',
        state.loadingFaxboxes
      );
      let loadedFaxboxes = get(
        action.payload,
        'loadedFaxboxes',
        state.loadedFaxboxes
      );

      return persist({
        ...state,
        loadingFaxboxes,
        loadedFaxboxes
      });

    case CONSTS.SET_FAXBOX_FAXES_LOADING:
      faxboxById = state.faxboxById[action.payload.faxboxId];
      faxboxById.loading = get(action.payload, 'loading', faxboxById.loading);
      faxboxById.loaded = get(action.payload, 'loaded', faxboxById.loaded);

      return persist({
        ...state,
        faxboxById: {
          ...state.faxboxById
          // ...faxboxById
        }
      });

    case CONSTS.SET_FAXBOX_FAXES:
      // // merges in faxes
      // const inbox = uniqBy(get(state.faxboxById,`${action.payload.faxboxId}.inbox`,[]).concat(action.payload.inbox),'id');
      // const outbox = uniqBy(get(state.faxboxById,`${action.payload.faxboxId}.outbox`,[]).concat(action.payload.outbox),'id');
      // const outgoing = uniqBy(get(state.faxboxById,`${action.payload.faxboxId}.outgoing`,[]).concat(action.payload.outgoing),'id');

      // replace faxes
      const inbox = action.payload.inbox;
      const outbox = action.payload.outbox;
      const outgoing = action.payload.outgoing;

      faxboxById = state.faxboxById[action.payload.faxboxId];
      faxboxById.inbox = inbox;
      faxboxById.outbox = outbox;
      faxboxById.outgoing = outgoing;

      return persist({
        ...state,
        faxboxById: {
          ...state.faxboxById
          // ...faxboxById
        }
      });

    case CONSTS.SET_FAXBOXES:
      // updates info for ALL vmboxes (counts)
      // - TODO: use vm_boxes[id].data? (like vm_boxes[id].messages)
      const { faxboxes } = action.payload;
      faxboxById = state.faxboxById;
      for (let faxbox of faxboxes) {
        const faxbox_id = faxbox.id;
        if (!faxboxById[faxbox_id]) {
          faxboxById[faxbox_id] = {};
        }
        faxboxById[faxbox_id] = defaults(
          faxboxById[faxbox_id],
          defaultFaxboxesStruct
        );
      }
      // TODO: remove unused faxboxes, faxes
      return persist({
        ...state,
        faxboxes,
        faxboxById: {
          ...faxboxById
        }
      });

    case CONSTS.RESET_FAXES:
    case CONSTS.LOGOUT:
      // debugger;
      return persist(initialState);
    default:
      return state;
  }
};
