import React, { useState } from 'react';
import { Typography, Switch } from '@material-ui/core';
import { Image } from './../../Common/Image';
import useStyles, {
  Container,
  SoftwareAccessList,
  FormControlLabelWithSwitch
} from './../styles';
import WebsocketStatus from './WebsocketStatus';
import { useTranslation } from 'react-i18next';

export const SoftwareAccess = () => {
  const classes = useStyles();
  const [autoStart, setAutoStart] = useState(
    window.commio.app.autoStart.applicationAutoStarts
  );
  const { t } = useTranslation();

  const onAutoStartChange = () => {
    window.comm.io.app.autoStart.toggleAutoStart();
    setAutoStart(!autoStart);
  };

  return (
    <Container maxWidth='sm' className={classes.section}>
      <div>
        <Typography className={classes.accessTitle} variant='subtitle1'>
          {t('settings.softwareAccess.label')}
        </Typography>
      </div>
      <SoftwareAccessList>
        <hr />
        <FormControlLabelWithSwitch
          labelPlacement='start'
          label={t('settings.autoStart.label')}
          control={
            <Switch
              checked={autoStart}
              onChange={onAutoStartChange}
              name='autoStart'
              color='primary'
            />
          }
        />
        <hr />
        <FormControlLabelWithSwitch
          labelPlacement='start'
          control={<WebsocketStatus />}
          label={t('settings.websocketStatus.label')}
        />
        <hr />
      </SoftwareAccessList>
      <div className={classes.appVersionContainer}>
        <Image
          src={'/icon.png'}
          alt='Comm.io Icon'
          className={classes.appIcon}
        />
        <div className={classes.appVersionInfo}>
          <Typography className={classes.appVersionInfoTitle}>
            Comm.io
          </Typography>
          <Typography className={classes.appVersionInfoSubtitle}>
            {window.commio.app.version}
          </Typography>
        </div>
      </div>
    </Container>
  );
};
