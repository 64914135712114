import React, { useEffect } from 'react';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import Settings from './Components/Settings/Settings';
import { Home } from './Components/Home/';
import { Voicemails } from './Components/Voicemails';
import { CallHistory } from './Components/CallHistory';
import { DevicesAndNumbers } from './Components/DevicesNumbers';
import { Conferences } from './Components/Conferences';
import Faxes from './Components/Faxes/Faxes';
import FaxBox from './Components/FaxBox/FaxBox';
import { Sidebar } from './Components/Sidebar';
import Topbar from './Components/Topbar/Topbar';
import useStyles from './styles';

const UserPortal = props => {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isPermanent = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  });

  const isDefaultMiniMenu = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  });

  const [miniMenu, setMiniMenu] = React.useState(isDefaultMiniMenu);

  const handleDrawerToggle = () => {
    if (isPermanent) {
      setMiniMenu(!miniMenu);
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  // watch for transition from mini<>full drawer
  useEffect(() => {
    setMiniMenu(isDefaultMiniMenu);
  }, [isDefaultMiniMenu, setMiniMenu]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Topbar handleDrawerToggle={handleDrawerToggle} miniMenu={miniMenu} />
      <Sidebar
        container={container}
        theme={theme}
        mobileOpen={mobileOpen}
        miniMenu={miniMenu}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/user_portal/home" component={Home} />
          <Route
            exact
            path="/user_portal/voicemails/vmbox/:vmbox_id"
            component={Voicemails}
          />
          <Route exact path="/user_portal/voicemails" component={Voicemails} />
          <Route
            exact
            path="/user_portal/call_history"
            component={CallHistory}
          />
          <Route
            exact
            path="/user_portal/devices_numbers"
            component={DevicesAndNumbers}
          />

          <Route
            exact
            path="/user_portal/faxes/faxbox/:faxbox_id"
            component={FaxBox}
          />
          <Route exact path="/user_portal/faxes" component={Faxes} />

          <Route
            exact
            path="/user_portal/conferences"
            component={Conferences}
          />

          <Route exact path="/user_portal/settings" component={Settings} />
          <Route render={() => <Redirect to="/user_portal/home" />} />
          {/* change above to /user_portal/home AFTER mobile launch */}
        </Switch>
      </main>
    </div>
  );
};

export const drawerWidth = 280;
export const drawerMiniWidth = 56;

export default UserPortal;
