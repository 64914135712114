import React from 'react';
import { AsYouType } from 'libphonenumber-js';
import Flag from 'react-flags';

export const PhoneNumberFlag = ({ ptn, size }) => {
  const asYouType = new AsYouType();
  asYouType.input(ptn);
  const code = asYouType.getNumber()?.country ?? 'unknown';

  return (
    <Flag
      name={code}
      format="svg"
      basePath={process.env.PUBLIC_URL + '/flags'}
      width={size}
      height={size}
    />
  );
};

export default PhoneNumberFlag;
