import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const EN_US = require('./languages/en-US');

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: EN_US
    },
    react: {
      transSupportBasicHtmlNodes: true
    }
  }
});

export default i18next;
