import React from "react";
import useStyles from "./styles";

export const Loader = props => {
  const {
    type,
    marginTop
  } = props;

  const classes = useStyles({
    type,
    marginTop
  });

  return (
    <div className={classes.loader}>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
    </div>
  );
};

export default Loader;
