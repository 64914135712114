import { toast } from 'react-toastify';
import { remove } from 'lodash';
import { KazooSDK } from '../index.js';
import isElectron from 'is-electron';

export const getFaxMethods = (props) => {
  const updateFaxesState = props.updateFaxesState;
  return {
    filterFaxes: (params) => {
      updateFaxesState({
        type: props.typeFilter,
        listStatus: [...props.listStatusFilter],
        page: 0,
        makeCheckedFax: [],
        makeCheckedFaxObject: []
      });
      //  props.getFaxes(params);
      props.fetchFaxesForFaxbox(
        props.faxBox.id,
        props.faxBox.caller_id,
        props.from,
        props.to
      );
    },
    updateType: (value) => {
      updateFaxesState({ type: value });
    },
    handleSelectStatus: (type, data) => {
      let temps = [];
      let tempObjects = [];
      switch (type) {
        case 'all':
          if (props.statusSelected === 'all') {
            updateFaxesState({
              makeCheckedFax: temps,
              makeCheckedFaxObject: tempObjects,
              statusSelected: null
            });
          } else {
            data.forEach((element) => {
              temps.push(element.id);
              tempObjects.push({
                id: element.id,
                folder: element.folder,
                status: element.status
              });
            });
            updateFaxesState({
              makeCheckedFax: temps,
              makeCheckedFaxObject: tempObjects,
              statusSelected: 'all'
            });
          }
          break;
        case 'received':
          data.forEach((element) => {
            if (element.folder === 'inbox') {
              temps.push(element.id);
              tempObjects.push({
                id: element.id,
                folder: element.folder,
                status: element.status
              });
            }
          });
          updateFaxesState({
            makeCheckedFax: temps,
            makeCheckedFaxObject: tempObjects,
            statusSelected: 'received'
          });
          break;
        case 'sent':
          data.forEach((element) => {
            if (element.status === 'completed') {
              temps.push(element.id);
              tempObjects.push({
                id: element.id,
                folder: element.folder,
                status: element.status
              });
            }
          });
          updateFaxesState({
            makeCheckedFax: temps,
            makeCheckedFaxObject: tempObjects,
            statusSelected: 'sent'
          });
          break;
        case 'failed':
        case 'processing':
        case 'pending':
          data.forEach((element) => {
            if (element.status === type) {
              temps.push(element.id);
              tempObjects.push({
                id: element.id,
                folder: element.folder,
                status: element.status
              });
            }
          });
          updateFaxesState({
            makeCheckedFax: temps,
            makeCheckedFaxObject: tempObjects,
            statusSelected: type
          });
          break;
        default:
          updateFaxesState({
            makeCheckedFax: temps,
            makeCheckedFaxObject: tempObjects,
            statusSelected: null
          });
          break;
      }
    },
    handleCheckBox: (value) => {
      let temp = props.makeCheckedFax || [];
      let tempObjects = props.makeCheckedFaxObject || [];
      if (props.makeCheckedFax.includes(value)) {
        remove(temp, (element) => element === value);
        remove(tempObjects, (element) => element.id === value);
      } else {
        let tempElement = [
          ...props.faxesInboxData,
          ...props.faxesOutboxData
        ].find((ele) => ele.id === value);
        if (tempElement) {
          tempObjects.push({
            id: tempElement.id,
            folder: tempElement.folder,
            status: tempElement.status
          });
          temp.push(value);
        }
      }
      updateFaxesState({
        makeCheckedFax: temp,
        statusSelected: null,
        makeCheckedFaxObject: tempObjects
      });
    },
    deleteFaxes: async () => {
      try {
        if (props.makeCheckedFax.length > 0) {
          await Promise.all(
            props.makeCheckedFaxObject.map((deleteFax) => {
              return KazooSDK.deleteFax(deleteFax).catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              });
            })
          )
            .then(() => {
              // toast.success('Deleted', {
              //   position: toast.POSITION.TOP_RIGHT
              // });
              updateFaxesState({
                page: 0,
                makeCheckedFax: [],
                makeCheckedFaxObject: []
              });
              /* props.getFaxes({
                to: props.to,
                from: props.from,
                type: props.typeFilter,
                listStatus: props.listStatus,
              }); */
              props.fetchFaxesForFaxbox(
                props.faxBox.id,
                props.faxBox.caller_id,
                props.from,
                props.to
              );
            })
            .catch((e) => {
              toast.error(e.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            });
        }
      } catch (e) {
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        console.warn(e, 'err deleting fax');
      }
    },
    deleteFax: (folder, id) => {
      KazooSDK.deleteFax({ folder: folder, id: id })
        .then(() => {
          updateFaxesState({
            page: 0,
            makeCheckedFax: [],
            makeCheckedFaxObject: []
          });
          props.fetchFaxesForFaxbox(props.faxBox.id, props.faxBox.caller_id);
          // props.getFaxes({
          //   to: props.to,
          //   from: props.from,
          //   type: props.typeFilter,
          //   listStatus: props.listStatus
          // });
        })
        .catch((e) => {
          // toast.error(e.message, {
          //   position: toast.POSITION.TOP_RIGHT
          // });
        });
    },
    resendFax: async () => {
      if (props.makeCheckedFax && props.makeCheckedFax.length > 0) {
        toast.success('Resending Fax...', {
          position: toast.POSITION.TOP_RIGHT
        });
        await Promise.all(
          props.makeCheckedFaxObject.map((resendFax) => {
            return KazooSDK.resendFax(resendFax).catch((e) => {
              toast.error(e.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            });
          })
        ).then((res) => {
          toast.success('Fax resent', {
            position: toast.POSITION.TOP_RIGHT
          });
          updateFaxesState({ makeCheckedFax: [], makeCheckedFaxObject: [] });
          props.getFaxes({
            to: props.to,
            from: props.from,
            type: props.typeFilter,
            listStatus: props.listStatus
          });
        });
      }
    },
    handleOpenFile: (pdfUrl) => {
      console.info('Opening Fax', { pdfUrl });
      window.localStorage.setItem('pdf_viewer_file_url', pdfUrl);
      if (isElectron() && window.commio) {
        window.commio.pdf.openPDFViewer();
      } else {
        window.open(`/pdf_viewer`);
      }
    }
  };
};

export default getFaxMethods;
