import { createSelector } from 'reselect';
import { getAuthState, getDeviceState } from '../../../Selectors/common';
// import { KazooSDK } from '@KazooSDK';
// import { getPhoneNumber, toMomentUtc, gregorianToEpoch } from '@KazooSDK/utils';
// import { get, toNumber } from 'lodash';
// import moment from 'moment';

const prepareDevicesAndNumbersState = createSelector(
  [getAuthState, getDeviceState],
  (auth, devices) => {
    // TODO: switch to using better/different phone numbers
    return {
      devices,
      phoneNumbers:
        (auth && auth.phone_numbers && auth.phone_numbers.numbers) || []
    };
  }
);

export default prepareDevicesAndNumbersState;
