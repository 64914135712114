import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { WebsocketProvider } from '@Util/websocketBinding';
import { unregister } from './registerServiceWorker';
import {
  cleanUpSessionStorage,
  getLaunchParams,
  setupMoment
} from 'utils/launch';
import { theme } from './theme';
import './index.css';
import store from './store';
import { App } from './Components/App';

cleanUpSessionStorage();
getLaunchParams();
setupMoment();

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <WebsocketProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Component />
          </Router>
        </ThemeProvider>
      </WebsocketProvider>
    </Provider>,
    document.getElementById('root')
  );
};
unregister();

render(App);

if (module.hot) {
  module.hot.accept('./Components/App/App', () => {
    const NextApp = require('./Components/App/App').default;
    render(NextApp);
  });
}
