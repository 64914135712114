import React, { useRef, useReducer } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import { remove } from 'lodash';
import { useStyles } from './styles';
import { Telicon } from '../../Icons/index';
import { useTranslation } from 'react-i18next';

const FilterWidgetFax = props => {
  const {
    updateFaxesState,
    handleFilter,
    typeFilter,
    to,
    from,
    listStatusFilter
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const initState = {
    open: false,
    filtered: false,
    openFromDate: false,
    openToDate: false,
    selectedFilterOptions: listStatusFilter
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { open, filtered, openFromDate, openToDate } = state;

  const popoverAnchor = useRef(null);

  const id = open ? 'simple-popover' : undefined;

  const handleClick = () => {
    setState({ open: true });
  };

  const handleClose = () => {
    setState({ open: false });
  };

  const handleClearFilter = () => {
    setState({ ...initState, open: true });
    updateFaxesState({
      typeFilter: null,
      from: moment()
        .subtract(7, 'day')
        .format('MM/DD/YYYY'),
      to: moment().format('MM/DD/YYYY'),
      listStatusFilter: []
    });
  };

  const resetStatusFilterToDefaults = () => {
    setState({ ...initState, open: true });
    updateFaxesState({
      typeFilter: null,
      from: moment()
        .subtract(7, 'day')
        .format('MM/DD/YYYY'),
      to: moment().format('MM/DD/YYYY'),
      listStatusFilter: [
        'received',
        'completed',
        'pending',
        'processing',
        'failed'
      ]
    });
  };

  const handleApplyFilter = () => {
    handleFilter({ typeFilter, from, to, listStatusFilter });
    handleClose();
  };

  const handleOnChangeType = value => {
    switch (value) {
      case 'all':
        updateFaxesState({
          typeFilter: value,
          listStatusFilter: [
            'received',
            'completed',
            'pending',
            'processing',
            'failed'
          ]
        });
        break;
      case 'received':
        updateFaxesState({ typeFilter: value, listStatusFilter: ['received'] });
        break;
      case 'sent':
        updateFaxesState({
          typeFilter: value,
          listStatusFilter: ['completed', 'pending', 'processing', 'failed']
        });
        break;
      default:
        updateFaxesState({
          typeFilter: value,
          listStatusFilter: [
            'received',
            'completed',
            'pending',
            'processing',
            'failed'
          ]
        });
        break;
    }
  };

  const handleCheckBox = event => {
    let temtype = typeFilter || 'all';
    let temp = listStatusFilter || [];
    if (listStatusFilter.includes(event.target.value)) {
      remove(temp, ele => ele === event.target.value);
    } else {
      temp.push(event.target.value);
    }
    updateFaxesState({ listStatusFilter: temp, typeFilter: temtype });
    setState({ filtered: true });
  };

  const handleOnChangeFromDate = value => {
    let tempToDate = to;
    let tempValueDate = value;

    if (moment(tempValueDate).diff(moment(), 'days') >= 0) {
      tempValueDate = moment().format('MM/DD/YYYY');
    }

    if (
      moment(to).diff(moment(tempValueDate), 'days') >= 30 ||
      moment(to).diff(moment(tempValueDate), 'days') < 0
    ) {
      tempToDate = moment(tempValueDate)
        .add(30, 'day')
        .format('MM/DD/YYYY');
    }
    updateFaxesState({
      from: moment(tempValueDate).format('MM/DD/YYYY'),
      to: tempToDate
    });
    setState({ openFromDate: false });
  };

  const handleOnChangeToDate = value => {
    let tempFromDate = from;
    let tempValueDate = value;

    if (moment(tempValueDate).diff(moment(), 'days') >= 0) {
      tempValueDate = moment().format('MM/DD/YYYY');
    }
    if (
      moment(tempValueDate).diff(moment(from), 'days') >= 30 ||
      moment(tempValueDate).diff(moment(from), 'days') < 0
    ) {
      tempFromDate = moment(tempValueDate)
        .subtract(30, 'day')
        .format('MM/DD/YYYY');
    }
    updateFaxesState({
      from: tempFromDate,
      to: tempValueDate
    });
    setState({ openToDate: false });
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Popover
          id={id}
          open={open}
          anchorEl={popoverAnchor.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          className={classes.popover}
        >
          <Paper className={classes.root}>
            <div className={classes.topRow}>
              <Typography className={classes.totalText}>
                {t('faxes.filter.type.label')}
              </Typography>
              <div className={classes.secondCol}>
                <ButtonGroup
                  className={classes.filterButtons}
                  size='large'
                  aria-label='full width outlined button group'
                >
                  <Button
                    className={classNames({
                      [classes.buttonGroupActive]: typeFilter === 'all'
                    })}
                    onClick={() => handleOnChangeType('all')}
                  >
                    {t('faxes.filter.all.label')}
                  </Button>
                  <Button
                    className={classNames({
                      [classes.buttonGroupActive]: typeFilter === 'received'
                    })}
                    onClick={() => handleOnChangeType('received')}
                    startIcon={<Telicon name='arrow-target-down' />}
                  >
                    {t('faxes.filter.received.label')}
                  </Button>
                  <Button
                    className={classNames({
                      [classes.buttonGroupActive]: typeFilter === 'sent'
                    })}
                    onClick={() => handleOnChangeType('sent')}
                    startIcon={<Telicon name='arrow-target-up' />}
                  >
                    {t('faxes.filter.sent.label')}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <Divider />
            <div className={classes.secondRow}>
              <Typography className={classes.totalText}>
                {t('faxes.filter.status.label')}
              </Typography>
              <div className={classes.secondCol}>
                {typeFilter !== 'received' && (
                  <>
                    <FormControlLabel
                      className={classNames(classes.checkBox, 'grayLabel')}
                      label='Pending'
                      control={
                        <Checkbox
                          onChange={handleCheckBox}
                          checked={listStatusFilter.includes('pending')}
                          value='pending'
                        />
                      }
                    />
                    <FormControlLabel
                      className={classNames(classes.checkBox, 'grayLabel')}
                      label='Processing'
                      control={
                        <Checkbox
                          onChange={handleCheckBox}
                          checked={listStatusFilter.includes('processing')}
                          value='processing'
                        />
                      }
                    />
                    <FormControlLabel
                      className={classNames(classes.checkBox, 'redLabel')}
                      label='Failed'
                      control={
                        <Checkbox
                          onChange={handleCheckBox}
                          checked={listStatusFilter.includes('failed')}
                          value='failed'
                        />
                      }
                    />
                    <FormControlLabel
                      className={classNames(classes.checkBox, 'greyLabel')}
                      label='Sent'
                      control={
                        <Checkbox
                          onChange={handleCheckBox}
                          checked={listStatusFilter.includes('completed')}
                          value='completed'
                        />
                      }
                    />
                  </>
                )}
                {typeFilter !== 'sent' && (
                  <FormControlLabel
                    className={classNames(classes.checkBox, 'greyLabel')}
                    label='Received'
                    control={
                      <Checkbox
                        onChange={handleCheckBox}
                        checked={listStatusFilter.includes('received')}
                        value='received'
                      />
                    }
                  />
                )}
              </div>
            </div>
            <Divider />

            <div className={classes.secondRow}>
              <div className={classes.topRow}>
                <Typography className={classes.totalText}>
                  {t('faxes.filter.date.label')}
                </Typography>

                <div className={classes.wrapPicker}>
                  <KeyboardDatePicker
                    open={openFromDate}
                    onOpen={() => setState({ openFromDate: true })}
                    onClose={() => setState({ openFromDate: false })}
                    classes={{ root: classes.datePicker }}
                    keyboardIcon={<Telicon name='calendar' />}
                    InputProps={{
                      classes: {
                        root: classes.datePickerInnerInput
                      }
                    }}
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    value={from}
                    onChange={value => handleOnChangeFromDate(value)}
                    disableFuture={true}
                    maxDateMessage=''
                    invalidDateMessage=''
                  />
                  <span className={classes.dateSeparator} />
                  <KeyboardDatePicker
                    open={openToDate}
                    onOpen={() => setState({ openToDate: true })}
                    onClose={() => setState({ openToDate: false })}
                    classes={{ root: classes.datePicker }}
                    keyboardIcon={<Telicon name='calendar' />}
                    variant='inline'
                    InputProps={{
                      classes: {
                        root: classes.datePickerInnerInput
                      }
                    }}
                    value={to}
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    onChange={value => handleOnChangeToDate(value)}
                    disableFuture={true}
                    maxDateMessage=''
                    invalidDateMessage=''
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className={classes.thirdRow}>
              <div className={classes.leftContainer}>
                <Typography className={classes.restoreText} onClick={resetStatusFilterToDefaults}>
                  {t('faxes.filter.resetToDefaults.label')}
                </Typography>
              </div>
              <div className={classes.rightContainer}>
                <Button
                  variant='outlined'
                  className={classes.buttonClearAll}
                  onClick={handleClearFilter}
                >
                  {t('faxes.filter.clearAll.label')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.buttonApply}
                  onClick={handleApplyFilter}
                  disabled={
                    !to ||
                    !from ||
                    to === 'Invalid date' ||
                    from === 'Invalid date'
                  }
                >
                  {t('faxes.filter.apply.label')}
                </Button>
              </div>
            </div>
          </Paper>
        </Popover>
      </MuiPickersUtilsProvider>
      <Button
        variant='outlined'
        className={classNames(classes.button, classes.buttonFilter, {
          [classes.buttonOpen]: open,
          [classes.buttonFiltered]: filtered
        })}
        startIcon={<Telicon name='layers--3' />}
        ref={popoverAnchor}
        onClick={handleClick}
      >
        <Typography>{t('faxes.filter.label')}</Typography>
      </Button>
    </>
  );
};

export default FilterWidgetFax;
