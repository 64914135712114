import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import useStyles from '../FilterInput/styles';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useTranslation } from 'react-i18next';

export default function VoicemailFilter (props) {
  const { handleActionBar } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMaskItem = value => {
    handleClose();
    handleActionBar(value);
  };

  return (
    <div className={classes.containerBar}>
      <div className={classes.root}>
        <PlaylistAddCheckIcon style={{ color: '#2297ff' }} />
      </div>
      <div onClick={handleClick} className={classes.root1}>
        <div className={classes.root2} />
      </div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
      >
        <MenuItem value="MarkAsNew" onClick={e => handleMaskItem('new')} dense>
	  {t('voicemails.markAsNew.label')}
        </MenuItem>
        <MenuItem
          value='MarkAsListened'
          onClick={e => handleMaskItem('saved')}
          dense
        >
	  {t('voicemails.markAsListened.label')}
        </MenuItem>
      </Menu>
    </div>
  );
}
