import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '32px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    float: 'left',
    borderRight: 'none'
  },
  root1: {
    width: '32px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.inboundGrey['500']}`,
    float: 'left',
    borderLeft: 'none'
  },
  root2: {
    width: 0,
    height: 0,
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderTop: `8px solid ${theme.palette.textGrey['500']}`
  },
  containerBar: {
    display: 'inline-block',
    marginLeft: 30,
    height: 40,
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, ${theme.palette.sidebarText['500']} 100%)`,
    borderRadius: 2
  },
  containerCheckbox: {
    display: 'inline-block',
    height: 40,
    background: `linear-gradient(-180deg, ${theme.palette.white['500']} 0%, ${theme.palette.sidebarText['500']} 100%)`,
    borderRadius: 2,
    paddingLeft: theme.spacing(1)
  }
}));
