import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesDesktopCallList = makeStyles(theme => ({
  root: {
    width: '100%',
    '& span, p, td': {
      fontFamily: '"Source Sans Pro-Regular", sans-serif'
    }
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tableWrapper: {
    overflowX: 'visible',
    '& .MuiTableRow-hover': {
      transition: '0.5s'
    },
    '& .MuiTableRow-hover:hover, .Mui-selected': {
      backgroundColor: theme.palette.white['500'],
      boxShadow: `inset 0px 1px 0px 0px ${theme.palette.inboundGrey['500']},
        inset 0px -1px 0px 0px ${theme.palette.inboundGrey['500']},
        0px 1px 4px 0px ${theme.palette.inboundGrey['500']}`
    },
    '& .MuiTableCell-head .MuiTableSortLabel-root': {
      color: theme.palette.textGrey['500'],
      fontFamily: 'Source Sans Pro',
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 600
    },
    '& .MuiTableCell-head': {
      paddingBottom: 0
    }
  },
  pagination: {
    marginTop: 20,
    '& .MuiTablePagination-caption': {
      padding: '0 32px 0 0',
      fontWeight: 'bold'
    },
    '& .MuiTablePagination-input': {
      backgroundColor: theme.palette.white['500'],
      border: `1px solid ${theme.palette.inboundGrey['500']}`,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 5px 5px 15px',

      '& .MuiInputBase-inputSelect': {
        marginBottom: 3,
        color: theme.palette.selectBlack['500'],
        fontSize: 14,
        '&:focus': {
          backgroundColor: theme.palette.white['500']
        }
      },
      '&>.MuiSelect-select::before': {
        content: "'View '"
      },
      '&>.MuiSelect-select::after': {
        content: "' per page'",
        paddingRight: 40
      },
      '& svg': {
        position: 'relative'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTablePagination-toolbar ': {
        display: 'block'
      },
      '& .MuiTablePagination-selectRoot': {
        marginBottom: 20,
        width: 200,
        marginLeft: 0
      },
      '& .MuiTablePagination-caption': {
        float: 'left'
      }
    }
  }
}));
