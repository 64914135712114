import React, { useCallback } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { orderBy as _orderBy, debounce } from 'lodash';
import isElectron from 'is-electron';
import { useSessionStorage, useLocalStorage } from 'react-use';
import {
  getDateTime,
  formatDateTime,
  getDuration,
  getPhoneNumber
} from '@KazooSDK/utils';
import { useTranslation } from 'react-i18next';
import { toast } from '@Utils/toast';
import classNames from 'classnames';
import { KazooSDK } from '@KazooSDK';
import { useStylesCallListTableBody } from './styles';
import CONFIG from './../../../../../../../Config';
import { PhoneCallStatus } from '../../../../Common/PhoneCallStatus';

const sleep = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const CallListTableBody = ({
  rowsFilteredByText,
  orderBy,
  order,
  page,
  rowsPerPage,
  selected,
  handleClick
}) => {
  const [isMobile] = useSessionStorage('mobile', false);
  const [deviceId] = useLocalStorage('webphone_device_id', false, {
    raw: true
  });
  const classes = useStylesCallListTableBody();
  const { t } = useTranslation();

  const handleCallButtonClick = useCallback(
    debounce(
      async (row) => {
        if (row.conferenceExtension) {
          return;
        }

        let to = KazooSDK.isIncomingCall(row) ? row.from : row.to;
        try {
          to = getPhoneNumber(to);
        } catch (err) {
          toast({
            type: 'error',
            title: t('callHistory.toast.invalidNumber.label', {
              to: to
            })
          });
          return false;
        }
        if (
          !isMobile &&
          isElectron() &&
          CONFIG.ENABLE_WEBPHONE &&
          CONFIG.ENABLE_WEBPHONE !== 'false' &&
          deviceId &&
          deviceId !== 'undefined'
        ) {
          window.commio.webphone.sendWebphoneAction('dial-number', {
            to: to.split('@')[0]
          });
        } else {
          toast({
            type: 'success',
            title: t('callHistory.toast.ringingYourDevice.label', {
              to: to
            })
          });

          // delay while notifying user
          await sleep(1000);

          KazooSDK.makeCall({ to })
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.warn(e);
            });
        }
      },
      5000,
      {
        leading: true,
        trailing: false
      }
    ),
    []
  );

  if (!rowsFilteredByText.length) {
    return (
      <caption className={classes.caption}>
        {t('callHistory.emptyFilter.text.label')}
      </caption>
    );
  }

  return (
    <TableBody>
      {_orderBy(rowsFilteredByText, [orderBy], [order])
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <TableRow
            hover
            onClick={() => {
              handleClick(row.id);
              handleCallButtonClick(row);
            }}
            aria-checked={selected === row.id}
            tabIndex={-1}
            key={row.id}
            name={row.id}
            selected={selected === row.id}
            style={{ cursor: 'pointer' }}
          >
            <TableCell
              className={classes.wrapperCell}
              component='th'
              scope='row'
            >
              <div>
                <span>
                  <PhoneCallStatus
                    interaction={row}
                    hangup_cause={row.hangup_cause}
                  />
                </span>
                <div className={classes.wrapperItem}>
                  {row.caller_id_name && (
                    <span
                      className={classNames([classes.fromTitle], {
                        [classes.red]: KazooSDK.isMissedCall(row)
                      })}
                    >
                      {getPhoneNumber(row.caller_id_name)}
                    </span>
                  )}
                  <span>{getPhoneNumber(row.caller_id_number)}</span>
                </div>
              </div>
            </TableCell>
            <TableCell
              className={classes.wrapperCell}
              component='th'
              scope='row'
            >
              <div>
                <div className={classes.wrapperItem}>
                  {row.callee_id_name ? (
                    <span>{getPhoneNumber(row.callee_id_name)}</span>
                  ) : (
                    <span>{row.dialed_number}</span>
                  )}
                  <span>{getPhoneNumber(row.callee_id_number)}</span>
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.wrapperCell}>
              <div>
                <div className={classes.wrapperItem}>
                  <span>{getDateTime(row.timestamp).date}</span>
                  <span>{formatDateTime(row.timestamp, 'h:mm a')}</span>
                </div>
              </div>
            </TableCell>
            <TableCell>{getDuration(row.duration_seconds)}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};
