import { createSelector } from 'reselect';
import {
  getAuthState,
  getVoicemailState,
  getSettingsState,
  getCallState,
  getDeviceState,
  getFaxesState
} from '../../../Selectors/common';
import { KazooSDK } from '@KazooSDK';
import { getPhoneNumber, gregorianToEpoch } from '@KazooSDK/utils';
import { get, toNumber } from 'lodash';
import moment from 'moment';

const prepareHomeState = createSelector(
  [
    getAuthState,
    getVoicemailState,
    getSettingsState,
    getCallState,
    getDeviceState,
    getFaxesState
  ],
  (auth, voicemails, settings, calls, devices, faxes) => {
    const user_data = auth.user_data || {};
    const user_last_seen = user_data.last_seen || {};

    // TODO: switch phone_numbers back to Calls (instead of in auth)
    // const numbers = calls.phone_num;
    const numbers =
      (auth && auth.phone_numbers && auth.phone_numbers.numbers) || [];

    let callData = [];
    const todayCalls = calls.today_data;
    let todayTotal = todayCalls.length ? 0 : 1; // denominator, don't divide by 0

    numbers.forEach((number) => {
      let todayCount = 0;
      const phoneNumber = number.length > 4 ? getPhoneNumber(number) : number;
      if (todayCalls.length) {
        todayCalls.forEach((call) => {
          if (
            call.callee_id_number &&
            call.caller_id_number &&
            (number.includes(call.callee_id_number) ||
              number.includes(call.caller_id_number))
          ) {
            todayCount++;
            todayTotal++;
          }
        });
      }
      callData.push({ phoneNumber, todayCount });
    });

    callData = callData.map((data) => ({ ...data, todayTotal }));

    let newVoicemailCount = 0;
    let totalVoicemailCount = 0;
    const voicemailsLoading = !!(
      voicemails.loadingVmboxes ||
      voicemails.vm_boxes.filter((vmbox) =>
        get(voicemails.vmboxById, `${vmbox.id}.loading`, false)
      ).length
    );
    for (let vmBox of voicemails.vm_boxes) {
      newVoicemailCount += get(vmBox, 'folders.new') || 0;
      totalVoicemailCount +=
        newVoicemailCount + (get(vmBox, 'folders.saved') || 0);
    }

    const last_seen_missed_calls_val = KazooSDK.lastSeenFor(
      user_last_seen,
      'missed_calls'
    );
    const missedCalls = calls.callHistory
      // .filter(call=>call.direction === "inbound")
      .filter((call) => toNumber(call.timestamp) > last_seen_missed_calls_val)
      // .map((call) => call.hangup_cause)
      // .filter((call) => call.hangup_cause !== 'NORMAL_CLEARING');
      .filter((call) => KazooSDK.isMissedCall(call));

    const startOfToday = moment().startOf('day').utc().unix();
    const recentCallData = calls.callHistory.filter(
      (call) => gregorianToEpoch(call.timestamp) > startOfToday
    );

    const missedCallCount = missedCalls.length;

    // Iterate through faxboxes to find New Fax counts
    let newFaxCount = 0;
    faxes.faxboxes.forEach((faxbox) => {
      const last_seen_faxbox_inbox_val = KazooSDK.lastSeenFor(
        user_last_seen,
        'faxbox_inbox',
        faxbox.id
      );
      newFaxCount += get(faxes.faxboxById, `${faxbox.id}.inbox`, []).filter(
        (fax) => fax.timestamp > last_seen_faxbox_inbox_val
      ).length;
    });

    const faxesLoading = !!(
      faxes.loadingFaxboxes ||
      faxes.faxboxes.filter((faxbox) =>
        get(faxes.faxboxById, `${faxbox.id}.loading`, false)
      ).length
    );
    let totalFaxCount = 0;
    faxes.faxboxes.forEach((faxbox) => {
      totalFaxCount += get(faxes.faxboxById, `${faxbox.id}.inbox`, []).length;
    });

    const inboxFaxes = faxes.faxboxes
      .map((faxbox) =>
        get(faxes.faxboxById, `${faxbox.id}.inbox`, []).filter(
          (fax) => gregorianToEpoch(fax.created) > startOfToday
        )
      )
      .flat();

    const outboxFaxes = faxes.faxboxes
      .map((faxbox) =>
        get(faxes.faxboxById, `${faxbox.id}.outbox`, []).filter(
          (fax) => gregorianToEpoch(fax.created) > startOfToday
        )
      )
      .flat();

    // const outboxFaxes = get(faxboxById, `${faxBox.id}.outbox`, []);

    // const last_seen_fax_val = toNumber(63748668030);
    // const newFaxCount = faxes.faxes_inbox_data.filter(fax=>fax.timestamp>last_seen_fax_val).length;

    // let newVoicemailCount =  voicemails.newvoicemails.length ? voicemails.newvoicemails.map(vmbox => vmbox.newmessagecount || 0).reduce((acc, cur) => acc + cur) : 0;
    return {
      faxes,
      inboxFaxes,
      outboxFaxes,
      faxesLoading,
      ...devices,
      ...auth,
      calls,
      lng: settings.language,
      phoneNumbers:
        (auth && auth.phone_numbers && auth.phone_numbers.numbers) || [],
      todayCalls: calls.today_data,
      recentCallData,
      callData,
      missedCallCount,
      voicemailsLoading,
      newVoicemailCount,
      totalVoicemailCount,
      newFaxCount,
      totalFaxCount
    };
  }
);

export default prepareHomeState;
