import React from "react";
import { 
  toast as toaster,
  ToastContainer as ToasterContainer 
} from "react-toastify";
import {
  Typography,
  Grid
} from "@material-ui/core";
import { css } from 'glamor'
import useStyles from "./styles";

import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";

import "react-toastify/dist/ReactToastify.css";

export const ToastContainer = props => {
  return <ToasterContainer className={css({
    marginTop: '56px !important'
  })} {...props} />
}

export const DisplayIcon = ({type}) => {
  const classes = useStyles({type});
  switch (type) {
    case 'success':
    case 'nice':
      return (
        <CheckCircleIcon
          fontSize="small"
          className={classes.icon}
        />
      );
    case 'mean':
    case 'error':
    case 'fail':
    default:
      return (
        <CancelIcon
          fontSize="small"
          className={classes.icon}
        />
      );
  }
}

export const Toast = ({ type, title, message, closeToast }) => {
  const classes = useStyles({type})
  return (
    <div className={classes.root}>
      <Grid container className={classes.body}>
        <Grid item xs={2} className={classes.iconContainer}>
          <DisplayIcon type={type} />
        </Grid>
        <Grid item xs={10}>
          <Typography
            className={classes.title}
          >
            {title}
          </Typography>

          <Typography
            className={classes.message}
          >
            {message}
          </Typography>

        </Grid>
      </Grid>
    </div>
  )
}

export const toast = props => {

  toaster(<Toast {...props} />, {
    autoClose: props.autoClose || 3000,
    position: "top-center",
    className: css({
      background:'none !important', 
      boxShadow: 'none !important'
    }),
    closeButton: false,
    hideProgressBar: true
  });
}

export default toast;