import React, { useEffect, useReducer, useCallback } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import queryString from 'query-string';
import { Grid, Hidden } from '@material-ui/core';
// import { useStyles } from './styles';
import { DesktopCallList } from './Calls/Desktop';
import { MobileCallList } from './Calls/Mobile';
import { FilterWidget } from '../Common/FilterWidget';
// import SearchWidget from '../Common/SearchWidget';
import {
  // fetchInitialData,
  filterCallHistory,
  resetCallHistory
} from '../../Actions/callHistory';
import { KazooSDK } from '@KazooSDK';
import prepareCallHistoryState from '../../Selectors/CallHistory';
import { updateLastSeen } from '../../../../Actions/auth';

import { useWebsocketBinding } from '@Utils/websocketBinding';

// TODO: move filter state here (to, from)
//       - move FilterWidget to just changing filterable settings

const calledChannels = {};

const CallHistory = ({
  device_id,
  filterCallHistory,
  history,
  isLoaded,
  isLoading,
  location,
  missedCallCount,
  rows,
  updateLastSeen
}) => {
  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    {
      type: queryString.parse(location.search).filter || 'all',
      filter: '',
      to: null,
      from: null
    }
  );

  const { type, filter, to, from } = state;

  const lastTimestamp = get(rows, [0, 'timestamp']);

  // useEffect(() => {
  //   fetchInitialData();
  //   return () => {
  //     // resetCallHistory();
  //   };
  // }, [fetchInitialData]);

  const handleFilter = useCallback(props => {
    const { to, from } = props;
    setState({ to, from });
    // filterCallHistory(props);
  }, []);
  const changeFilter = value => setState({ filter: value });

  useEffect(() => {
    setState({ type: queryString.parse(location.search).filter || 'all' });
  }, [location.search]);

  useEffect(() => {
    // Update last_seen for missed_calls
    if (type === 'all' || !lastTimestamp) {
      return;
    }
    const url = KazooSDK.getUserLastSeenMissedCallsUrl();
    updateLastSeen(url, lastTimestamp);
  }, [type, lastTimestamp, updateLastSeen]);

  useEffect(() => {
    // console.log('refiltering call history', to, from);
    if (to && from) {
      filterCallHistory({ to, from });
    }
  }, [to, from, filterCallHistory]);

  useWebsocketBinding(['call.CHANNEL_DESTROY.*'],
    'userportal.callhistory',
    event => {
    // only need to listen for a single CHANNEL_DESTROY call for the call_id
    const interactionId = event?.data?.custom_channel_vars?.call_interaction_id;
    if (!calledChannels[interactionId]) {
      calledChannels[interactionId] = true;
      setTimeout(() => {
        filterCallHistory({ to: state.to, from: state.from });
        calledChannels[interactionId] = false; // debouncing
      }, 1000);
    }
  },
  true,  // set shouldBind true
  false
  );
  // const { lastMessage } = useWebsocketBinding('call.CHANNEL_CREATE.*');

  // useEffect(() => {
  //   console.info('effect in CallHistory for lastMessage', lastMessage);
  // }, [lastMessage]);

  return (
    <>
      {/* <Grid container spacing={3}> */}
      {/* <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
      >
        <Grid item xs> */}
      <FilterWidget
        handleFilter={handleFilter}
        type={type}
        history={history}
        changeFilter={changeFilter}
        missingCount={missedCallCount}
      />
      {/* </Grid>
      </Grid> */}
      <Grid container>
        <Hidden xsDown>
          <DesktopCallList
            filter={filter}
            type={type}
            rows={rows}
            isLoading={isLoading}
            isLoaded={isLoaded}
          />
        </Hidden>
        <Hidden smUp>
          <MobileCallList
            type={type}
            filter={filter}
            rows={rows}
            isLoading={isLoading}
            isLoaded={isLoaded}
            device_id={device_id}
          />
        </Hidden>
      </Grid>
      {/* </Grid> */}
    </>
  );
};

export default compose(
  withRouter,
  connect(prepareCallHistoryState, {
    updateLastSeen,
    // fetchInitialData,
    filterCallHistory,
    resetCallHistory
  })
)(CallHistory);
