import React, { useState, useRef, useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import classNames from 'classnames';
import { orderBy as _orderBy } from 'lodash';
// import Checkbox from '@material-ui/core/Checkbox';
// import { DownloadCloud, Phone, Trash, HiddenIcon } from '../../../Icons/index';
// import {
//   getDuration,
//   calculateTimeElapsed,
//   getPhoneNumber
// } from '@KazooSDK/utils';
// import { AudioPlayer } from '../../AudioPlayer';
// import DesktopVoicemailMessageRow from './MessageRow';
import DesktopVoicemailMessageRow from '../Mobile/Message';
// import { KazooSDK } from '@KazooSDK';

const BodyTable = props => {
  const {
    messagesData,
    orderBy,
    order,
    page,
    rowsPerPage,
    // classes,
    // makeCheckedItem,
    handleCheckbox
    // handleDeleteMessage,
    // handleCall,
    // vmBox
    // handleEnded
  } = props;

  const [selected, setSelected] = useState(null);

  const handleClick = id => setSelected(id);

  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSelected(null);
    }
  };

  const handleHidden = e => {
    e.stopPropagation();
    setSelected(null);
  };

  const handleCheckboxElement = e => {
    e.stopPropagation();
    setSelected(null);
    handleCheckbox(e);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <TableBody ref={wrapperRef}>
      {_orderBy(messagesData, [orderBy], [order])
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(message => {
          // const URL_media = KazooSDK.getMessageUrl(vmBox.id, message.media_id);
          const isSelected = selected === message.media_id;
          return (
            <DesktopVoicemailMessageRow
              key={message.media_id}
              table={true}
              handleHidden={handleHidden}
              handleCheckboxElement={handleCheckboxElement}
              handleClick={handleClick}
              setSelected={setSelected}
              message={message}
              isSelected={isSelected}
              {...props}
            />
          );
        })}
    </TableBody>
  );
};

export default BodyTable;
