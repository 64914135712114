import { createSelector } from 'reselect';
import {
  getAuthState,
  getVoicemailState,
  getFaxesState,
  getUserSettings
} from '../../../Selectors/common';
import { KazooSDK } from '@KazooSDK';
import { get } from 'lodash';

const prepareTray = createSelector(
  [getAuthState, getVoicemailState, getFaxesState, getUserSettings],
  (auth, voicemails, faxes, userSettings) => {
    const user_data = auth.user_data || {};
    const user_last_seen = user_data.last_seen || {};
    const callForwarding = {
      isEnabled: userSettings.user.isCallForwardingEnabled,
      number: userSettings.user.callForwardingNumber
    };
    const doNotDisturb = {
      devices: userSettings.devices
    };

    let newVoicemailCount = 0;
    // let totalVoicemailCount = 0;
    for (let vmBox of voicemails.vm_boxes) {
      newVoicemailCount += get(vmBox, 'folders.new') || 0;
      //   totalVoicemailCount +=
      //     newVoicemailCount + (get(vmBox, 'folders.saved') || 0);
    }

    // Iterate through faxboxes to find New Fax counts
    let newFaxCount = 0;
    let defaultFaxboxId = faxes.faxboxes.length ? faxes.faxboxes[0].id : null;
    faxes.faxboxes.forEach(faxbox => {
      const last_seen_faxbox_inbox_val = KazooSDK.lastSeenFor(
        user_last_seen,
        'faxbox_inbox',
        faxbox.id
      );
      newFaxCount += get(faxes.faxboxById, `${faxbox.id}.inbox`, []).filter(
        fax => fax.timestamp > last_seen_faxbox_inbox_val
      ).length;
    });

    return {
      authenticated: auth.authenticated,
      fullName: KazooSDK.getFullName(),
      newVoicemailCount,
      newFaxCount,
      defaultFaxboxId,
      callForwarding,
      doNotDisturb
      //   totalVoicemailCount,
      //   newFaxCount,
    };
  }
);

export default prepareTray;
