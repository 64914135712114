import React from 'react';
import { Telicon } from '../../Icons';
import { KazooSDK } from '@KazooSDK';
import { useStylesPhoneCallStatus } from './styles';

export const PhoneCallStatus = ({ interaction }) => {
  const classes = useStylesPhoneCallStatus();

  if (KazooSDK.isIncomingCall(interaction)) {
    return KazooSDK.isMissedCall(interaction) ? (
      <Telicon name='phone-missed' className={classes.iconMissed} />
    ) : (
      <Telicon name='phone-inbound' className={classes.iconInBound} />
    );
  } else {
    return <Telicon name='phone-outbound' className={classes.iconOutBound} />;
  }
};
