import React, { useReducer, useRef } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { orderBy as _orderBy, get } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { Document, Page, pdfjs } from 'react-pdf/dist/entry';
import { Telicon } from '../Icons/index';
import { getPhoneNumber, getDateTime } from '@KazooSDK/utils';
import { KazooSDK } from '@KazooSDK';
// import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BodyTable = (props) => {
  const {
    faxesData,
    orderBy,
    order,
    page,
    rowsPerPage,
    classes,
    makeCheckedFax,
    handleCheckbox,
    faxBox,
    handleDeleteOneFax,
    handleOpenFile
  } = props;

  const initState = {
    selected: null
  };

  const [state, setState] = useReducer(
    (preState, newState) => ({ ...preState, ...newState }),
    initState
  );

  const { selected } = state;

  const handleClick = (id) => setState({ selected: id });

  const wrapperRef = useRef(null);

  /* const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setState({ selected: null });
    }
  }; */

  const handleHidden = (e) => {
    e.stopPropagation();
    setState({ selected: null });
  };

  const handleCheckboxElement = (e) => {
    e.stopPropagation();
    setState({ selected: null });
    handleCheckbox(e);
  };

  /*   useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }); */

  return (
    <TableBody ref={wrapperRef}>
      {_orderBy(faxesData, [orderBy], [order])
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const URL_file = KazooSDK.getFaxUrl(row);
          const isSelected = selected === row.id;
          return (
            <TableRow
              hover
              onClick={() => handleClick(row.id)}
              aria-checked={isSelected}
              tabIndex={-1}
              key={row.id}
              selected={isSelected}
            >
              <TableCell
                className={classes.wrapperCell}
                component='th'
                scope='row'
              >
                <div className={classes.wrapperItemRow}>
                  <Checkbox
                    name={row.id}
                    value={row.id}
                    checked={makeCheckedFax.includes(row.id)}
                    onClick={handleCheckboxElement}
                  />
                  {row.folder === 'outbox' && (
                    <span
                      className={`statusLabel ${
                        row.status === 'failed'
                          ? 'redLabel'
                          : row.status === 'pending' ||
                            row.status === 'processing'
                          ? 'grayLabel'
                          : 'greyLabel'
                      }`}
                    >
                      {row.status === 'completed' ? 'sent' : row.status}
                    </span>
                  )}
                  {row.folder === 'inbox' && (
                    <span className='statusLabel greyLabel'>received</span>
                  )}
                </div>
              </TableCell>
              <TableCell
                className={classes.wrapperCell}
                component='th'
                scope='row'
              >
                <div>
                  <div className={classes.wrapperItem}>
                    {row.folder === 'inbox' && (
                      <>
                        <span>{faxBox.caller_name}</span>
                        <span>{faxBox.faxbox_name}</span>
                      </>
                    )}
                    {row.folder === 'outbox' && (
                      <>
                        {row.to && <span>{getPhoneNumber(row.to)}</span>}
                        <span>{getPhoneNumber(row.to_number)}</span>
                      </>
                    )}
                  </div>
                </div>
                {isSelected && (
                  <Document
                    className={classes.wrapDocument}
                    file={{
                      url: URL_file
                    }}
                    loading='Please wait!'
                    onItemClick={() => {}}
                  >
                    <Page pageNumber={1} height={300} />
                  </Document>
                )}
              </TableCell>
              <TableCell className={classes.wrapperCell}>
                <div>
                  <div className={classes.wrapperItem}>
                    <span>{getDateTime(row.created).date}</span>
                    <span>{getDateTime(row.created).time}</span>
                  </div>
                  {isSelected && (
                    <div className={classes.wrapDocument}>
                      <Button className={classes.buttonAction}>
                        <a href={URL_file} download>
                          <Telicon name='download-cloud' />
                        </a>
                      </Button>
                      <Button
                        className={classes.buttonAction}
                        onClick={() => {
                          handleOpenFile(URL_file);
                        }}
                      >
                        <Telicon name='eye' />
                      </Button>
                      <Button
                        className={classes.buttonAction}
                        onClick={() => handleDeleteOneFax(row.folder, row.id)}
                      >
                        <Telicon name='trash' />
                      </Button>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.wrapperCell}>
                {row.folder === 'inbox' && get(row, 'rx_result.total_pages')}
                {row.folder === 'outbox' && get(row, 'tx_result.total_pages')}
                {isSelected && (
                  <Button
                    className={classes.buttonHidden}
                    onClick={handleHidden}
                    aria-hidden
                  >
                    <Telicon name='chevron-up' />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default BodyTable;
