import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
// import {
//   Voicemail as VoicemailIcon,
//   PhoneMissed
// } from "../../Icons";
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { Loader } from '../Loader';
import { Telicon } from '../../Icons';

import useStyles from './styles';

export const CountWidget = props => {
  const { type, count, label, routePath, Icon, icon, isLoading } = props;

  const history = useHistory();

  const route = useCallback(() => {
    history.push(routePath);
  }, [history, routePath]);

  const classes = useStyles({
    type
  });

  return (
    <div className={classes.root} onClick={route}>
      <div className={classes.leftContainer}>
        <div>
          <Typography display="inline" className={classes.countText}>
            {count}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          {isLoading && <Loader marginTop={-12} />}
        </div>
        <Typography className={classes.label}>{label}</Typography>
      </div>
      {Icon && (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </div>
      )}
      {icon && (
        <div className={classes.iconContainer}>
          <Telicon name={icon} className={classes.icon} />
        </div>
      )}
    </div>
  );
};

export default CountWidget;
